import { Component, Input } from '@angular/core';
import { heroicons } from '@assets/icons/heroicons';

type Status = 'default' | 'info' | 'warning' | 'success' | 'error';

@Component({
  selector: 'tu-closable-card',
  styleUrls: ['./card.component.scss'],
  template: `
    <details
      class="tw-divide-y tw-divide-gray-300 tw-border tw-border-gray-300 tw-bg-white"
      [ngClass]="klass"
      [open]="defaultOpen"
    >
      <summary
        class="tw-flex tw-cursor-pointer tw-items-center tw-space-x-2 tw-px-4 tw-py-3"
        [ngClass]="[status]"
      >
        <tu-hero-icon
          *ngIf="!icon; else customIcon"
          [path]="heroicons.outline.chevronDown"
          klass="tw-h-4 tw-inline-block tw-mx-auto tw-text-gray-700"
        ></tu-hero-icon>
        <ng-template #customIcon>
          <i [class]="icon"></i>
        </ng-template>

        <ng-content select="[card-header]"></ng-content>
      </summary>

      <main class="tw-p-4">
        <ng-content select="[card-content]"></ng-content>
      </main>

      <footer *ngIf="withFooter" class="tw-p-4">
        <ng-content select="[card-footer]"></ng-content>
      </footer>
    </details>
  `,
})
export class ClosableCardComponent {
  public heroicons = heroicons;

  @Input('defaultOpen')
  public defaultOpen = false;

  @Input('icon')
  public icon?: string;

  @Input('withFooter')
  public withFooter = false;

  @Input('klass')
  public klass = '';

  @Input('status')
  public status: Status = 'default';
}
