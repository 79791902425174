import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatStepperModule } from '@angular/material/stepper';
import { MatChipsModule } from '@angular/material/chips';
import { RouterModule } from '@angular/router';
import { SharedModule } from '@app/modules/shared/shared.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SimpleNotificationsModule } from 'angular2-notifications';
import { MomentModule } from 'ngx-moment';
import { NgxSelectModule } from 'ngx-select-ex';
import { NetworkService } from '../network/services/network.service';
import { AnonymizationModalComponent } from './components/customer-detail/anonymization-modal/anonymization-modal.component';
import { AttributesModalComponent } from './components/customer-detail/attributes-modal/attributes-modal.component';
import { ImageUpdateModalComponent } from './components/customer-detail/avatar-update-modal/avatar-update-modal.component';
import { CustomerDetailComponent } from './components/customer-detail/customer-detail.component';
import { InformationModalComponent } from './components/customer-detail/information-modal/information-modal.component';
import { CustomerLostPhoneModalComponent } from './components/customer-detail/lost-phone-modal/customer-lost-phone-modal.component';
import { NetworkDissociationModalComponent } from './components/customer-detail/network-dissociation-modal/network-dissociation-modal.component';
import { TicketGenerationModalComponent } from './components/customer-detail/ticket-generation-modal/ticket-generation-modal.component';
import { CustomerSearchComponent } from './components/customer-search/customer-search.component';
import { FusionModalComponent } from './components/customer-detail/fusion-modal/fusion-modal.component';
// Components
import { CustomersComponent } from './components/customers.component';
// Modules
import { CustomersRoutingModule } from './customers.routing';
// Services
import { CustomersService } from './services/customers.service';
import { CustomersFiltersService } from './services/filters.service';
import { ProductsService } from './services/products.service';
import { TicketService } from './services/ticket.service';
import { PaperworkService } from './services/paperwork.service';
import { SubscriptionsService } from '@app/modules/subscriptions/services/subscriptions.service';
import { UsersService } from '../users/services/users.service';
import { DeleteTicketModalComponent } from './components/customer-detail/delete-ticket-modal/delete-ticket-modal.component';
import { ShopService } from './services/shop.service';
import { DocumentUploadModalComponent } from './components/customer-detail/document-upload-modal/document-upload-modal.component';
import { CustomerCommentComponent } from './components/customer-detail/customer-comment/customer-comment.component';

@NgModule({
  declarations: [
    CustomersComponent,
    CustomerDetailComponent,
    CustomerSearchComponent,
    AnonymizationModalComponent,
    AttributesModalComponent,
    InformationModalComponent,
    ImageUpdateModalComponent,
    NetworkDissociationModalComponent,
    TicketGenerationModalComponent,
    CustomerLostPhoneModalComponent,
    FusionModalComponent,
    DeleteTicketModalComponent,
    DocumentUploadModalComponent,
    CustomerCommentComponent,
  ],
  imports: [
    CustomersRoutingModule,
    SharedModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    MomentModule,
    NgxSelectModule,
    MatAutocompleteModule,
    MatButtonModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatStepperModule,
    MatChipsModule,
    NgbModule,
    SimpleNotificationsModule,
  ],
  providers: [
    CustomersService,
    ProductsService,
    TicketService,
    CustomersFiltersService,
    NetworkService,
    PaperworkService,
    SubscriptionsService,
    UsersService,
    ShopService,
  ],
})
export class CustomersModule {}
