import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom, map } from 'rxjs';
import { environment as env } from '@env/environment';

@Injectable()
export class ShopService {
  constructor(private http: HttpClient) {}

  public countNetworksProductTypes(networks: number[]): Promise<number> {
    return firstValueFrom(
      this.http
        .get(env.config.feedRoot + 'Shop/getProductsType', {
          params: {
            pagination: JSON.stringify({ offset: 0, limit: 1 }),
            network_id: networks.join(','),
          },
          observe: 'response',
        })
        .pipe(
          map((response) => {
            return Number(response.headers.get('x-airweb-pagination-count'));
          })
        )
    );
  }
}
