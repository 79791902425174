import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { NotificationsService } from 'angular2-notifications';
import { PaginationService as NGXPaginationService } from 'ngx-pagination';
import { isObservable, Observable, Subscription } from 'rxjs';
import { AuthService } from '../../services/auth.service';
import { PaginationService } from '../../services/pagination.service';
import { Filters } from '../filter/filter.component';

@Component({
  selector: 'tu-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
  providers: [DatePipe],
})
export class TableComponent implements OnInit {
  public _filter: string;
  public type = TableElementType;
  public tagType = TagType;
  public sortType: { key: any; sort: Sort };
  public allChecked: boolean;
  public custom_fields: Array<any>;
  public config: Array<any>;
  public selectedPage = 1;
  public submenuOpen: string | false = false;

  public notificationOptions = {
    timeOut: 5000,
    showProgressBar: true,
    pauseOnHover: false,
    clickToClose: false,
  };

  private __content: any = null;
  private __sortedContent: any = null;

  @Input('config') set _config(c: Array<any>) {
    this.sortType = { key: null, sort: Sort.NONE };
    this.config = c.map((config) => {
      return {
        ...config,
        class: {
          header: config?.class?.header || '',
          row: config?.class?.row || '',
        },
      };
    });
  }
  @Input() filterObs: Observable<Filters>;

  @Input() headers = true;

  @Input() componentName: string;

  @Input() id: string = location.pathname;

  /**
   * Subcription object if content is an observable
   */
  private subscription: Subscription = null;

  @Input() set content(content: Observable<Object[]> | Object[]) {
    // unsubscribe if subscribed to anything
    if (this.subscription) {
      this.subscription.unsubscribe();
      this.subscription = null;
    }

    // If null or empty, display nothing
    if (!content) {
      this._content = [];
      return;
    }

    // if only an array just update
    if (Array.isArray(content)) {
      this._content = content as any[];
      return;
    } // Converted to DataRow by setter (????)

    // If an observable, subscribe to it and store subscription
    if (isObservable(content)) {
      this.subscription = content.subscribe((c) => (this._content = c as any));
      return;
    }
  }

  @Input() fromDate: string;
  @Input() toDate: string;
  @Input() isClicked: boolean;
  @Input() perPage = 10;

  @Input() checkboxes: boolean;
  @Input() simpleCheckboxes = false;
  @Input() pagination: boolean;
  @Output() rowClicked: EventEmitter<Object> = new EventEmitter();

  @Output() checkedRows: EventEmitter<Object[]> = new EventEmitter();
  @Output() hasCheckedAll: EventEmitter<boolean> = new EventEmitter();

  public tags = [
    'info',
    'warning',
    'success',
    'default',
    'primary',
    'danger',
    'reg',
    'waiting_payment',
  ];

  get _content(): DataRow[] {
    return this.__sortedContent;
  }
  set _content(values) {
    if (values) {
      this.__content = values.map((elem) => {
        return {
          elem,
          values: this.config.map((conf: ConfigBase) => {
            let value = conf.key.split('.').reduce((o, i) => (o[i] ? o[i] : ''), elem);
            value = conf.modifier ? conf.modifier(value, elem) : value;
            return Object.assign({}, conf, { value });
          }),
        };
      });
      this.__sortedContent = this.sort(this.__content);

      /**
       * The end of this function is mostly to handle a specific case
       * where the selected page that's stored on the pagination service
       * is actually higher than the total number of page.
       *
       * If we somehow detect that case, then we redirect the user to the
       * page number 1.
       *
       * The implemantation is faaaar from being clean and might not even be
       * bug free. That being said, spending an entire morning on this kind
       * of shit when that would be implemanted in a fraction of seconds
       * in ANY other technology is really worrisome, either for my career
       * or for Angular.
       */
      const instance = this.ngxPaginationService.getInstance();

      // This calcul was directly taken from the original implemantion
      // in the ngx-pagination repo:
      // https://github.com/michaelbromley/ngx-pagination/blob/master/src/pagination-controls.directive.ts#L168
      const totalPages = Math.max(
        Math.ceil(this.__sortedContent.length / instance.itemsPerPage),
        1
      );

      if (totalPages < this.paginationService.getSelectedPage()) {
        // Why is the selected page information at THREE place???
        // I guess we will never find out...
        this.paginationService.setSelectedPage(1);
        this.selectedPage = 1;
      }
    }
  }
  constructor(
    private sanitizer: DomSanitizer,
    public paginationService: PaginationService,
    private notification: NotificationsService,
    private translate: TranslateService,
    private authService: AuthService,
    private datePipe: DatePipe,
    private ngxPaginationService: NGXPaginationService
  ) {}

  public trustStyle(value: string) {
    return this.sanitizer.sanitize(SecurityContext.STYLE, value);
  }

  public isFunction(val) {
    return typeof val === 'function';
  }

  public pageChanged(page) {
    this.paginationService.setSelectedPage(page, this.id);
  }

  public onRowClicked(elem: Object): void {
    // this.selectedPage = this.paginationService.selectedPage;
    this.rowClicked.emit(elem);
  }

  public onRowChecked($event, elem: Object): void {
    elem['_checked'] = $event.target.checked;
    if (!$event.target.checked) {
      this.allChecked = false;
    }

    this.emitCheckedRows();
  }

  public changeSort(key) {
    if (this.sortType.key === key)
      this.sortType.sort = (this.sortType.sort + 1) % (Object.keys(Sort).length / 2);
    else this.sortType.sort = 1;

    this.sortType.key = key;
    this.__sortedContent = this.sort(this.__content);
  }

  private sort(content: Array<any>): Array<any> {
    if (!content) return null;
    if (!content.length) return [];

    let temp = Object.assign([], content),
      val_key = this.sortType.key,
      val_sort = this.sortType.sort,
      index = null;

    try {
      if (temp[0] !== undefined) {
        if ((val_sort === 1 || val_sort === 2) && val_key !== null) {
          index = temp[0].values.findIndex((v) => v.key === val_key);
          const type = temp[0].values[index].type;

          let t = temp.sort((a, b) => {
            a = a.values[index].value;
            b = b.values[index].value;

            switch (type) {
              case TableElementType.Link:
                a = a.label;
                b = b.label;
                break;

              case TableElementType.ToolTip:
                a = a.enabledTag ? a.tag.label : a.text;
                b = b.enabledTag ? b.tag.label : b.text;
                break;

              case TableElementType.Price:
                a = +a;
                b = +b;
                break;
            }

            if (['number', 'boolean'].includes(typeof a)) return +a - +b;
            else if (a !== null) return a.localeCompare(b);
            else return a;
          });
          if (val_sort === 2) return t.reverse();
          else return t;
        } else return this.__content;
      }
    } catch (e) {
      console.error(e);
    }
  }

  ngOnInit() {
    if (typeof this.filterObs !== 'undefined') {
      this.filterObs.subscribe((filter) => {
        this._filter = <string>filter.query;
      });
    }

    this.selectedPage = this.paginationService.getSelectedPage(this.id);
  }

  public checkAll(checked) {
    this._content.forEach((cont) => (cont.elem['_checked'] = checked));
    this.allChecked = checked;

    this.emitCheckedRows();
  }

  private emitCheckedRows() {
    this.checkedRows.emit(this._content.filter((cont) => cont.elem['_checked']));
    this.hasCheckedAll.emit(this.allChecked);
  }

  public exportToFile(_type: string) {
    const file = this.componentName || 'exports';

    const customFields = this.config
      .filter((column) => {
        const isExportable = this.isFunction(column.exportable)
          ? column.exportable()
          : column.exportable;

        // We only want to filter out the columns that have been specifically
        // marked as `false`. `undefined` and `true` both count as exportable columns.
        // The person that came up with this stupid idea doesn't work here probably.
        return isExportable !== false;
      })
      .reduce<Record<string, any>>((columns, column) => {
        columns[column.key] = column;
        return columns;
      }, {});

    const filteredContent = this._content
      .map((row) => row.elem)
      .filter((row) => row['_checked'] === true);

    if (!filteredContent.length) {
      return this.notification.warn(this.translate.instant(`otherslabels.notif_data_export`));
    }

    const csvData = this.generateCsv(filteredContent, customFields);

    const now = new Date();
    const fileName = `${file}_${this.parseDate(now)}.csv`;
    const blob = new Blob(['\ufeff', csvData], { type: 'text/csv' });

    const a = document.createElement('a');
    const url = URL.createObjectURL(blob);

    a.setAttribute('style', 'display: none');
    document.body.appendChild(a);
    a.href = url;
    a.download = fileName;
    a.click();
    a.remove();

    this.notification.info(
      this.translate.instant(`otherslabels.notif_download`),
      this.translate.instant(`otherslabels.notif_download_progress`)
    );
  }

  public isExportable(key: string, fields: string[]): boolean {
    return fields.includes(key);
  }

  public generateCsv(data: Record<string, any>[], fields: Record<string, any>): string {
    const fieldsKey = Object.keys(fields);

    const isObject = (maybeObject: unknown): maybeObject is Record<string, any> => {
      return typeof maybeObject === 'object';
    };

    const rows = isObject(data) ? data : JSON.parse(data);

    let str = '';
    let row = '';

    for (const [headerKey, headerValue] of Object.entries(data[0])) {
      if (headerValue && isObject(headerValue)) {
        if (this.isExportable(headerKey, fieldsKey)) {
          row += `"${headerKey}";`;
        }

        for (const columnKey of Object.keys(headerValue)) {
          const key = `${headerKey}.${columnKey}`;

          if (this.isExportable(key, fieldsKey)) {
            row += `"${columnKey}";`;

            if (fields[key].type === TableElementType.Price) {
              row += `"${columnKey}_currency";`;
            }
          }
        }
      } else if (this.isExportable(headerKey, fieldsKey)) {
        row += `"${headerKey}";`;

        if (fields[headerKey].type === TableElementType.Price) {
          row += `"${headerKey}_currency";`;
        }
      }
    }

    row = row.slice(0, -1);
    // append header row with line break
    str += row + '\r\n';

    for (let i = 0; i < rows.length; i++) {
      let line = '';

      for (const index in rows[i]) {
        if (rows[i][index] && isObject(data[0][index])) {
          if (this.isExportable(index, fieldsKey)) {
            if (line !== '') line += ';';
            const value = rows[i][index] ? rows[i][index] : '';

            try {
              line += `"${fields[`${index}`].modifier?.(value) ?? value}"`;
            } catch {
              line += `"${value}"`;
            }

            if (fields[`${index}`].type === TableElementType.Price) {
              const network = this.authService.networks.find(
                (network) => +network.id === +rows[i]['network_id']
              );

              if (network?.currency) line += `;"${network.currency}"`;
            }
          }

          for (const key in rows[i][index]) {
            if (this.isExportable(`${index}.${key}`, fieldsKey)) {
              if (line !== '') line += ';';
              const value = rows[i][index][key] ? rows[i][index][key] : '';
              line += `"${value}"`;

              if (fields[`${index}.${key}`].type === TableElementType.Price) {
                const network = this.authService.networks.find(
                  (network) => +network.id === +rows[i]['network_id']
                );

                if (network?.currency) line += `;"${network.currency}"`;
              }
            }
          }
        } else {
          if (this.isExportable(index, fieldsKey)) {
            if (line !== '') line += ';';
            const regex = new RegExp(
              '^(-?(?:[1-9][0-9]*)?[0-9]{4})-(1[0-2]|0[1-9])-(3[01]|0[1-9]|[12][0-9])T(2[0-3]|[01][0-9]):([0-5][0-9]):([0-5][0-9])'
            );
            if (regex.test(rows[i][index])) {
              rows[i][index] = this.datePipe.transform(rows[i][index], 'yyyy-MM-dd HH:mm:ss');
            }
            const value = rows[i][index] ? rows[i][index] : '';
            line += `"${value}"`;

            if (fields[index].type === TableElementType.Price) {
              if (rows[i]['currency']) line += `;${rows[i]['currency']}`;
              else {
                const network = this.authService.networks.find(
                  (network) => +network.id === +rows[i]['network_id']
                );

                if (network?.currency) line += `;"${network.currency}"`;
              }
            }
          }
        }
      }

      str += line + '\r\n';
    }

    return str;
  }

  public external(link: string): boolean {
    const regexp =
      /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/;
    return regexp.test(link);
  }

  public getTag(c: any): string {
    if (
      c.config.tags[c.value] &&
      typeof c.config.tags[c.value]['type'] !== 'undefined' &&
      this.tags[c.config.tags[c.value]['type']]
    ) {
      try {
        return `tag-${this.tags[c.config.tags[c.value]['type']]}`;
      } catch (e) {
        console.log({ e });
        return `tag-${this.tags[TagType.Default]}`;
      }
    } else {
      console.error(`Missing tag type for ${c.value} in`, c.config.tags, c.config.tags[c.value]);
      return `tag-${this.tags[TagType.Default]}`;
    }
  }

  public getTagContent(c: any, element: any): string {
    if (c.config.tags[c.value] && typeof c.config.tags[c.value]['label'] !== 'undefined') {
      if (this.isFunction(c.config.tags[c.value].label)) {
        return c.config.tags[c.value].label(element);
      } else {
        return this.translate.instant(c.config.tags[c.value].label);
      }
    } else {
      console.error(`Missing Content tag for ${c.value} in`, c.config.tags, c.config.tags[c.value]);
      return this.translate.instant('otherslabels.unkown');
    }
  }

  public stringToArray(str: string | string[]) {
    return Array.isArray(str) ? str : [str];
  }

  private parseDate(date: Date): string {
    let day = `0${date.getDate()}`.slice(-2),
      month = `0${date.getMonth() + 1}`.slice(-2),
      year = date.getFullYear().toString();

    return `${day}_${month}_${year}`;
  }

  public getSelectedOption(value: string, options: ConfigSelectOption[]): ConfigSelectOption {
    return options.find((opt) => opt.value === value);
  }

  public getSelectedTagOption(value: string, options: ConfigSelectOption[]): TagType {
    const optValue = this.getSelectedOption(value, options);

    if (optValue) {
      return optValue.type;
    }

    return TagType.Default;
  }

  public selectIsDisabled(config: ConfigSelect, value: string): boolean {
    if (typeof config.config.disabled === 'boolean') {
      return config.config.disabled;
    }

    if (typeof config.config.disabled === 'function') {
      return config.config.disabled(value);
    }

    return false;
  }
}

export enum TableElementType {
  Avatar,
  Date,
  Email,
  Function,
  Icon,
  Image,
  Link,
  Price,
  Submenu,
  Tag,
  Text,
  ToolTip,
  Select,
}

export enum TagType {
  Info,
  Warning,
  Success,
  Default,
  Primary,
  Danger,
  Reg,
  waiting_payment,
}

export enum Sort {
  NONE,
  ASC,
  DESC,
}

export type Config =
  | ConfigBase
  | ConfigIcon
  | ConfigTag
  | ConfigImage
  | ConfigAvatar
  | ConfigToolTip
  | ConfigPrice
  | ConfigSelect;

export interface ConfigBase extends Record<string, any> {
  key: string;
  label: string;
  type: TableElementType;
  exportable?: boolean | { (): boolean };
  displayed?: boolean | { (): boolean };
  modifier?: { (value: any, element: any): any };
  format?: string;
  style?: string | Function;
  isSortable?: boolean;
  class?: {
    header?: string;
    row?: string;
  };
}

export interface ConfigAvatar extends ConfigBase {
  type: TableElementType.Avatar;
  imgDefault: string;
}

export interface ConfigImage extends ConfigBase {
  type: TableElementType.Image;
  imgDefault?: string;
  config?: {
    height?: string;
    width?: string;
  };
}

export interface ConfigIcon extends ConfigBase {
  type: TableElementType.Icon;
  config?: {
    source?: string;
    icons?: { [icon: string]: string | string[] };
    label?: string;
  };
}

export interface ConfigTag extends ConfigBase {
  type: TableElementType.Tag;
  config?: {
    tags?: { [tag: string]: { type: TagType; label: string | Function } };
  };
}

export interface ConfigPrice extends ConfigBase {
  type: TableElementType.Price;
  currency?: (element: any) => string | string;
  locale?: (element: any) => string | string;
}

export interface ConfigToolTip extends ConfigBase {
  type: TableElementType.ToolTip;
  placement?: string;
  tooltip?: string;
  text?: string;
  enabledTag?: boolean;
  tag?: { type: TagType; label: string };
}

export interface ConfigWithValue extends ConfigBase {
  value: any;
}

export interface ConfigSelect extends ConfigBase {
  type: TableElementType.Select;
  config: {
    options: ConfigSelectOption[];
    disabled: boolean | ((value: string) => boolean);
  };
  change: (value: string, element: any) => void;
}

export interface ConfigSelectOption {
  label: string;
  value: string;
  type: TagType;
  disabled?: boolean;
}

export interface DataRow {
  elem: any;
  values: ConfigWithValue[];
}
