import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { NotificationsService } from 'angular2-notifications';
import { CustomersService } from '../../../services/customers.service';

export interface DialogData {
  customerId: string;
  label: string;
  value: any;
  attributeId: number | string;
  attributeType: string;
  attributeValues: (string | boolean)[];
}

@Component({
  selector: 'tu-attributes-modal',
  templateUrl: './attributes-modal.component.html',
  providers: [CustomersService],
})
export class AttributesModalComponent implements OnInit {
  @Output() updateField = new EventEmitter();

  public attributeInput: any;
  public loading = false;

  public notificationOptions = {
    timeOut: 3000,
    showProgressBar: true,
    pauseOnHover: false,
    clickToClose: false,
  };

  constructor(
    public modalRef: MatDialogRef<AttributesModalComponent>,
    private customersService: CustomersService,
    private notification: NotificationsService,
    private readonly translate: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {}

  ngOnInit() {
    this.attributeInput = this.data.value;
  }

  public closeModal(): void {
    this.modalRef.close();
  }

  public onSelectValue(value: boolean): void {
    this.attributeInput = value;
  }

  public async confirmAttributeEdit() {
    try {
      if (this.loading || this.attributeInput === this.data.value) return;

      this.loading = true;

      const result = await this.customersService.setCustomerAttribute(
        this.data.customerId,
        this.data.attributeId,
        this.attributeInput
      );

      const attributes = await this.customersService.getCustomerAttributes(this.data.customerId);

      if (result.error) {
        throw new Error();
      } else {
        this.updateField.emit(attributes);
        this.closeModal();
        this.notification.success(
          this.translate.instant('pages.customer_details.metadata_changed')
        );
      }
    } catch {
      this.notification.error(
        this.translate.instant('pages.customer_details.metadata_change_fail')
      );
    } finally {
      this.loading = false;
    }
  }
}
