import { BehaviorSubject, Observable } from 'rxjs';

/**
 * Creates an Observable for a specific property of an object.
 * https://craftsmen.nl/angular-lifehack-reactive-component-input-properties/
 *
 * @template T The type of the target object.
 * @template K The type of the key of the target object.
 *
 * @param {T} target The target object.
 * @param {K} key The key of the property to observe.
 *
 * @returns {Observable<T[K]>} An Observable that emits the new value whenever the property changes.
 */
export function observeProperty<T, K extends keyof T>(target: T, key: K): Observable<T[K]> {
  // Create a new BehaviorSubject that will emit the current value of the property
  const subject = new BehaviorSubject<T[K]>(target[key]);

  // Redefine the property with a getter and setter
  Object.defineProperty(target, key, {
    // The getter simply returns the current value from the BehaviorSubject
    get(): T[K] {
      return subject.getValue();
    },
    // The setter checks if the new value is different from the current value
    // If it is, it updates the BehaviorSubject with the new value
    set(newValue: T[K]): void {
      if (newValue !== subject.getValue()) {
        subject.next(newValue);
      }
    },
  });

  // Return the BehaviorSubject as an Observable
  return subject.asObservable();
}
