<div class="tw-bg-gray-50 tw-p-4 tw-flex tw-flex-col tw-gap-1">
  <!-- Time + buttons -->
  <div class="tw-flex tw-justify-between tw-items-center">
    <div>
      <time class="text-muted">
        {{ comment?.date | date : 'shortDate' : undefined : translate.currentLang }}
        {{ comment?.date | date : 'shortTime' : undefined : translate.currentLang }}
      </time>
      <em *ngIf="editedComment === comment.id" class="text-muted tw-ml-2">En cours d'édition</em>
    </div>
    
    <div
      *ngIf="comment?.user_id === authService?.user_id && comment?.id; else differentUser"
      class="tw-flex tw-space-x-2"
    >
      <button
        ngbTooltip="Editer le commentaire"
        (click)="commentToEdit(comment.id, comment.comment)"
      >
        <tu-hero-icon [path]="heroicons.outline.pencilAlt" klass="tw-w-6"></tu-hero-icon>
      </button>
      <button
        ngbTooltip="Supprimer le commentaire"
        (click)="commentToDelete(comment.id)"
      >
        <tu-hero-icon [path]="heroicons.outline.trash" klass="tw-w-6 tw-text-red-500"></tu-hero-icon>
      </button>
    </div>
    <ng-template #differentUser>
      <div *ngIf="comment?.id" class="tw-flex tw-space-x-2">
        <button
          [hasAllPermission]="['EDIT_ALL_COMMENTS']"
          ngbTooltip="Editer le commentaire"
          (click)="commentToEdit(comment.id, comment.comment)"
        >
          <tu-hero-icon [path]="heroicons.outline.pencilAlt" klass="tw-w-6"></tu-hero-icon>
        </button>
        <button
          [hasAllPermission]="['EDIT_ALL_COMMENTS']"
          ngbTooltip="Supprimer le commentaire"
          (click)="commentToDelete(comment.id)"
        >
          <tu-hero-icon [path]="heroicons.outline.trash" klass="tw-w-6 tw-text-red-500"></tu-hero-icon>
        </button>
      </div>
    </ng-template>
  </div>
  <!-- User + order info -->
  <div class="tw-flex tw-items-center tw-gap-2">
    <span class="text-muted">{{ comment?.login }}</span>
    <!-- Status -->
    <div [ngSwitch]="comment?.order_status">
      <span
        class="tag tag-pill"
        [ngClass]="{
          'tag-info': comment?.order_status === OrderStatus.pending,
          'tag-success':
            comment?.order_status === OrderStatus.completed ||
            comment?.order_status === OrderStatus.preauthorized ||
            comment?.order_status === OrderStatus.refunded,
          'tag-warning': comment?.order_status === OrderStatus.canceled,
          'tag-primary': comment?.order_status === OrderStatus.to_refund,
          'tag-danger': comment?.order_status === OrderStatus.error,
          'tag-reg': comment?.order_status === OrderStatus.regularization,
          'tag-waiting_payment': comment?.order_status === OrderStatus.waiting_for_payment
        }"
        placement="left"
        ngbTooltip="{{ 'pages.order_details.command_title' | translate }}"
      >
        <ng-container *ngSwitchCase="OrderStatus.pending">
          {{ 'pages.order_details.pending' | translate }}
        </ng-container>
        <ng-container *ngSwitchCase="OrderStatus.completed">
          {{ 'pages.order_details.completed' | translate }}
        </ng-container>
        <ng-container *ngSwitchCase="OrderStatus.canceled">
          {{ 'pages.order_details.canceled' | translate }}
        </ng-container>
        <ng-container *ngSwitchCase="OrderStatus.error">
          {{ 'pages.order_details.error' | translate }}
        </ng-container>
        <ng-container *ngSwitchCase="OrderStatus.regularization">
          {{ 'pages.order.regularization' | translate }}
        </ng-container>
        <ng-container *ngSwitchCase="OrderStatus.preauthorized">
          {{ 'pages.order.preauthorized' | translate }}
        </ng-container>
        <ng-container *ngSwitchCase="OrderStatus.waiting_for_payment">
          {{ 'pages.order.waiting_for_payment' | translate }}
        </ng-container>
        <ng-container *ngSwitchCase="OrderStatus.to_refund">
          {{ 'pages.order.to_refund' | translate }}
        </ng-container>
        <ng-container *ngSwitchCase="OrderStatus.refunded">
          {{ 'pages.order.refunded' | translate }}
        </ng-container>
      </span>
    </div>
    <!-- Process status -->
    <div [ngSwitch]="comment?.order_process_status">
      <span
        class="tag tag-pill"
        [ngClass]="{
          'tag-default': comment?.order_process_status === 'NULL',
          'tag-info': comment?.order_process_status === OrderProcessStatus.to_process,
          'tag-success': [
            OrderProcessStatus.processed,
            OrderProcessStatus.autoprocessed
          ].includes(comment?.order_process_status),
          'tag-warning': comment?.order_process_status === OrderProcessStatus.processing
        }"
        placement="left"
        ngbTooltip="{{ 'pages.order_details.process_title' | translate }}"
      >
        <ng-container *ngSwitchCase="'NULL'">
          {{ 'otherslabels.unspecified' | translate }}
        </ng-container>
        <ng-container *ngSwitchCase="OrderProcessStatus.to_process">
          {{ 'pages.order_details.to_process' | translate }}
        </ng-container>
        <ng-container *ngSwitchCase="OrderProcessStatus.processed">
          {{ 'pages.order_details.processed' | translate }}
        </ng-container>
        <ng-container *ngSwitchCase="OrderProcessStatus.autoprocessed">
          {{ 'pages.order_details.autoprocessed' | translate }}
        </ng-container>
        <ng-container *ngSwitchCase="OrderProcessStatus.processing">
          {{ 'pages.order_details.processing' | translate }}
        </ng-container>
      </span>
    </div>
  </div>
  <!-- Comment -->
  <div [innerHTML]="markdownToHTML(comment.comment)"></div>
</div>