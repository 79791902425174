import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'tu-delete-modal',
  templateUrl: './delete-modal.component.html',
  styleUrls: ['./delete-modal.component.scss'],
})
export class DeleteModalComponent {
  constructor(private modalRef: MatDialogRef<DeleteModalComponent>) {}

  public closeModal(success: boolean): void {
    this.modalRef.close({ success });
  }
}
