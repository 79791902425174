import { Component, Input } from '@angular/core';
import { OrderProcessStatus, OrderStatus } from '../../../models/order';
import { heroicons } from '@assets/icons/heroicons';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '@app/modules/shared/services/auth.service';

@Component({
  selector: 'tu-order-comment',
  templateUrl: './order-comment.component.html',
  styleUrls: ['./order-comment.component.scss'],
})
export class OrderCommentComponent {
  public heroicons = heroicons;
  public OrderProcessStatus = OrderProcessStatus;
  public OrderStatus = OrderStatus;

  @Input()
  public comment: any;

  @Input()
  public commentToEdit: (id: number, message: string) => void;

  @Input()
  public editedComment: string;

  @Input()
  public commentToDelete: (id: number) => void;

  @Input()
  public markdownToHTML: (markdown: string) => string;

  constructor(public translate: TranslateService, public authService: AuthService) {}
}
