<h5 mat-dialog-title class="mb-1">
  {{ 'pages.submission_details.delete_document_validation' | translate }}
</h5>

<div mat-dialog-content>
  <p class="tw-mb-0">
    {{ 'pages.submission_details.modal_message_1' | translate }}
  </p>
  <p class="tw-mb-0 tw-mt-1">
    {{ 'pages.submission_details.modal_message_2' | translate }} "{{ data.documentTypeName }}" ?
  </p>

  <form (ngSubmit)="confirmDocumentDelete()">
    <div mat-dialog-actions class="form-group pull-right tw-min-h-[auto]">
      <button
        type="button"
        (click)="closeModal()"
        [disabled]="loading"
        class="mr-1 btn btn-sm btn-secondary tw-mt-0"
      >
        {{ 'otherslabels.btn_cancel' | translate }}
      </button>

      <button type="submit" [disabled]="loading" class="btn btn-sm btn-danger tw-mt-0">
        <i class="fa fa-circle-o-notch fa-spin" [ngClass]="{ 'd-none': !loading }"></i>
        {{
          !loading ? ('otherslabels.btn_confirm' | translate) : ('otherslabels.loading' | translate)
        }}
      </button>
    </div>
  </form>
</div>

<simple-notifications [options]="notificationOptions"></simple-notifications>
