import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewEncapsulation,
} from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { heroicons } from '@assets/icons/heroicons';
import { marked } from 'marked';
import DOMPurify from 'dompurify';
import { fontColor } from './marked-colors';
import { CommentFormValues } from '../../models/comment';
import { NotificationsService } from 'angular2-notifications';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';
import { DeleteModalComponent } from './delete-modal/delete-modal.component';
import { Network } from '../../models/network';

type ComputeCommentResponse = Promise<{ success: boolean; errorMessage?: string }>;

@Component({
  selector: 'tu-comments-ng',
  templateUrl: './comments-ng.component.html',
  styleUrls: ['./comments-ng.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CommentsNgComponent implements OnInit {
  public heroicons = heroicons;

  public commentForm = this.fb.group({
    id: null,
    message: '',
    networkId: null,
  });

  public showFormat: boolean = false;
  public submitLoading: boolean = false;
  public isLoading: boolean = false;

  @Input()
  public comments: Array<any> | null = null;

  @Input()
  public commentTemplate: TemplateRef<any>;

  @Input()
  public postComment: (comment: CommentFormValues) => ComputeCommentResponse;

  @Input()
  public editComment: (comment: CommentFormValues) => ComputeCommentResponse;

  @Input()
  public deleteComment: (id: number) => ComputeCommentResponse;

  @Input() public requiresNetwork = false;

  @Input() public networks: Network[] = [];

  @Output() public selectedNetwork = new EventEmitter<string>();

  constructor(
    private fb: FormBuilder,
    private notification: NotificationsService,
    private translate: TranslateService,
    private modal: MatDialog
  ) {}

  get hasMessage(): boolean {
    return (
      Boolean(this.commentForm.controls.message.value) &&
      // No only space
      !/^\s+$/.test(this.commentForm.controls.message.value)
    );
  }

  get sortedComments(): Array<any> {
    if (this.comments) {
      return this.comments;
    }

    return [];
  }

  public ngOnInit(): void {
    this.commentForm.controls.networkId.valueChanges.subscribe((networkId) => {
      this.selectedNetwork.emit(networkId);
      this.commentForm.controls.id.reset();
    });

    if (this.requiresNetwork && this.networks.length === 1) {
      this.commentForm.controls.networkId.setValue(this.networks[0].id);
    }
  }

  public toggleFormat(): void {
    this.showFormat = !this.showFormat;
  }

  public async submitComment(): Promise<void> {
    try {
      this.isLoading = true;
      this.submitLoading = true;

      const body = this.commentForm.value;

      const response = await (body.id ? this.editComment(body) : this.postComment(body));

      if (!response.success) {
        throw new Error(response.errorMessage);
      }

      this.cancelEdition();
    } catch {
      this.notification.error(this.translate.instant('pages.customer_details.generic_error'));
    } finally {
      this.isLoading = false;
      this.submitLoading = false;
    }
  }

  public cancelEdition(): void {
    this.commentForm.controls.message.reset();
    this.commentForm.controls.id.reset();
  }

  public setEditableComment = (id: number, message: string) => {
    this.commentForm.controls.id.setValue(id);
    this.commentForm.controls.message.setValue(message);
  };

  public deleteCommentModal = (id: number) => {
    const modalRef = this.modal.open(DeleteModalComponent);

    modalRef.afterClosed().subscribe(async (response) => {
      if (response?.success === true) {
        try {
          this.isLoading = true;
          await this.deleteComment(id);
        } catch {
          this.notification.error(this.translate.instant('pages.customer_details.generic_error'));
        } finally {
          this.isLoading = false;
        }
      }
    });
  };

  public markdownToHTML(markdown: string): string {
    marked.setOptions({ breaks: true });
    marked.use({ extensions: [fontColor] });

    //https://marked.js.org/
    const dirtyHTML = marked.parse(markdown);

    //https://github.com/cure53/DOMPurify
    return DOMPurify.sanitize(dirtyHTML);
  }
}
