import { Pipe, PipeTransform } from '@angular/core';
import { escapeRegExp } from '@app/utils/escape-regexp';

@Pipe({
  name: 'search',
  pure: true,
})
export class SearchPipe implements PipeTransform {
  transform(value: Object[], filter_str: string): any {
    if (value && filter_str) {
      return value.filter((val) => this.contains(val, filter_str));
    } else {
      return value;
    }
  }

  private contains(val: string | number | Object, str: string): boolean {
    let res = false;
    if (typeof val === 'object') {
      for (let v in val) {
        if (!val.hasOwnProperty(v)) {
          continue;
        }
        res = this.contains(val[v], str) || res;
      }
    } else if (typeof val === 'string') {
      let re = new RegExp(escapeRegExp(str), 'gi');
      res = re.test(val);
    } else if (typeof val === 'number') {
      let re = new RegExp(str, 'gi');
      res = re.test(val.toString());
    }
    return res;
  }
}
