import { Component, Input } from '@angular/core';

export type TOOLTIP_DESIGN = 'info' | 'warning' | 'definition';

@Component({
  selector: 'tu-tooltip',
  templateUrl: './tooltip.component.html',
})
export class TooltipComponent {
  @Input() design: TOOLTIP_DESIGN = 'info';
  @Input() message: string = null;
  @Input() config: {
    theme?: string;
    animation?: string;
    position?: string;
    width?: string;
    classList?: string;
    bounded?: boolean; //Block position relative to component
  };

  //Click display
  private _clickDisplay = {
    show: false,
    timeout: null,
    delay: 5000,
  };
  public get clickDisplay() {
    return this._clickDisplay.show;
  }
  public set clickDisplay(show: boolean) {
    this._clickDisplay.show = show;
  }
  public toggleTooltip() {
    clearTimeout(this._clickDisplay.timeout);
    this.clickDisplay = !this.clickDisplay;
    if (this.clickDisplay) {
      this._clickDisplay.timeout = setTimeout(() => {
        this.clickDisplay = false;
      }, this._clickDisplay.delay);
    }
  }

  public get classIcon() {
    let classIcon = `tw-group tw-ml-1 tw-text-lg fa 
      ${this?.config?.bounded === false ? null : `tw-relative`} 
    `;
    return `${classIcon} ${this._design}`;
  }

  public get classBlock() {
    return `tw-block tw-absolute tw-z-50 ${this._theme} ${this._width} ${this._position} ${this._animation} ${this.classList}`;
  }

  private get _design() {
    switch (this.design) {
      case 'warning':
        return `fa-exclamation-triangle tw-text-yellow-500`;
      case 'definition':
        return `fa-question-circle-o tw-text-gray-500`;
      default: //Infos
        return `fa-info-circle tw-text-blue-500`;
    }
  }
  private get _theme() {
    if (!this?.config?.theme) return `tw-bg-black tw-text-white tw-p-4 tw-font-sans tw-text-xs`;
    return this.config.theme;
  }
  private get _animation() {
    return `tw-transition-transform tw-origin-top tw-scale-y-0 group-hover:tw-scale-y-100 ${
      this.clickDisplay ? 'tw-scale-y-100' : ''
    }`;
  }
  private get _position() {
    if (!this?.config?.position) return `tw-left-0 tw-top-full`; //left bottom

    if (this.config.position === 'custom') {
      return '';
    }

    const positions = [];
    //Top or Bottom
    if (this.config.position.includes('top')) positions.push('tw-bottom-full');
    else positions.push('tw-top-full');
    //Left, Center or Right
    if (this.config.position.includes('left')) positions.push('tw-left-0');
    else if (this.config.position.includes('right')) positions.push('tw-right-0');
    else positions.push('tw-left-1/2 -tw-translate-x-1/2');

    return positions.join(' ');
  }
  private get _width() {
    if (!this?.config?.width) return `tw-w-72`;
    return this.config.width;
  }

  private get classList(): string {
    return this.config?.classList ?? '';
  }
}
