import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@app/modules/shared/guards/auth.guard';
import { PermissionGuard } from '@app/modules/shared/guards/permission.guard';
import { OrderDetailComponent } from './components/order-detail/order-detail.component';
import { OrdersComponent } from './components/orders.component';

const routes: Routes = [
  {
    path: '',
    canActivate: [AuthGuard],
    canActivateChild: [PermissionGuard],
    data: {
      title: 'pages.list_menu.sales',
      permissions: {
        One: ['ACCESS_ORDERS', 'ACCESS_SCHOOL_ORDERS'],
        redirectTo: '/403',
      },
    },
    children: [
      {
        path: '',
        canActivate: [AuthGuard],
        component: OrdersComponent,
        data: {
          title: '',
        },
      },
      {
        path: ':id',
        canActivate: [AuthGuard],
        component: OrderDetailComponent,
        data: {
          title: 'pages.order_details.sale_details',
        },
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class OrdersRoutingModule {}
