import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { RouterModule } from '@angular/router';
import { SharedModule } from '@app/modules/shared/shared.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SimpleNotificationsModule } from 'angular2-notifications';
import { MomentModule } from 'ngx-moment';
import { ShopService } from '../shop/services/shop.service';
import { OrderDetailComponent } from './components/order-detail/order-detail.component';
import { OrdersComponent } from './components/orders.component';
import { OrdersRoutingModule } from './orders.routing';
import { OrdersService } from './services/orders.service';
import { MatMenuModule } from '@angular/material/menu';
import { OrderCommentComponent } from './components/order-detail/order-comment/order-comment.component';

@NgModule({
  declarations: [OrdersComponent, OrderDetailComponent, OrderCommentComponent],
  imports: [
    OrdersRoutingModule,
    SharedModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    MomentModule,
    NgbModule,
    SimpleNotificationsModule.forRoot(),
    MatSelectModule,
    MatInputModule,
    MatAutocompleteModule,
    MatMenuModule,
  ],
  providers: [OrdersService, ShopService, MatSelectModule],
})
export class OrdersModule {}
