<div *ngIf="!_content">
  <h6 class="px-2 py-1 table-msg">{{ 'otherslabels.loading' | translate }}</h6>
</div>
<div *ngIf="_content && !_content.length">
  <h6 class="px-2 py-1 table-msg">{{ 'otherslabels.no_data' | translate }}</h6>
</div>

<div *ngIf="_content && _content.length > 0" class="tw-w-full tw-overflow-auto">
  <table class="tw-mb-4 tw-min-w-full">
    <caption class="tw-sr-only">Tableau générique d'affichage de données</caption>

    <thead *ngIf="headers">
      <tr>
        <th scope="col" *ngIf="checkboxes !== false" class="tw-whitespace-nowrap tw-px-4 tw-py-3">
          <input
            *ngIf="simpleCheckboxes"
            [checked]="allChecked"
            class="simple-checkbox"
            type="checkbox"
            (change)="checkAll($event.target.checked)"
          />

          <label *ngIf="!simpleCheckboxes" class="switch switch-default switch-primary-outline-alt">
            <input
              [checked]="allChecked"
              class="switch-input"
              type="checkbox"
              (change)="checkAll($event.target.checked)"
            />
            <span class="switch-label"></span>
            <span class="switch-handle"></span>
          </label>
        </th>
        <ng-container *ngFor="let conf of config; let i = index">
          <th
            [class]="'tw-whitespace-nowrap tw-px-4 tw-py-3 ' + conf.class.header"
            [ngClass]="{ function: conf.type === type.Function, icon: conf.type === type.Icon }"
            *ngIf="(isFunction(conf.displayed) ? conf.displayed() : conf.displayed) !== false"
            scope="col"
          >
            <div class="tw-flex tw-items-center">
              <button
                *ngIf="conf.isSortable; else nonSortableColumn"
                class="btn btn_header tw-m-0 tw-flex tw-items-center"
                (click)="changeSort(conf.key)"
              >
                {{ conf.label | translate }}
                <i
                  *ngIf="sortType.key === conf.key"
                  class="fa fa-{{ ['sort', 'sort-asc', 'sort-desc'][sortType?.sort] }}"
                ></i>
                <i *ngIf="sortType.key !== conf.key" class="fa fa-sort"></i>
              </button>

              <ng-template #nonSortableColumn>
                <span>
                  {{ conf.label | translate }}
                </span>
              </ng-template>
            </div>
          </th>
        </ng-container>
      </tr>
    </thead>

    <tbody>
      <tr
        class="hover:tw-bg-gray-50"
        *ngFor="
          let cont of pagination !== false
            ? (_content
              | paginate : { itemsPerPage: perPage, currentPage: selectedPage, id: this.id })
            : _content
        "
        (click)="onRowClicked(cont.elem)"
      >
        <td (click)="$event.stopPropagation()" class="tw-px-4 tw-py-3" *ngIf="checkboxes !== false">
          <input
            *ngIf="simpleCheckboxes"
            [checked]="cont.elem._checked"
            class="simple-checkbox"
            type="checkbox"
            (change)="onRowChecked($event, cont.elem)"
          />

          <label
            *ngIf="!simpleCheckboxes"
            class="switch switch-default switch-pill switch-primary-outline-alt tw-m-0"
          >
            <input
              type="checkbox"
              class="switch-input"
              [checked]="cont.elem._checked"
              (change)="onRowChecked($event, cont.elem)"
            />
            <span class="switch-label"></span>
            <span class="switch-handle"></span>
          </label>
        </td>

        <ng-container *ngFor="let c of cont.values" [ngSwitch]="c.type">
          <td
            [class]="'tw-px-4 tw-py-3 ' + c.class.row"
            *ngIf="(isFunction(c.displayed) ? c.displayed() : c.displayed) !== false"
            [style]="trustStyle(!c.style ? '' : isFunction(c.style) ? c.style(cont.elem) : c.style)"
            [ngClass]="{ has_detail: isClicked }"
          >
            <span
              *ngSwitchCase="type.Text"
              class="tw-whitespace-nowrap"
              translate
              [innerHTML]="c.value || '-' | highlight : _filter"
            ></span>
            <img
              *ngSwitchCase="type.Image"
              [ngClass]="{ 'd-none': !c.value && !c.imgDefault }"
              [src]="c.value"
              alt=""
              [default]="c.imgDefault"
              [attr.height]="c.config?.height || 'auto'"
              [attr.width]="c.config?.width || 'auto'"
            />
            <div
              *ngSwitchCase="type.Avatar"
              class="tw-mx-auto tw-h-9 tw-w-9 tw-overflow-hidden tw-rounded-full"
            >
              <img
                [src]="c.value"
                alt="Avatar"
                [default]="c.imgDefault"
                class="tw-block tw-h-full tw-w-full tw-object-cover"
              />
            </div>
            <a
              *ngSwitchCase="type.Email"
              class="tw-whitespace-nowrap"
              href="mailto:{{ c.value }}"
              target="_blank"
              rel="noopener noreferrer"
              (click)="$event.stopPropagation()"
              [innerHTML]="c.value | highlight : _filter"
            ></a>
            <ng-container *ngSwitchCase="type.Date">
              <span
                *ngIf="!!c.value"
                [innerHTML]="
                  c.value
                    | date : c.format : undefined : translate.currentLang
                    | highlight : _filter
                "
              ></span>
              <span *ngIf="!c.value">&ndash;</span>
            </ng-container>
            <span
              *ngSwitchCase="type.Price"
              [innerHTML]="
                c.value
                  | currency
                    : (isFunction(c.currency) ? c.currency(cont.elem) : c.currency)
                    : 'symbol-narrow'
                    : '1.2-2'
                    : (isFunction(c.locale) ? c.locale(cont.elem) : c.locale || 'fr-FR')
              "
            ></span>
            <span
              *ngSwitchCase="type.Tag"
              class="tag tag-pill {{ getTag(c) }}"
              [innerHTML]="getTagContent(c, cont.elem)"
            ></span>
            <ng-container *ngSwitchCase="type.ToolTip">
              <span
                *ngIf="c.value?.enabledTag"
                class="tag tag-pill tag-{{ tags[c.value.tag.type] }}"
                [innerHTML]="c.value.tag.label | translate"
                [placement]="c.value.placement || 'bottom'"
                [ngbTooltip]="c.value.tooltip !== '' ? c.value.tooltip : ''"
              ></span>
              <span
                *ngIf="c.value === null || !c.value?.enabledTag"
                [placement]="c.value?.placement || 'bottom'"
                [ngbTooltip]="c.value?.tooltip !== '' ? c.value?.tooltip : ''"
                [innerHTML]="c.value?.text !== '' ? c.value?.text : ''"
              ></span>
            </ng-container>
            <ng-container *ngSwitchCase="type.Link" [ngSwitch]="!!c.value.link">
              <ng-container *ngIf="!external(c.value.link)">
                <a
                  *ngSwitchCase="true"
                  [routerLink]="c.value.link"
                  [queryParams]="c.value.params || {}"
                  (click)="$event.stopPropagation()"
                  class="tw-whitespace-nowrap"
                  [innerHTML]="c.value.label | highlight : _filter"
                ></a>
              </ng-container>
              <ng-container *ngIf="external(c.value.link)">
                <a
                  *ngSwitchCase="true"
                  [href]="c.value.link"
                  (click)="$event.stopPropagation()"
                  class="tw-whitespace-nowrap"
                  [innerHTML]="c.value.label | highlight : _filter"
                ></a>
              </ng-container>
              <span
                *ngSwitchCase="false"
                class="tw-whitespace-nowrap"
                [innerHTML]="c.value.label || '-' | highlight : _filter"
              ></span>
            </ng-container>
            <span class="function font-2xl" *ngSwitchCase="type.Function">
              <i
                [class]="c.value.icon.startsWith('icon-') ? c.value.icon : 'fa fa-' + c.value.icon"
                (click)="c.value.fn(cont.elem)"
              ></i>
            </span>
            <span
              *ngSwitchCase="type.Icon"
              class="icon font-2xl"
              [title]="c.config.labels?.[c.value]"
            >
              <ng-container
                *ngFor="let ic of stringToArray(c.config.icons[c.value]); let last = last"
              >
                <ng-container *ngIf="c.config?.source === 'heroicons'; else otherIconSource">
                  <tu-hero-icon
                    [path]="ic"
                    klass="tw-h-6 tw-inline-block tw-mx-auto tw-text-gray-700"
                  ></tu-hero-icon>
                </ng-container>

                <ng-template #otherIconSource>
                  <i
                    *ngIf="c.config.icons[c.value]"
                    [ngClass]="{ icon_space: !last }"
                    [class]="ic.startsWith('icon-') ? ic : 'fa fa-' + ic"
                  ></i>
                </ng-template>
              </ng-container>
            </span>
            <div *ngSwitchCase="type.Submenu" class="tw-relative">
              <tu-hero-icon
                [path]="c.config.icon"
                klass="tw-h-6 tw-inline-block tw-mx-auto tw-text-gray-700 tw-cursor-pointer"
                (click)="submenuOpen = cont.elem.id; $event.stopPropagation(); false"
              ></tu-hero-icon>
              <!--Click away trigger-->
              <div
                *ngIf="submenuOpen"
                (click)="submenuOpen = false"
                class="tw-fixed tw-inset-0 tw-z-20 tw-h-screen tw-w-screen"
              ></div>
              <ul
                *ngIf="submenuOpen === cont.elem.id"
                class="tw-absolute tw-right-0 tw-top-0 tw-z-30 tw-flex tw-w-40 tw-flex-col tw-rounded-md tw-bg-white tw-shadow-xl"
              >
                <li
                  *ngFor="let action of c.config.actions"
                  (click)="submenuOpen = false; action.fn(cont.elem); $event.stopPropagation(); false"
                  class="tw-py-2 tw-px-6 hover:tw-bg-gray-100"
                  [ngClass]="{
                    'tw-cursor-pointer': action.active(cont.elem),
                    'tw-opacity-30': !action.active(cont.elem)
                  }"
                >
                  <tu-hero-icon
                    *ngIf="action.icon"
                    [path]="action.icon"
                    klass="tw-h-6 tw-inline-block tw-mx-auto tw-text-gray-700"
                  ></tu-hero-icon>
                  {{ action.label | translate }}
                </li>
              </ul>
            </div>
            
            <div *ngSwitchCase="type.Select">
              <mat-select [(value)]="c.value" [disabled]="selectIsDisabled(c, c.value)" (selectionChange)="c.change($event.value, cont.elem)">
                <mat-select-trigger>
                  <span class="tag tag-pill tag-{{ tags[getSelectedTagOption(c.value, c.config.options)] }}">
                    {{ (getSelectedOption(c.value, c.config.options).label) | translate }}
                  </span>
                </mat-select-trigger>
                <mat-option *ngFor="let option of c.config.options" [value]="option.value" [disabled]="option.disabled">
                  <span 
                    class="tag tag-pill tag-{{ tags[option.type] }}"
                    [ngClass]="{
                      'tw-opacity-75': option.disabled
                    }"
                  >
                    {{ option.label | translate }}
                  </span>
                </mat-option>
              </mat-select>
            </div>
          </td>
        </ng-container>
      </tr>
    </tbody>
  </table>

  <pagination-controls
    *ngIf="pagination !== false"
    [id]="id"
    (pageChange)="pageChanged($event); selectedPage = $event"
    maxSize="8"
    previousLabel="{{ 'otherslabels.label_page_prev' | translate }}"
    nextLabel="{{ 'otherslabels.label_page_next' | translate }}"
  ></pagination-controls>
</div>

<simple-notifications [options]="notificationOptions"></simple-notifications>
