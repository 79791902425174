<h5 *ngIf="withTitle" mat-dialog-title class="mb-1">
  <ng-container *ngIf="userPhoto.uri">
    {{ 'otherslabels.alert_ask_photo' | translate }}
  </ng-container>
  <ng-container *ngIf="!userPhoto.uri">
    {{ 'otherslabels.label_choose_photo' | translate }}
  </ng-container>
</h5>
<div mat-dialog-content class="tw-max-h-screen">
  <div class="ctn">
    <div id="modal-context" class="tw-m-2 tw-flex tw-flex-col tw-space-y-2 tw-text-center">
      <label
        class="m-0 btn btn-sm btn-primary tw-flex tw-h-9 tw-items-center tw-justify-center tw-rounded"
        (click)="onClickToggleUpload()"
      >
        <span>
          {{
            userPhoto.uri
              ? ('pages.customer_image_modal.change_image_button' | translate)
              : ('pages.customer_image_modal.new_image_button' | translate)
          }}
        </span>
        <input
          #upload
          [hidden]="true"
          (change)="onFileSelect($event)"
          type="file"
          id="userPhoto"
          name="userPhoto"
          accept="image/*"
        />
      </label>
      <button
        *ngIf="userPhoto.uri && !userPhoto.isCropping"
        mat-flat-button
        class="mt-0 btn btn-sm btn-primary"
        (click)="onClickToggleImageCrop(true)"
      >
        <span>{{ 'pages.customer_image_modal.crop_image_button' | translate }}</span>
      </button>
      <button
        *ngIf="userPhoto.uri && userPhoto.isCropping"
        mat-flat-button
        class="mt-0 btn btn-sm btn-secondary"
        (click)="onClickToggleImageCrop(false)"
      >
        <span>{{ 'pages.customer_image_modal.cancel_crop_button' | translate }}</span>
      </button>
      <div>
        <tu-image-cropper
          *ngIf="userPhoto.isCropping"
          (newCroppedImageUri)="onChangeCroppedImageUri($event)"
          [src]="userPhoto.uri"
        ></tu-image-cropper>
        <label *ngIf="!userPhoto.isCropping" for="userPhoto" class="tw-mx-auto tw-h-80 tw-w-80">
          <img
            class="tw-h-full tw-w-full tw-object-contain"
            default="/assets/img/user.svg"
            alt="photo de l'utilisateur"
            [src]="userPhoto.uri"
          />
        </label>
      </div>
    </div>
    <div mat-dialog-actions class="pull-right tw-m-0 tw-mt-2 tw-p-0" style="min-height: auto">
      <button
        *ngIf="data.isTicketPicture"
        type="button"
        mat-flat-button
        (click)="closeModal()"
        [disabled]="loading"
        class="mt-0 mr-1 btn btn-sm btn-secondary"
      >
        {{ 'otherslabels.btn_cancel' | translate }}
      </button>
      <button
        [disabled]="loading || (!data.isTicketPicture && !userPhoto.isModified)"
        mat-flat-button
        class="mt-0 btn btn-sm btn-primary"
        (click)="onClickSubmitUserPhoto()"
      >
        <i class="fa fa-circle-o-notch fa-spin" [ngClass]="{ 'd-none': !loading }"></i>
        <span>
          {{
            !loading ? ('otherslabels.btn_save' | translate) : ('otherslabels.loading' | translate)
          }}
        </span>
      </button>
    </div>
  </div>
</div>
<simple-notifications [options]="notificationOptions"></simple-notifications>
