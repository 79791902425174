<h5 mat-dialog-title class="mb-1">{{ title }}</h5>

<div mat-dialog-content>
  <ng-container *ngIf="!isForbidden; else anonymizeForbidden">
    <p>{{ 'pages.customer_details.anonymization_modal_message_1' | translate }}</p>
    <p>
      {{ 'pages.customer_details.anonymization_modal_message_2' | translate }}
      <strong>{{ isCustomerEmailAvailable ? data.customerEmail : data.customerId }}</strong>
      {{ 'pages.customer_details.anonymization_modal_message_3' | translate }}
    </p>
    <form class="mb-1">
      <input
        type="text"
        name="anonymizeInput"
        [placeholder]="
          isCustomerEmailAvailable
            ? ('pages.customer_details.customer_email' | translate)
            : ('pages.customer_details.customer_id' | translate)
        "
        class="form-control"
        [disabled]="loading"
        [(ngModel)]="anonymizeInput"
      />
    </form>
  </ng-container>
  <ng-template #anonymizeForbidden>
    <p>
      {{ 'pages.customer_details.forbidden_anonymize' | translate }}
    </p>
    <p>
      <strong>
        {{ 'pages.customer_details.ask_for_delete' | translate }}
      </strong>
    </p>
  </ng-template>
</div>

<div mat-dialog-actions class="pull-right">
  <button (click)="onCancel()" [disabled]="loading" class="mt-0 mr-1 btn btn-sm btn-secondary">
    {{ 'otherslabels.btn_cancel' | translate }}
  </button>

  <button
    *ngIf="!isForbidden"
    (click)="confirmAnonymisation()"
    [disabled]="loading || isDisabled"
    class="mt-0 btn btn-sm btn-danger"
  >
    <i class="fa fa-circle-o-notch fa-spin" [ngClass]="{ 'd-none': !loading }"></i>
    {{ !loading ? ('otherslabels.btn_confirm' | translate) : ('otherslabels.loading' | translate) }}
  </button>
</div>

<simple-notifications [options]="notificationOptions"></simple-notifications>
