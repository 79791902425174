import {
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Customer } from '@app/modules/customers/models/customer';
import { TranslateService } from '@ngx-translate/core';
import { NotificationsService } from 'angular2-notifications';
import { firstValueFrom } from 'rxjs';
import { CustomersService } from '../../../services/customers.service';

//https://stackoverflow.com/questions/4998908/convert-data-uri-to-file-then-append-to-formdata
function dataURItoBlob(dataURI: string) {
  // convert base64/URLEncoded data component to raw binary data held in a string
  let byteString: string;
  if (dataURI.split(',')[0].indexOf('base64') >= 0) byteString = atob(dataURI.split(',')[1]);
  else byteString = unescape(dataURI.split(',')[1]);
  // separate out the mime component
  const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
  // write the bytes of the string to a typed array
  const intArray = new Uint8Array(byteString.length);
  for (let i = 0; i < byteString.length; i++) {
    intArray[i] = byteString.charCodeAt(i);
  }
  return new Blob([intArray], { type: mimeString });
}

export interface DialogData {
  customerId: string;
  picture: string;
  isTicketPicture: boolean;
}
@Component({
  selector: 'tu-avatar-update-modal',
  templateUrl: './avatar-update-modal.component.html',
})
export class ImageUpdateModalComponent implements OnInit {
  @Input('withTitle')
  public withTitle = true;

  @Input('customerData')
  public customerData: Customer = null;

  @Output() updateField = new EventEmitter();

  @ViewChild('image', { static: false })
  public imageElement: ElementRef;

  public userPhoto = {
    file: null as File | null,
    uri: '',
    isModified: false,
    croppedUri: '',
    isCropping: false,
  };

  public loading: boolean = false;

  public notificationOptions = {
    timeOut: 3000,
    showProgressBar: true,
    pauseOnHover: false,
    clickToClose: false,
  };

  constructor(
    public modalRef: MatDialogRef<ImageUpdateModalComponent>,
    private customersService: CustomersService,
    private translateService: TranslateService,
    private notification: NotificationsService,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {}

  ngOnInit() {
    this.userPhoto.uri = this.customerData ? this.customerData.picture : this.data.picture;
  }

  public closeModal(pictureUri?: string): void {
    this.modalRef.close(pictureUri);
  }

  public onChangeCroppedImageUri(newCroppedImageUri: string) {
    this.userPhoto.croppedUri = newCroppedImageUri;
  }

  public onClickToggleImageCrop(isCropping: boolean) {
    this.userPhoto.isCropping = isCropping;
    this.userPhoto.isModified = isCropping || Boolean(this.userPhoto.file);
  }

  public onClickToggleUpload() {
    this.userPhoto.isCropping = false;
  }

  public onFileSelect(event) {
    const file = event.target.files?.[0];
    if (!file) return;

    let reader = new FileReader();

    reader.addEventListener(
      'load',
      () => {
        this.userPhoto.uri = reader.result as string;
      },
      false
    );
    reader.readAsDataURL(file);
    this.userPhoto.file = file;
    this.userPhoto.isModified = true;
  }

  public async onClickSubmitUserPhoto() {
    if (this.data.isTicketPicture && !this.userPhoto.isModified)
      this.closeModal(this.userPhoto.uri);

    if (!this.userPhoto.isModified) return;

    try {
      this.loading = true;

      if (this.userPhoto.isCropping) {
        const blob = dataURItoBlob(this.userPhoto.croppedUri);
        const file = new File([blob], this.userPhoto.croppedUri);
        this.userPhoto.file = file;
        this.userPhoto.uri = this.userPhoto.croppedUri;
      }

      const customerId = this.customerData ? this.customerData.customer_id : this.data.customerId;

      const response = await firstValueFrom(
        this.customersService.uploadImage(customerId, this.userPhoto.file)
      );

      if (response.success && 'url' in response) {
        this.userPhoto.uri = response.url;

        if (!this.data.isTicketPicture) {
          this.notification.success(
            this.translateService.instant(
              'pages.customer_details.update_avatar_notification_title'
            ),
            this.translateService.instant(
              'pages.customer_details.update_avatar_notification_content'
            )
          );
        }
      }

      if (response.error) {
        throw new Error();
      } else {
        const customerId = this.customerData ? this.customerData.customer_id : this.data.customerId;

        const customer = await this.customersService.getCustomerDetails(customerId);

        this.updateField.emit(customer);
        this.notification.success(
          this.translateService.instant(`pages.customer_details.information_changes_success`)
        );

        if (!this.customerData) {
          this.closeModal(this.userPhoto.uri);
        }
      }
    } catch (err) {
      console.error(err);
      this.notification.error(
        this.translateService.instant('pages.customer_details.generic_error')
      );
    } finally {
      this.loading = false;
    }
  }
}
