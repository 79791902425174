import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GenericResponse } from '@app/modules/shared/models/generic-response';
import { Observable, firstValueFrom, map } from 'rxjs';
import { environment as env } from '@env/environment';

@Injectable({
  providedIn: 'root',
})
export class PaperworkService {
  constructor(private http: HttpClient) {}

  public updateDocument(
    documentId: number | string,
    params?: {
      status?: string;
      startingAt?: string;
      expiresAt?: string;
      comment?: string;
      examined?: string;
    }
  ): Observable<Document> {
    return this.http
      .patch<GenericResponse<{ document: Document }>>(
        env.config.feedRoot + `Paperwork/updateDocument`,
        {
          documentId,
          ...params,
        }
      )
      .pipe(map(({ response }) => response.document));
  }

  public createDocument(customerId: number, typeId: string, file: File, status?: string) {
    const formData = new FormData();
    formData.append('customerId', `${customerId}`);
    formData.append('typeId', typeId);
    formData.append('file', file);
    formData.append('status', status);

    return firstValueFrom(
      this.http
        .post<GenericResponse<{ document: Document }>>(
          env.config.feedRoot + `Paperwork/createDocument`,
          formData
        )
        .pipe(map(({ response }) => response.document))
    );
  }
}
