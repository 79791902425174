<h4 class="bg-info tw-p-2 tw-font-bold" mat-dialog-title>
  <span>{{ data.title }}</span>
</h4>
<div mat-dialog-content>
  <p class="tw-text-lg" [innerHtml]="data.text"></p>
</div>
<div mat-dialog-actions>
  <button mat-button class="btn btn-secondary" [mat-dialog-close]="false">
    {{ 'otherslabels.btn_cancel' | translate }}
  </button>
  <button mat-button class="btn btn-primary tw-ml-auto tw-mr-0" [mat-dialog-close]="true">
    {{ 'otherslabels.btn_confirm' | translate }}
  </button>
</div>
