<div class="tw-px-4">
  <h3 class="tw-text-lg">{{ 'pages.shared.format_direction' | translate }}</h3>
  <p>{{ 'pages.shared.markdown_use' | translate }}</p>

  <h4 class="tw-text-lg">{{ 'pages.shared.reminders' | translate }} :</h4>
  <table class="tw-min-w-full">
    <caption class="tw-sr-only">{{ 'pages.shared.reminders_list' | translate }}</caption>

    <thead>
      <tr>
        <th scope="col" class="tw-whitespace-nowrap tw-px-4 tw-py-3">
          {{ 'pages.shared.reminder_style' | translate }}
        </th>
        <th scope="col" class="tw-whitespace-nowrap tw-px-4 tw-py-3">
          {{ 'pages.shared.reminder_syntax' | translate }}
        </th>
        <th scope="col" class="tw-whitespace-nowrap tw-px-4 tw-py-3">
          {{ 'pages.shared.reminder_exemple' | translate }}
        </th>
        <th scope="col" class="tw-whitespace-nowrap tw-px-4 tw-py-3">
          {{ 'pages.shared.reminder_result' | translate }}
        </th>
      </tr>
    </thead>

    <tbody>
      <tr>
        <td class="tw-whitespace-nowrap tw-px-4 tw-py-3">
          {{ 'pages.shared.color' | translate }}
        </td>
        <td class="tw-whitespace-nowrap tw-px-4 tw-py-3">
          {{ 'pages.shared.color_hint' | translate }}
          <br />
          <b>{{ 'pages.shared.color_options' | translate }}</b>
          {{ 'pages.shared.color_options_label' | translate }}
        </td>
        <td class="tw-whitespace-nowrap tw-px-4 tw-py-3">
          {{ 'pages.shared.color_hint_red' | translate }}
        </td>
        <td class="tw-whitespace-nowrap tw-px-4 tw-py-3 tw-text-red-500">
          {{ 'pages.shared.color_red' | translate }}
        </td>
      </tr>
      <tr>
        <td class="tw-whitespace-nowrap tw-px-4 tw-py-3">
          {{ 'pages.shared.bold' | translate }}
        </td>
        <td class="tw-whitespace-nowrap tw-px-4 tw-py-3">** ** ou __ __</td>
        <td class="tw-whitespace-nowrap tw-px-4 tw-py-3">
          {{ 'pages.shared.bold_exemple' | translate }}
        </td>
        <td class="tw-whitespace-nowrap tw-px-4 tw-py-3">
          <b>{{ 'pages.shared.bold_text' | translate }}</b>
        </td>
      </tr>

      <tr>
        <td class="tw-whitespace-nowrap tw-px-4 tw-py-3">
          {{ 'pages.shared.italic' | translate }}
        </td>
        <td class="tw-whitespace-nowrap tw-px-4 tw-py-3">* * ou _ _</td>
        <td class="tw-whitespace-nowrap tw-px-4 tw-py-3">
          {{ 'pages.shared.italic_exemple' | translate }}
        </td>
        <td class="tw-whitespace-nowrap tw-px-4 tw-py-3">
          <i>{{ 'pages.shared.bold_text' | translate }}</i>
        </td>
      </tr>

      <tr>
        <td class="tw-whitespace-nowrap tw-px-4 tw-py-3">
          {{ 'pages.shared.strikethrough' | translate }}
        </td>
        <td class="tw-whitespace-nowrap tw-px-4 tw-py-3">~~ ~~</td>
        <td class="tw-whitespace-nowrap tw-px-4 tw-py-3">
          {{ 'pages.shared.strikethrough_exemple' | translate }}
        </td>
        <td class="tw-whitespace-nowrap tw-px-4 tw-py-3">
          <s>{{ 'pages.shared.strikethrough_text' | translate }}</s>
        </td>
      </tr>

      <tr>
        <td class="tw-whitespace-nowrap tw-px-4 tw-py-3">
          {{ 'pages.shared.link' | translate }}
        </td>
        <td class="tw-whitespace-nowrap tw-px-4 tw-py-3">
          {{ 'pages.shared.link_exemple' | translate }}
        </td>
        <td class="tw-whitespace-nowrap tw-px-4 tw-py-3">
          {{ 'pages.shared.link_test' | translate }}
        </td>
        <td class="tw-whitespace-nowrap tw-px-4 tw-py-3">
          <a href="https://airweb.fr">{{ 'pages.shared.link_test' | translate }}</a>
        </td>
      </tr>

      <tr>
        <td class="tw-whitespace-nowrap tw-px-4 tw-py-3">
          {{ 'pages.shared.title' | translate }}
        </td>
        <td class="tw-whitespace-nowrap tw-px-4 tw-py-3">
          {{ 'pages.shared.title_hierarchy' | translate }}
        </td>
        <td class="tw-whitespace-nowrap tw-px-4 tw-py-3">
          # {{ 'pages.shared.title_main' | translate }}
          <br />
          ### {{ 'pages.shared.title_minor' | translate }}
        </td>
        <td class="tw-whitespace-nowrap tw-px-4 tw-py-3">
          <h1>{{ 'pages.shared.title_main' | translate }}</h1>
          <h3>{{ 'pages.shared.title_minor' | translate }}</h3>
        </td>
      </tr>
    </tbody>
  </table>
</div>
