<div *ngIf="order" id="order-detail">
  <div [ngClass]="{ order_ctn_contract: showComments }" class="row">
    <div class="col-xs-12">
      <div class="card">
        <div
          class="card-header card-inverse md:tw-flex"
          [ngClass]="{
            'card-info': order.status === OrderStatus.pending,
            'card-success':
              order.status === OrderStatus.completed || order.status === OrderStatus.preauthorized,
            'card-warning': order.status === OrderStatus.canceled,
            'card-primary': order.status === OrderStatus.to_refund,
            'card-danger': order.status === OrderStatus.error,
            'card-reg': order.status === OrderStatus.regularization,
            'card-waiting_payment': order?.status === OrderStatus.waiting_for_payment,
            'card-refunded': order.status === OrderStatus.refunded,
            'card-danger': order.status === OrderStatus.defaultPayment
          }"
          [ngSwitch]="order.status"
        >
          <h5 class="tw-m-auto tw-text-center tw-text-lg tw-grow md:tw-text-left md:tw-text-xl">
            <span>{{ 'pages.order_details.sale_id' | translate }}</span>
            <strong>{{ order.order_identifier }}</strong>
          </h5>

          <div class="md:ml-auto status-selector tw-m-auto">
            <mat-form-field>
              <mat-select
                [disableOptionCentering]="true"
                [(value)]="selectedStatus"
                (selectionChange)="updateStatus($event.value)"
              >
                <mat-option *ngFor="let status of statusSelectOptions" [value]="status.value">
                  <h6 style="margin-top: 0.5em">
                    <span class="mt-8 tag tag-pill tag-{{ getCssClass(status.value) }}">
                      {{ status.label | translate }}
                    </span>
                  </h6>
                </mat-option>
              </mat-select>
            </mat-form-field>

            <span><i class="icon-note"></i></span>
          </div>
        </div>

        <div class="card-block tw-flex tw-flex-col md:tw-flex-row">
          <div>
            <img
              class="logo-network tw-mx-auto md:tw-ml-4"
              height="80px"
              [src]="order.network_logo"
              alt="Logo du réseau"
            />

            <div style="display: inline-flex" class="align-items-center tw-mx-auto md:tw-ml-4">
              <img
                *ngIf="order.universal"
                class="align-middle logo-tixi float-md-left text-md-left text-xs-center"
                style="height: 40px"
                src="/assets/img/logo_tixipass_big.png"
                alt="Logo TixiPass"
              />

              <img
                *ngIf="order.issuer === 'sncf'"
                class="align-middle logo-tixi float-md-left text-md-left text-xs-center"
                style="height: 40px"
                src="/assets/img/providers/sncf.png"
                alt="Logo SNCF"
              />

              <ng-container *ngIf="order.client">
                <img
                  *ngIf="order.client.slug === 'modalis'"
                  class="align-middle logo-tixi float-md-left text-md-left text-xs-center"
                  style="height: 40px"
                  src="/assets/img/providers/modalis.png"
                  alt="Logo Modalis"
                />
                <img
                  *ngIf="order.client.slug === 'smt_nfc'"
                  class="align-middle logo-tixi float-md-left text-md-left text-xs-center"
                  style="height: 40px"
                  src="/assets/img/providers/smt_nfc.png"
                  alt="Logo SMTNFC"
                />
              </ng-container>
            </div>

            <div class="tw-flex tw-flex-col tw-space-y-1 tw-text-center md:tw-text-left">
              <p class="tw-m-0 tw-font-bold">
                {{ 'pages.order_details.order_date_label' | translate }}
              </p>

              <time
                [dateTime]="order.date"
                class="tw-flex tw-items-center tw-justify-center tw-space-x-1 tw-text-lg md:tw-justify-start"
                [title]="order.date"
              >
                <span class="tw-font-bold">
                  {{ order.date | date : 'longDate' : undefined : translate.currentLang }}
                </span>

                <span>-</span>

                <span>
                  {{ order.date | date : 'shortTime' : undefined : translate.currentLang }}
                </span>
              </time>
            </div>

            <div
              *ngIf="latestCheckout"
              class="tw-mt-4 tw-flex tw-flex-col tw-space-y-1 tw-text-center md:tw-text-left"
            >
              <p class="tw-m-0 tw-font-bold">
                {{ 'pages.order_details.checkout_date_label' | translate }}
              </p>

              <time
                [dateTime]="latestCheckout.createdAt"
                class="tw-flex tw-items-center tw-justify-center tw-space-x-1 tw-text-lg md:tw-justify-start"
                [title]="latestCheckout.createdAt"
              >
                <!-- prettier-ignore -->
                <span class="tw-font-bold">{{ latestCheckout.createdAt | date : 'longDate' : undefined : translate.currentLang }}</span>

                <span>-</span>

                <!-- prettier-ignore -->
                <span>{{ latestCheckout.createdAt | date : 'shortTime' : undefined : translate.currentLang }}</span>
              </time>
            </div>
          </div>

          <div
            class="tw-mx-auto tw-mt-4 tw-flex tw-flex-col tw-text-center md:tw-mt-0 md:tw-mr-4 md:tw-text-right"
          >
            <h4 class="mb-0 card-title">
              <div class="align-middle d-inline-block">
                <a
                  class="d-block"
                  [routerLink]="['/customers', order.user.customer_id]"
                  *ngIf="!order.wasCustomerAuthAsGuest"
                >
                  {{ order.user.firstname }} {{ order.user.lastname }}
                </a>
                <a
                  *ngIf="!order.wasCustomerAuthAsGuest"
                  class="mb-0 h5 d-block text-muted"
                  href="mailto:{{ order.user.email }}"
                  target="_blank"
                  rel="noopener noreferrer"
                  (click)="$event.stopPropagation()"
                >
                  <small>{{ order.user.email }}</small>
                </a>

                <a
                  *ngIf="order.wasCustomerAuthAsGuest"
                  class="d-block"
                  [routerLink]="['/customers', order.user.customer_id]"
                >
                  <small>{{ order.user.customer_id }}</small>
                </a>
              </div>

              <img
                class="ml-1 btn-hand img-avatar avatar d-inline-block tw-relative tw-overflow-hidden tw-align-middle"
                [routerLink]="['/customers', order.user.customer_id]"
                [src]="order.user.picture"
                [default]="
                  '/assets/img/user' + (order.wasCustomerAuthAsGuest ? '-anonymous' : '') + '.svg'
                "
                alt="Photo de profil"
              />
            </h4>

            <div *ngIf="order.origin_type" class="tw-mt-4 tw-font-bold">
              {{ 'pages.order.origin' | translate }} : {{ order.origin_type }}
            </div>

            <button
              [hasOnePermission]="['CHECKOUT_ORDER']"
              (click)="openCheckoutModalConfirmation()"
              type="button"
              class="btn btn-primary tw-mt-4 md:tw-mt-auto md:tw-ml-auto"
            >
              {{ 'pages.order_details.checkout_btn_label' | translate }}
            </button>
          </div>
        </div>

        <div
          class="card-footer card-inverse"
          *ngIf="showFooterHeader"
          [ngClass]="{
            'card-default': order.process_status === 'NULL',
            'card-success': [
              OrderProcessStatus.processed,
              OrderProcessStatus.autoprocessed
            ].includes(order.process_status),
            'card-warning': order.process_status === OrderProcessStatus.processing,
            'card-info': order.process_status === OrderProcessStatus.to_process
          }"
          [ngSwitch]="order.process_status"
        >
          <h5>
            {{ 'pages.order_details.process_title' | translate }}
          </h5>

          <div
            class="status-selector"
            *ngIf="selectedProcessStatus !== 'AUTOPROCESSED'; else noProcessStatusUpdate"
          >
            <mat-form-field>
              <mat-select
                [disableOptionCentering]="true"
                [(value)]="selectedProcessStatus"
                (selectionChange)="updateProcessStatus($event.value)"
              >
                <mat-option
                  *ngFor="let status of processStatusSelectOptions"
                  [value]="status.value"
                >
                  <h6 style="margin-top: 0.5em">
                    <span class="mt-8 tag tag-pill tag-{{ getCssClass(status.value) }}">
                      {{ status.label | translate }}
                    </span>
                  </h6>
                </mat-option>
              </mat-select>
            </mat-form-field>

            <span><i class="icon-note"></i></span>
          </div>

          <ng-template #noProcessStatusUpdate>
            <span class="mt-8 tag tag-pill tag-{{ getCssClass(selectedProcessStatus) }}">
              {{ 'pages.order.autoprocessed' | translate }}
            </span>
          </ng-template>
        </div>
      </div>
    </div>

    <div class="col-xs-12">
      <div class="card items" *ngIf="order?.items">
        <div
          class="tw-flex tw-items-center tw-justify-between tw-border-b tw-border-gray-300 tw-py-3 tw-px-5"
        >
          <span>{{ 'pages.order_details.products' | translate }}</span>

          <span
            *ngIf="order?.payment_method"
            class="tag tag-pill tag-{{ getPaymentMethodCssClass(order.payment_method) }}"
          >
            {{ getPaymentMethodLabel(order.payment_method) | translate }}
          </span>
        </div>
        <div class="card-block table-responsive tw-overflow-auto">
          <div class="row tw-overflow-auto">
            <div class="tw-w-full tw-overflow-auto">
              <table class="table table-hover table-align-middle tw-table-auto">
                <caption class="tw-sr-only">
                  {{ 'pages.order_details.order_products' | translate }}
                </caption>

                <thead>
                  <tr>
                    <th scope="col">{{ 'pages.order_details.products' | translate }}</th>
                    <th scope="col">{{ 'pages.order_details.quantity' | translate }}</th>
                    <th scope="col">{{ 'pages.order_details.unit_price' | translate }}</th>
                    <th *ngIf="!zeroVat" scope="col">
                      {{ 'pages.order_details.sub_total' | translate }}
                    </th>
                    <th scope="col">{{ 'pages.order_details.recipient' | translate }}</th>
                    <th scope="col">{{ 'pages.order_details.voucher' | translate }}</th>
                    <th scope="col">{{ 'pages.order.delivery' | translate }}</th>
                    <th scope="col">{{ 'pages.order_details.product_type' | translate }}</th>
                    <th scope="col">{{ 'pages.order_details.booking_details' | translate }}</th>
                  </tr>
                </thead>

                <tbody [formGroup]="fieldForm">
                  <ng-container *ngFor="let item of order.items">
                    <tr>
                      <td>
                        <div >
                          <p [ngClass]="{'tw-mb-0': !getProductTrip(item.id)?.origin || !getProductTrip(item.id)?.destination}">{{ item.title }}</p>
                          <p *ngIf="getProductTrip(item.id)?.origin">{{ 'pages.order_details.origin' | translate }} : {{ getProductTrip(item.id).origin.label }}</p>
                          <p 
                            *ngIf="getProductTrip(item.id)?.destination"
                            [class]="{
                              'tw-mb-0': !getProductTrip(item.id)?.via
                            }"
                          >{{ 'pages.order_details.destination' | translate }} : {{ getProductTrip(item.id).destination.label }}</p>
                          <p *ngIf="getProductTrip(item.id)?.via" class="tw-mb-0">{{ 'pages.order_details.via' | translate }} : {{ getProductTrip(item.id).via.label }}</p>
                        </div>
                        <!-- FIXME: There is a backend bug that neeeds to be adressed before
                             Removing the `item.support === 'MEDIA' below.
                             At the moment, EVERY item has a `startingAt` date which doesn't really
                             work for what we want to do
                        -->
                        <p *ngIf="item.startingAt && item.support === 'MEDIA'">
                          <span>{{ 'pages.order_details.start_date' | translate }} :</span>
                          <span class="tw-capitalize">
                            {{
                              item.startingAt | date : 'MMMM' : undefined : translate.currentLang
                            }}
                          </span>
                        </p>
                        <ul *ngIf="item.is_od" class="mt-1 mb-0 ml-1">
                          <li *ngFor="let product of item.products" class="od_data">
                            <span>
                              {{ product.origin_name }} -
                              {{
                                product.origin_at
                                  | date : 'shortDate' : undefined : translate.currentLang
                              }}
                              {{
                                product.origin_at
                                  | date : 'shortTime' : undefined : translate.currentLang
                              }}
                              ⟶
                              {{ product.destination_name }} -
                              {{
                                product.destination_at
                                  | date : 'shortDate' : undefined : translate.currentLang
                              }}
                              {{
                                product.destination_at
                                  | date : 'shortTime' : undefined : translate.currentLang
                              }}
                            </span>
                            <small>
                              {{ 'pages.ticket_details.number_seats' | translate }} :
                              {{ product.seats }}
                            </small>
                            <small>
                              {{ 'pages.ticket_details.number_seats_prm' | translate }} :
                              {{ product.seats_prm }}
                            </small>
                          </li>
                        </ul>
                      </td>
                      <td style="vertical-align: top">{{ item.qty }}</td>

                      <td style="vertical-align: top">
                        {{
                          item.price_wo_vat
                            | currency
                              : curr
                              : 'symbol-narrow'
                              : '1.2-2'
                              : this.translate.currentLang
                        }}
                      </td>
                      <td *ngIf="!zeroVat" style="vertical-align: top">
                        <span *ngIf="item.discounted_qty > 0; else elseBlock">
                          {{
                            getDiscountVatPrice(item)
                              | currency
                                : curr
                                : 'symbol-narrow'
                                : '1.2-2'
                                : this.translate.currentLang
                          }}
                        </span>
                        <ng-template #elseBlock>
                          <span>
                            {{
                              item.price_w_vat * item.qty
                                | currency
                                  : curr
                                  : 'symbol-narrow'
                                  : '1.2-2'
                                  : this.translate.currentLang
                            }}
                          </span>
                        </ng-template>
                      </td>
                      <td style="vertical-align: top">
                        <a [routerLink]="['/customers/' + item.user.customer_id]" *ngIf="item.user">
                          {{ item.user.customer_id }} - {{ item.user.fullname }}
                        </a>
                      </td>
                      <td style="vertical-align: top">
                        <span *ngIf="item.discounted_qty > 0">
                          <span *ngIf="item.voucher; else elseBlock">
                            {{ item.voucher }}
                          </span>

                          <ng-template #elseBlock>
                            {{ getDiscountSourceItem(item.id) }}
                          </ng-template>
                        </span>
                      </td>
                      <td style="vertical-align: top">
                        <div class="d-flex justify-content-center">
                          <span class="icon font-2xl" [title]="supportTitle(item)">
                            <tu-hero-icon
                              [path]="supportIcon(item)"
                              klass="tw-h-6 tw-inline-block tw-mx-auto tw-text-gray-700"
                            ></tu-hero-icon>
                          </span>
                        </div>
                      </td>
                      <td style="vertical-align: top">
                        {{ (getProductBooking(item.id) ? 'pages.order_details.booking' : 'pages.order_details.default_product') | translate }}
                      </td>
                      <td style="vertical-align: top">
                        <div *ngIf="getProductBooking(item.id)">
                          <p><strong>{{ 'pages.order_details.origin' | translate }} :</strong> {{ getProductBooking(item.id).tripDepartureLocationLabel }} </p>
                          <p><strong>{{ 'pages.order_details.destination' | translate }} :</strong> {{ getProductBooking(item.id).tripArrivalLocationLabel }} </p>
                          <p><strong>{{ 'pages.order_details.departure_date' | translate }} :</strong> {{ getProductBooking(item.id).tripDepartureDate | date: 'medium' }}</p>
                          <p><strong>{{ 'pages.order_details.arrival_date' | translate }} :</strong> {{ getProductBooking(item.id).tripArrivalDate | date: 'medium' }}</p>
                        </div>
                      </td>
                    </tr>
                    <tr class="field_row" *ngIf="item.fields?.length" [formGroupName]="item.id">
                      <td colspan="6">
                        <div class="flex">
                          <div class="fields">
                            <div *ngFor="let field of item.fields" class="field">
                              <strong>{{ field.label }}</strong>
                              <br />
                              <ng-container *ngIf="!edit[item.id]">
                                <span *ngIf="field.value_id !== null">
                                  {{ getValue(field.values, field.value_id) }}
                                </span>
                                <span *ngIf="field.value !== null">
                                  {{ formatFieldValue(field.type, field.value) }}
                                </span>
                                <span
                                  class="unknown"
                                  *ngIf="field.value_id === null && field.value === null"
                                >
                                  {{ 'otherslabels.label_not_specified' | translate }}
                                </span>
                              </ng-container>
                              <ng-container [ngSwitch]="field.type" *ngIf="edit[item.id]">
                                <select
                                  *ngSwitchCase="'SELECT'"
                                  class="minimal-select"
                                  [formControlName]="field.field_id"
                                >
                                  <option [ngValue]="null" *ngIf="field.mandatory === '0'">
                                    {{ 'otherslabels.label_not_specified' | translate }}
                                  </option>
                                  <option *ngFor="let val of field.values" [ngValue]="val.id">
                                    {{ val.value }}
                                  </option>
                                </select>
                                <input
                                  *ngSwitchCase="'INPUT'"
                                  class="minimal-select"
                                  type="text"
                                  [formControlName]="field.field_id"
                                />
                                <input
                                  *ngSwitchCase="'DATE'"
                                  class="minimal-select"
                                  type="date"
                                  [formControlName]="field.field_id"
                                />
                              </ng-container>
                            </div>
                          </div>
                          <div class="fields-buttons">
                            <button
                              *ngIf="!edit[item.id]"
                              type="button"
                              class="btn btn-secondary btn-cell font-xl"
                              (click)="edit[item.id] = true"
                            >
                              <i class="fa fa-pencil"></i>
                            </button>
                            <ng-container *ngIf="edit[item.id]">
                              <button
                                type="button"
                                class="btn btn-success font-xl"
                                (click)="saveEdition(item)"
                              >
                                <i class="fa fa-save"></i>
                              </button>
                              <button
                                type="button"
                                class="btn btn-danger font-xl"
                                (click)="cancelEdition(item.id)"
                              >
                                <i class="fa fa-close"></i>
                              </button>
                            </ng-container>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </ng-container>
                </tbody>
              </table>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-6"></div>
            <div class="col-lg-6">
              <div *ngIf="order.voucher || order.discounts?.length" class="mb-1">
                <strong>
                  {{ 'pages.order_details.use_voucher' | translate }}
                </strong>

                <ul>
                  <li *ngIf="order.voucher">{{ order.voucher }}</li>
                  <li *ngFor="let discount of order.discounts">{{ discount.sourceCode }}</li>
                </ul>
              </div>

              <table class="table mb-0 table-bordered">
                <caption class="tw-sr-only">
                  {{ 'pages.order_details.order_footer' | translate }}
                </caption>

                <thead class="tw-sr-only">
                  <tr>
                    <th scope="col">{{ 'pages.order_details.order_footer_label' | translate }}</th>
                    <th scope="col">{{ 'pages.order_details.order_footer_value' | translate }}</th>
                  </tr>
                </thead>

                <tbody>
                  <tr class="table-active">
                    <td>
                      <strong>{{ 'pages.order_details.tax_free_total' | translate }} :</strong>
                    </td>
                    <td>
                      {{
                        order.total_base
                          | currency : curr : 'symbol-narrow' : '1.2-2' : this.translate.currentLang
                      }}
                    </td>
                  </tr>

                  <ng-container *ngFor="let v of entries(vats)">
                    <tr class="table-active" *ngIf="v[0] > 0">
                      <td>
                        <strong>{{ 'pages.order_details.vat' | translate }} ({{ v[0] }}%)</strong>
                      </td>
                      <td>
                        {{
                          v[1]
                            | currency
                              : curr
                              : 'symbol-narrow'
                              : '1.2-2'
                              : this.translate.currentLang
                        }}
                      </td>
                    </tr>
                  </ng-container>

                  <tr *ngIf="!zeroVat" class="table-active">
                    <td>
                      <strong>{{ 'pages.order_details.total' | translate }} :</strong>
                    </td>
                    <td>
                      {{
                        order.total_vat
                          | currency : curr : 'symbol-narrow' : '1.2-2' : this.translate.currentLang
                      }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div class="card-footer d-flex">
          <div class="tw-flex tw-flex-1 tw-flex-col tw-items-center tw-gap-4 md:tw-flex-row">
            <button
              *ngIf="order.status === 'COMPLETED'  && authService.isSuperAdmin"
              type="button"
              class="btn btn-secondary"
              [disabled]="isInvoiceDownloading"
              mat-button 
              [matMenuTriggerFor]="printOrderMenu"
            >
              <span *ngIf="isInvoiceDownloading; else notDownloading">
                Téléchargement en cours...
              </span>
              <ng-template #notDownloading>
                <span>{{ 'pages.order_details.download_invoice' | translate }}</span>
              </ng-template>
            </button>
            
            <mat-menu 
              #printOrderMenu="matMenu"
              class="tw-max-w-md"
            >
              <button mat-menu-item (click)="printOrder({ legacy: true, version: 'V1' })">{{ 'pages.order_details.download_v1' | translate }}</button>
              <button mat-menu-item (click)="printOrder({ legacy: false, version: 'V2' })">{{ 'pages.order_details.download_v2' | translate }}</button>
              <button mat-menu-item (click)="printOrder({ legacy: false, version: 'V3' })">{{ 'pages.order_details.download_v3' | translate }}</button>
            </mat-menu>
            
            <button
              *ngIf="order.status === 'COMPLETED'  && !authService.isSuperAdmin"
              type="button"
              class="btn btn-secondary"
              [disabled]="isInvoiceDownloading"
              (click)="printOrder({ legacy: false, version: 'V3' })"
            >
              <span *ngIf="isInvoiceDownloading; else notDownloading">
                {{ 'otherslabels.notif_download_progress' | translate }}...
              </span>
              <ng-template #notDownloading>
                <span>{{ 'pages.order_details.download_invoice' | translate }}</span>
              </ng-template>
            </button>

            <button
              *ngIf="order.status === 'COMPLETED'"
              [hasOnePermission]="['DOWNLOAD_LEGACY_INVOICES']"
              type="button"
              class="btn btn-secondary"
              [disabled]="isInvoiceDownloading"
              (click)="printOrder({ legacy: true })"
            >
              <span *ngIf="isInvoiceDownloading; else notDownloading">
                {{ 'otherslabels.notif_download_progress' | translate }}...
              </span>
              <ng-template #notDownloading>
                <span>{{ 'pages.order_details.old_invoice' | translate }}</span>
              </ng-template>
            </button>

            <div *ngIf="hasClickAndCollectItems || hasHomeDeliveryItems">
              <select
                (change)="handleOrderAction($event)"
                style="width: 175px"
                class="tw-border tw-px-3 tw-py-2"
              >
                <option value="NOOP">{{ 'pages.order_details.cc_noop' | translate }}</option>
                <option value="SHIPPING" *ngIf="hasHomeDeliveryItems">
                  {{ 'pages.order_details.cc_shipping' | translate }}
                </option>
                <option value="COLLECT" *ngIf="hasClickAndCollectItems">
                  {{ 'pages.order_details.cc_collect' | translate }}
                </option>
              </select>
              <tu-tooltip>
                {{ 'pages.order_details.cc_tooltip' | translate }}
              </tu-tooltip>
            </div>
          </div>

          <h6 class="mx-1 mb-0">
            {{ 'pages.order_details.payment' | translate }} {{ order.installments }}
            {{ 'pages.order.times' | translate }}
          </h6>

          <h6 class="mx-1 mb-0" *ngIf="order.mandate_id">
            {{ 'pages.order_details.rum' | translate }} : {{ order.mandate_id }}
          </h6>

          <strong *ngIf="+order.item_subscription_count > 0" class="h-6 mb-0">
            {{ 'pages.order_details.tacitly_renewable' | translate }}
          </strong>
        </div>
      </div>
    </div>

    <div class="col-sm-12 col-md-6" *ngIf="order.shipping_address">
      <div class="card">
        <div class="card-header">
          {{ 'pages.order_details.shipping_address' | translate }}
        </div>
        <div class="card-block">
          <p class="mb-0 card-text font-weight-bold tw-whitespace-pre-line">
            {{
              order.shipping_address?.streetNumber
                ? order.shipping_address?.streetNumber + ' ' + order.shipping_address?.route
                : order.shipping_address?.route
            }}
          </p>
          <p class="card-text font-weight-bold">
            {{ order.shipping_address?.zipCode }} {{ order.shipping_address?.city }}
          </p>
          <p class="card-text text-uppercase">{{ order.shipping_address?.country }}</p>
        </div>
      </div>
    </div>

    <div class="col-sm-12 col-md-6" *ngIf="order.inst_schedule?.length">
      <div class="card">
        <div class="card-header">
          {{ 'pages.order_details.payment_plan_title' | translate }}
        </div>
        <div class="card-block table-responsive">
          <table class="table mb-0 table-bordered">
            <caption class="tw-sr-only">
              {{ 'pages.order_details.payment_plan_title' | translate }}
            </caption>

            <thead>
              <tr>
                <th scope="col">{{ 'pages.order_details.payment_plan' | translate }}</th>
                <th scope="col">{{ 'pages.order_details.amount' | translate }}</th>
                <!-- WARNING: This is disabled because Payzen never updates this status which is misleading -->
                <!-- You should re-enable this whemever the synchronization is working again -->
                <!-- <th scope="col">{{ 'pages.order_details.status' | translate }}</th> -->
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let inst of order.inst_schedule">
                <th scope="row">
                  {{ inst.date | date : 'shortDate' : undefined : translate.currentLang }}
                </th>
                <td>{{ inst.amount | currency : curr : 'symbol-narrow' : '1.2-2' }}</td>
                <!-- WARNING: See "warning" above for the reason of this few lines commented -->
                <!-- <td [ngSwitch]="inst.status">
                  <span *ngSwitchCase="'PENDING'" class="tag tag-default tag-reset">
                    {{ 'pages.order_details.inst_pending' | translate }}
                  </span>
                  <span *ngSwitchCase="'PROCESSING'" class="tag tag-warning tag-reset">
                    {{ 'pages.order_details.inst_processing' | translate }}
                  </span>
                  <span *ngSwitchCase="'DONE'" class="tag tag-success tag-reset">
                    {{ 'pages.order_details.inst_done' | translate }}
                  </span>
                  <span *ngSwitchCase="'ERROR'" class="tag tag-danger tag-reset">
                    {{ 'pages.order_details.inst_error' | translate }}
                  </span>
                  <span *ngSwitchCase="'REGULARIZATION'" class="tag tag-reg tag-reset">
                    {{ 'pages.order.regularization' | translate }}
                  </span>
                </td> -->
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
  
  <tu-comments-ng
    [comments]="comments"
    [commentTemplate]="commentTemplateNg"
    [postComment]="postComment"
    [editComment]="editComment"
    [deleteComment]="deleteComment"
  ></tu-comments-ng>
  
  <ng-template
    #commentTemplateNg
    let-comment="comment"
    let-commentToEdit="commentToEdit"
    let-commentToDelete="commentToDelete"
    let-editedComment="editedComment"
    let-markdownToHTML="markdownToHTML"
  >
    <tu-order-comment
      [comment]="comment"
      [commentToEdit]="commentToEdit"
      [commentToDelete]="commentToDelete"
      [editedComment]="editedComment"
      [markdownToHTML]="markdownToHTML"
    ></tu-order-comment>
  </ng-template>
</div>

<simple-notifications [options]="notificationOptions"></simple-notifications>
