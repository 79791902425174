<div
  class="tw-bg-gray-50 tw-p-4 tw-flex tw-flex-col tw-gap-1"
>
  <div class="tw-flex tw-items-center tw-justify-between">
    <div>
      <time class="text-muted">
        {{ comment.created_at | date : 'shortDate' : undefined : translate.currentLang }}
        {{ comment.created_at | date : 'shortTime' : undefined : translate.currentLang }}
      </time>
      <em *ngIf="editedComment === comment.id" class="text-muted tw-ml-2">En cours d'édition</em>
    </div>
    <div
      *ngIf="comment.userId === auth.user_id && comment.id; else differentUser"
      class="tw-flex tw-space-x-2"
    >
      <button
        type="button"
        (click)="commentToEdit(comment.id, comment.text)"
      >
        <span class="sr-only">{{ 'pages.customer_details.edit_comment' | translate }}</span>
        <tu-hero-icon [path]="heroicons.outline.pencilAlt" klass="tw-w-6"></tu-hero-icon>
      </button>
      <button
        type="button"
        (click)="deleteComment(comment.id)"
      >
        <span class="sr-only">
          {{ 'pages.customer_details.delete_comment' | translate }}
        </span>
        <tu-hero-icon [path]="heroicons.outline.trash" klass="tw-w-6 tw-text-red-500"></tu-hero-icon>
      </button>
    </div>

    <ng-template #differentUser>
      <div *ngIf="comment.id" class="tw-flex tw-space-x-2">
        <button
          [hasAllPermission]="['EDIT_ALL_COMMENTS']"
          type="button"
          (click)="commentToEdit(comment.id, comment.text)"
        >
          <span class="sr-only">
            {{ 'pages.customer_details.edit_comment' | translate }}
          </span>
          <tu-hero-icon [path]="heroicons.outline.pencilAlt" klass="tw-w-6"></tu-hero-icon>
        </button>
        <button
          [hasAllPermission]="['EDIT_ALL_COMMENTS']"
          type="button"
          (click)="commentToDelete(comment.id)"
        >
          <span class="sr-only">
            {{ 'pages.customer_details.delete_comment' | translate }}
          </span>
          <tu-hero-icon [path]="heroicons.outline.trash" klass="tw-w-6 tw-text-red-500"></tu-hero-icon>
        </button>
      </div>
    </ng-template>
  </div>

  <div class="tw-mt-2" [innerHTML]="markdownToHTML(comment.text)"></div>
</div>