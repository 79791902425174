import { Component, Inject } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export interface DeleteTicketModalResponse {
  delete: boolean;
  deleteLinkedSubscription?: boolean;
}

@Component({
  selector: 'tu-delete-ticket-modal',
  templateUrl: './delete-ticket-modal.component.html',
  styleUrls: ['./delete-ticket-modal.component.scss'],
})
export class DeleteTicketModalComponent {
  constructor(
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<DeleteTicketModalComponent, DeleteTicketModalResponse>,
    @Inject(MAT_DIALOG_DATA) public data: { label: string; showSubscriptionCheckbox: boolean }
  ) {}

  public form = this.fb.group({
    deleteLinkedSubscription: [false],
  });

  public close(): void {
    this.dialogRef.close({
      delete: false,
    });
  }

  public deleteTicket(): void {
    const deleteLinkedSubscription = this.form.value.deleteLinkedSubscription;

    this.dialogRef.close({
      delete: true,
      deleteLinkedSubscription,
    });
  }
}
