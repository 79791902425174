import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PaperworkService } from '@app/modules/customers/services/paperwork.service';
import { NetworkService } from '@app/modules/network/services/network.service';
import { IDocument } from '@app/modules/orders/models/document';
import { Network } from '@app/modules/shared/models/network';
import { AuthService } from '@app/modules/shared/services/auth.service';
import { TranslateService } from '@ngx-translate/core';
import { NotificationsService } from 'angular2-notifications';
import { debounceTime, distinctUntilChanged, firstValueFrom } from 'rxjs';

interface DialogData {
  customerId: number;
}

@Component({
  selector: 'tu-document-upload-modal',
  templateUrl: './document-upload-modal.component.html',
  styleUrls: ['./document-upload-modal.component.scss'],
})
export class DocumentUploadModalComponent implements OnInit {
  public networkNameCtrl = new FormControl();
  public filteredNetworkList: Network[] = [];

  public documentTypeNameCtrl = new FormControl();
  public documentTypeLoader: boolean = false;
  private allDocTypeList: IDocument[] = [];
  public filteredDocTypeList: IDocument[] = [];

  public loader: boolean = false;

  public notificationOptions = {
    timeOut: 3000,
    showProgressBar: true,
    pauseOnHover: false,
    clickToClose: false,
  };

  public form = this.fb.group({
    networkId: [null, Validators.required],
    typeId: [null, Validators.required],
    document: [null, Validators.required],
  });

  constructor(
    private modalRef: MatDialogRef<DocumentUploadModalComponent>,
    private fb: FormBuilder,
    private authService: AuthService,
    private networkService: NetworkService,
    private paperworkService: PaperworkService,
    private notification: NotificationsService,
    private translate: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {}

  get hasSelectNetwork(): boolean {
    return Boolean(this.form.controls.networkId.value);
  }

  get networkHasNoDocumentType(): boolean {
    return !this.documentTypeLoader && !this.documentTypeNameCtrl.disabled && this.filteredDocTypeList.length === 0
  }
  
  public ngOnInit(): void {
    this.documentTypeNameCtrl.disable();

    // If user has only one network, we select it
    if (this.authService.networks.length === 1) {
      this.networkNameCtrl.setValue(this.authService.networks[0].name);
      this.form.controls.networkId.setValue(this.authService.networks[0].id);
      this.networkNameCtrl.disable();
      this.retrieveDocumentTypeList(+this.authService.networks[0].id);
    }

    this.networkNameCtrl.valueChanges
      .pipe(debounceTime(200), distinctUntilChanged())
      .subscribe((name) => {
        if (name) {
          this.filteredNetworkList = this.networksFilter(name);

          const network = this.authService.networks.find(
            ({ name: networkName }) => networkName === name
          );

          if (network) {
            this.form.controls.networkId.setValue(network.id);

            this.retrieveDocumentTypeList(+network.id);
          }
        } else {
          this.filteredNetworkList = this.authService.networks;
        }
      });

    this.documentTypeNameCtrl.valueChanges
      .pipe(debounceTime(200), distinctUntilChanged())
      .subscribe((doc: IDocument | string | null) => {
        if (typeof doc === 'string') {
          this.filteredDocTypeList = this.docTypesFilter(doc);
        } else if (doc) {
          this.form.controls.typeId.setValue(doc.id);
        }
      });
  }

  private networksFilter(value: string): Network[] {
    const filterValue = value.toLowerCase();

    return this.authService.networks.filter((network) =>
      network.name.toLowerCase().includes(filterValue)
    );
  }

  private docTypesFilter(value: string): IDocument[] {
    const filterValue = value.toLowerCase();

    return this.allDocTypeList.filter((docType) =>
      docType.name.toLowerCase().includes(filterValue)
    );
  }

  public async submit(): Promise<void> {
    const { typeId, document } = this.form.value;

    try {
      this.loader = true;

      await this.paperworkService.createDocument(this.data.customerId, typeId, document, 'VALID');

      this.notification.success(this.translate.instant('pages.document_upload_modal.success'));
      this.modalRef.close({ success: true });
    } catch {
      this.notification.error(this.translate.instant('otherslabels.generic_error'));
    } finally {
      this.loader = false;
    }
  }

  public displayDocumentType(documentType: IDocument | string | null): string {
    if (typeof documentType === 'string') {
      return documentType;
    } else if (documentType) {
      return documentType.name;
    }
  }

  public onFileSelected(event: Event): void {
    const target = event.target as HTMLInputElement;

    if (target.files.length > 0) {
      const file = target.files[0];
      this.form.controls.document.setValue(file);
    } else {
      this.form.controls.document.setValue(null);
    }
  }

  public async retrieveDocumentTypeList(networkId: number): Promise<void> {
    try {
      this.documentTypeLoader = true;

      const docTypeList = await firstValueFrom(this.networkService.getDocuments(networkId));

      this.allDocTypeList = docTypeList;
      this.filteredDocTypeList = docTypeList;
      this.documentTypeNameCtrl.enable();
    } catch {
      this.notification.error(this.translate.instant('otherslabels.generic_error'));
    } finally {
      this.documentTypeLoader = false;
    }
  }

  public closeModal() {
    this.modalRef.close({ success: false });
  }
}
