import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { Observable, firstValueFrom, of, throwError } from 'rxjs';

import { Categorie } from '../models/categorie';
import { ProductType } from '../models/product-type';
import { environment as env } from '@env/environment';
import { CacheManager } from '@app/utils/cache-manager';
import { Blueprint, BlueprintDetails } from '@app/modules/shop/models/blueprint/blueprint';
import { FilterService } from '@app/modules/shared/services/filter.service';
import { GenericResponse } from '@app/modules/shared/models/generic-response';

import { BlueprintRules, BlueprintSettings } from '../models/blueprint/blueprint';
import { PeriodType, PivotType, ProductItem, ProductItemFilters } from '../models/product-item';
import { PreviewName } from '../models/preview-name';
import { ImageType } from '../models/image';


interface FileUpload {
  name: string;
  path: string;
  url: string;
}

@Injectable()
export class ShopService {
  private cache = new CacheManager('shop');

  constructor(private http: HttpClient, private filters: FilterService) {}

  public getCategories(params: Partial<{ network_id: string; parent_id?: number }> = {}) {
    return this.http
      .get<GenericResponse<{ categories: Categorie[] }>>(
        env.config.feedRoot + `Shop/getCategories`,
        {
          params: {
            ...params,
            v: this.cache.getVersion(),
          },
        }
      )
      .pipe(
        map(({ response }) => {
          return response.categories;
        })
      );
  }

  public getCategorieDetails(id: number) {
    return this.http
      .get<GenericResponse<{ categories: Categorie[] }>>(
        env.config.feedRoot + `Shop/getCategories`,
        {
          params: {
            id,
            v: this.cache.getVersion(),
          },
        }
      )
      .pipe(
        map(({ response }) => {
          return response.categories[0];
        })
      );
  }

  public saveCategorie(categorie: Categorie) {
    const { cname, network_id, cid, active, translations, parent_id, index } = categorie;

    return this.http
      .post<GenericResponse<{ categorie: Categorie }>>(env.config.feedRoot + `Shop/saveCategorie`, {
        cname,
        network_id,
        cid,
        active,
        translations,
        parent_id,
        index,
      })
      .pipe(
        map(({ response }) => {
          this.cache.incrementVersion();
          return response.categorie as Categorie;
        })
      );
  }

  public saveProductType(productType: ProductType): any {
    return this.http.post<any>(env.config.feedRoot + `Shop/saveProductType`, productType).pipe(
      map(({ response: r }) => {
        this.cache.incrementVersion();
        return r as { product_id: string };
      })
    );
  }

  public getProductsType(params: Partial<{ id: string; network_id: string }> = {}) {
    let version = this.cache.getVersion();
    const url = params.id ? `Shop/productTypeDetails` : 'Shop/getProductsType';

    return of([null]).pipe(
      switchMap(() =>
        this.http.get<any>(env.config.feedRoot + url, {
          params: {
            ...params,
            v: version,
          },
        })
      ),

      map(({ response }) => {
        return params.id ? (response.productType as ProductType) : response.productsType;
      })
    );
  }

  public getBlueprint(params: Partial<{ id: string }> = {}) {
    return firstValueFrom(
      this.http
        .get<GenericResponse>(env.config.feedRoot + `Wallet/blueprints`, {
          params: {
            id: `["${params.id}"]`,
          },
        })
        .pipe(
          catchError(() => {
            console.error('Erreur sur le flux Wallet/blueprints');
            return of({
              status: 'ko',
              response: {
                errorMessage: 'Erreur sur le flux Wallet/blueprints',
                errorCode: 'BLUEPRINT_FLUX_ERROR',
              },
            });
          }),
          map(({ response }) => {
            if (!Array.isArray(response)) return response;
            if (response.length === 0) {
              return {
                errorMessage: `Aucun blueprint récupéré avec l'identifiant ${params.id}`,
                errorCode: 'BLUEPRINT_FLUX_EMPTY',
              };
            }
            return { blueprint: response[0] as Blueprint };
          })
        )
    );
  }

  public getBlueprints(params: Partial<{ ids: string[]; networks: number[] }> = {}) {
    return of([null]).pipe(
      switchMap(() =>
        this.http.get<any>(`${env.config.feedRoot}Wallet/blueprints`, {
          params: {
            id: params?.ids ? `[${params.ids.join(',')}]` : null,
            network: params?.networks ? `[${params?.networks.join(',')}]` : null,
          },
        })
      ),
      map(({ response }) => {
        return response ? (response as Blueprint[]) : null;
      })
    );
  }

  public saveBlueprint(blueprint: Blueprint): any {
    return firstValueFrom(
      this.http
        .post<any>(`${env.config.feedRoot}Wallet/createBlueprint`, {
          ...blueprint,
        })
        .pipe(
          catchError((error) => {
            return of(this._blueprintErrorsCatch(error));
          }),
          map(({ response }) => {
            return response;
          })
        )
    );
  }

  public updateBlueprintInfo(
    id: string,
    params: {
      networkId?: string;
      name?: string;
      description?: string;
      comment?: string;
      offlineEnabled?: boolean;
      transferEnabled?: boolean;
      attestationProviderId?: string;
      externalCode?: string;
      maxOwned?: number | string;
    }
  ) {
    return firstValueFrom(
      this.http
        .put<any>(`${env.config.feedRoot}Wallet/updateBlueprintInfo`, {
          id,
          ...params,
        })
        .pipe(
          catchError((error) => {
            return of(this._blueprintErrorsCatch(error));
          }),
          map(({ response }) => {
            return response;
          })
        )
    );
  }

  public updateBlueprintConfig(
    id: string,
    config: {
      settings?: BlueprintSettings;
      rules?: BlueprintRules[];
    }
  ) {
    return firstValueFrom(
      this.http
        .put<any>(`${env.config.feedRoot}Wallet/updateBlueprintConfig`, {
          id,
          config,
        })
        .pipe(
          catchError((error) => {
            return of(this._blueprintErrorsCatch(error));
          }),
          map(({ response }) => {
            return response;
          })
        )
    );
  }

  public getBlueprintDetails(blueprintId: string) {
    return firstValueFrom(
      this.http
        .get<GenericResponse<{ blueprintDetails: BlueprintDetails }>>(
          `${env.config.feedRoot}Wallet/blueprintDetails`,
          {
            params: {
              blueprintId,
            },
          }
        )
        .pipe(
          catchError((error) => {
            return of({
              response: {
                blueprintDetails: null,
                errorCode: error.error.code,
                errorMessage: error.error.message,
              },
            });
          }),
          map(({ response }) => {
            return response || null;
          })
        )
    );
  }

  public deleteBlueprint(blueprintId: string): any {
    return this.http
      .delete<any>(`${env.config.feedRoot}Wallet/deleteBlueprint`, {
        params: {
          blueprintId,
        },
      })
      .pipe(
        map(({ response: r }) => {
          return r.success as { success: boolean };
        })
      );
  }

  public getProductsItem(params?: ProductItemFilters): Observable<ProductItem | ProductItem[]> {
    const version = this.cache.getVersion();

    return this.http
      .get<GenericResponse>(`${env.config.feedRoot}Shop/getProductsItem`, {
        params: {
          version,
          ...params,
        },
      })
      .pipe(
        map(({ response }) => {
          if (params?.id) {
            return response.productsItem[0];
          }

          return response.productsItem;
        }),
        catchError((err) => {
          console.error(err);
          return of(null);
        }),
        tap((res) => {
          if (!res) {
            console.warn('No response');
          }
        })
      );
  }

  public getNetworkProductsItem(nid?: string): any {
    let version = this.cache.getVersion();
    this.filters.network = nid;

    return of([null]).pipe(
      switchMap(() =>
        this.http.get<any>(env.config.feedRoot + `Shop/getProductsItem?v=` + version, {
          params: this.filters.filtersWithID,
        })
      ),

      map(({ response: r }) => {
        return r.productsItem as ProductItem[];
      }),
      catchError(() => throwError('Erreur serveur !'))
    );
  }

  public saveProduct(product: Partial<ProductItem>) {
    return this.http
      .post<GenericResponse<ProductItem>>(env.config.feedRoot + `Shop/saveProduct`, {
        ...product,
        inst: product.installments,
      })
      .pipe(
        map(({ response }) => {
          this.cache.incrementVersion();
          return response;
        })
      );
  }

  public uploadImage(table: string, id: string, file: File, column: string) {
    let formData: FormData = new FormData();
    formData.append('table', table);
    formData.append('column', column);
    formData.append('media', file);
    formData.append('id', id);

    let headers = new Headers();
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');

    return this.http.post<any>(env.config.feedRoot + `Shop/UploadImage`, formData).pipe(
      map(({ response: r }) => {
        if (r.success) this.cache.incrementVersion();
        return r;
      })
    );
  }

  public getOrdering(nid: number) {
    return of([null]).pipe(
      switchMap(() =>
        this.http.get<any>(env.config.feedRoot + `Shop/ordering?networkId=${nid}`, {})
      ),

      map(({ response: r }) => {
        return r;
      })
    );
  }

  public saveOrdering(cats: any, citems: any): any {
    return this.http.post<any>(env.config.feedRoot + `Shop/saveOrdering`, { cats, citems }).pipe(
      map(({ response: r }) => {
        return r;
      })
    );
  }

  public getFamilyDiscount(nid: number) {
    return of([null]).pipe(
      switchMap(() =>
        this.http.get<any>(env.config.feedRoot + `Shop/getFamilyDiscount?network_id=${nid}`, {})
      ),

      map(({ response: r }) => {
        return r.famillyDiscount;
      })
    );
  }

  public updateFamilyDiscount(network_id: number, promos: any): any {
    return this.http
      .post<any>(env.config.feedRoot + `Shop/updateFamilyDiscount`, { network_id, promos })
      .pipe(
        map(({ response: r }) => {
          return r;
        })
      );
  }

  public uploadAsset(file: File, path?: string): Observable<FileUpload> {
    let formData: FormData = new FormData();
    formData.append('file', file);
    formData.append('path', path);

    return this.http
      .post<GenericResponse<FileUpload>>(env.config.feedRoot + `Assets/upload`, formData)
      .pipe(map(({ response }) => response));
  }

  public getProductStartDates(pivotType: PivotType, pivotValue: number, startProposals: number) {
    return firstValueFrom(
      this.http
        .get<GenericResponse<{ startDates: string[] }>>(
          `${env.config.feedRoot}Product/getProductStartDates`,
          {
            params: {
              pivot_type: pivotType,
              pivot_value: pivotValue,
              start_proposals: startProposals,
              timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            },
          }
        )
        .pipe(
          map(({ response }) => {
            return response;
          })
        )
    );
  }

  public getProductPreviewName(
    name: string,
    locale: string,
    data?: {
      startDate?: string;
      endDate?: string;
      pivotType?: PivotType;
      pivotValue?: number;
      startProposals?: number;
      periodType?: PeriodType;
      periodValue?: number;
    }
  ) {
    // remove empty params
    const filtered = Object.entries(data).filter(
      ([_, value]) => !['', 'null'].includes(`${value}`)
    );
    const dataFiltered = Object.fromEntries(filtered);

    // startProposals param is not compatible with startDate and endDate
    if (dataFiltered.startDate && dataFiltered.endDate) {
      delete dataFiltered.startProposals;
    }

    return firstValueFrom(
      this.http
        .get<GenericResponse<{ namePreviews: PreviewName[] }>>(
          `${env.config.feedRoot}Product/previewNames`,
          {
            params: {
              name,
              locale,
              ...dataFiltered,
            },
          }
        )
        .pipe(
          map(({ response }) => {
            return response.namePreviews;
          })
        )
    );
  }

  private _blueprintErrorsCatch(error: { error: { code?: string; message?: string; info?: any } }) {
    console.error('Erreur lors de la sauvegarde / mise à jour du blueprint', error);
    switch (error?.error?.code) {
      case 'UPDATE_BLUEPRINT_INFO_NETWORK_NOT_TRANSFERABLE':
        return {
          response: {
            error: {
              code: error.error.code,
              message: `La configuration du réseau n'autorise pas le transfert depuis le portefeuille`,
              issue:
                error.error?.info?.issues[0]?.message ||
                'UPDATE_BLUEPRINT_INFO_NETWORK_NOT_TRANSFERABLE',
            },
          },
        };
      case 'WALLET_BLUEPRINT_UPDATE_ERROR':
        return {
          response: {
            error: {
              code: error.error.code,
              message: `Une des images ne corresponds pas aux limites`,
              issue: error.error.message || 'WALLET_BLUEPRINT_UPDATE_ERROR',
            },
          },
        };
      case 'WALLET_BLUEPRINT_CONFIG_INVALID':
        return {
          response: {
            error: {
              code: error.error.code,
              message: `La configuration du blueprint transmise ne correspond pas à l'algorithme du blueprint`,
              issue: error.error?.info?.issues[0]?.message || 'WALLET_BLUEPRINT_CONFIG_INVALID',
            },
          },
        };
      case 'WALLET_BLUEPRINTS_CREATE_ERROR':
        return {
          response: {
            error: {
              code: error.error.code,
              message: `Erreur lors de la création du blueprint`,
              issue: error.error?.message || 'WALLET_BLUEPRINTS_CREATE_ERROR',
            },
          },
        };
      default:
        return {
          response: {
            error: {
              code: 'BLUEPRINT_UNKNOW_ERROR',
              message: `Erreur non gérée, veuillez contacter le service technique`,
              issue: error?.error?.message ? error?.error?.message : 'BLUEPRINT_UNKNOW_ERROR',
            },
          },
        };
    }
  }

  public updateItemImage(
    itemId: string,
    imageType: ImageType,
    file: File
  ): Promise<{ success: true }> {
    let formData: FormData = new FormData();
    formData.append('itemId', itemId);
    formData.append('imageType', imageType);
    formData.append('file', file);

    return firstValueFrom(
      this.http
        .post<GenericResponse<{ success: true }>>(
          env.config.feedRoot + `Shop/updateItemImage`,
          formData
        )
        .pipe(map(({ response }) => response))
    );
  }

  public deleteItemImage(itemId: string, imageType: ImageType): Promise<{ success: true }> {
    return firstValueFrom(
      this.http
        .delete<GenericResponse<{ success: true }>>(env.config.feedRoot + `Shop/deleteItemImage`, {
          params: {
            itemId,
            imageType,
          },
        })
        .pipe(map(({ response }) => response))
    );
  }

  public updateBlueprintImage(
    blueprintId: string,
    imageType: ImageType,
    file: File
  ): Promise<{ success: true; path: string; url: string }> {
    let formData: FormData = new FormData();
    formData.append('blueprintId', blueprintId);
    formData.append('imageType', imageType);
    formData.append('file', file);

    return firstValueFrom(
      this.http
        .post<GenericResponse<{ success: true; path: string; url: string }>>(
          env.config.feedRoot + `Wallet/updateBlueprintImage`,
          formData
        )
        .pipe(map(({ response }) => response))
    );
  }

  public deleteBlueprintImage(
    blueprintId: string,
    imageType: ImageType
  ): Promise<{ success: true }> {
    return firstValueFrom(
      this.http
        .delete<GenericResponse>(env.config.feedRoot + `Wallet/deleteBlueprintImage`, {
          params: {
            blueprintId,
            imageType,
          },
        })
        .pipe(map(({ response }) => response))
    );
  }
}
