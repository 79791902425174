import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'currency2' })
export class CurrencyPipe implements PipeTransform {
  transform(
    value: number,
    locale: string | undefined = 'fr-FR',
    currency: string | undefined = 'EUR'
  ) {
    return parseFloat(value.toString()).toLocaleString(locale, {
      style: 'currency',
      currency,
    });
  }
}
