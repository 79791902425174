import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'key' })
export class KeyPipe implements PipeTransform {
  transform<T>(content: T[], key: string, value: any): T[] {
    if (!content || typeof value === 'undefined' || value === null) return content;
    if (!content.length || !(key in content[0])) return content; // if key doesn't appear in content

    return content.filter((data) => {
      const contentVal = key.split('.').reduce((a, c) => a[c], data);
      if (Array.isArray(contentVal)) return contentVal.some((v) => v == value);
      else return value == contentVal;
    });
  }
}
