import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';
import { Customer } from '@app/modules/customers/models/customer';
import { CustomersService } from '@app/modules/customers/services/customers.service';
import { ProductsService } from '@app/modules/customers/services/products.service';
import { TicketService } from '@app/modules/customers/services/ticket.service';
import { NetworkInfo } from '@app/modules/network/models/network-info';
import { NetworkService } from '@app/modules/network/services/network.service';
import { Network } from '@app/modules/shared/models/network';
import { TicketPurposeLabel } from '@app/modules/shared/models/ticket';
import { AuthService } from '@app/modules/shared/services/auth.service';
import { TranslateService } from '@ngx-translate/core';
import { NotificationsService } from 'angular2-notifications';
import { isMatch as dateIsMatch } from 'date-fns';
import { debounceTime, distinctUntilChanged, map } from 'rxjs';
import { $$Ticket } from '../../../services/customers.service';
import { Blueprint } from '@app/modules/shop/models/blueprint/blueprint';

interface CustomerNetwork {
  network: Network;
  blueprints: Blueprint[];
}

export interface DialogData {
  customer: Customer;
  customerNetworks: CustomerNetwork[];
  version: 'v1' | 'v2';
  transfert: {
    ticket: $$Ticket;
    network_name: string;
  };
}
@Component({
  selector: 'tu-ticket-generation-modal',
  templateUrl: './ticket-generation-modal.component.html',
  styleUrls: ['./ticket-generation-modal.component.scss'],
  providers: [CustomersService],
})
export class TicketGenerationModalComponent implements OnInit {
  constructor(
    public modalRef: MatDialogRef<TicketGenerationModalComponent>,
    public modal: MatDialog,
    public translate: TranslateService,
    private ticketService: TicketService,
    private authService: AuthService,
    private customersService: CustomersService,
    private productsService: ProductsService,
    private notification: NotificationsService,
    private fb: FormBuilder,
    private networkService: NetworkService,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {}

  //Component Outpouts
  @Output() updateField = new EventEmitter();
  @Output() desactivateTicketEvent = new EventEmitter<$$Ticket>();

  //General list
  private networksProducts = [];
  public customerNetworks: CustomerNetwork[] = [];
  public blueprints: Blueprint[] = [];

  //Step 1
  public productFormGroup: FormGroup;
  public currentNetwork: NetworkInfo;

  //Step 2
  public productInfoFormGroup: FormGroup;
  public originStopControl = new FormControl();
  public destinationStopControl = new FormControl();
  public loadingStops = false;
  public stops: { stop_id: string; stop_name: string }[] = [];
  public filteredOriginStops = [];
  public filteredDestinationStops = [];
  public isTripFound = false;
  public tripErrorMessage: string | null = null;
  public avatarErrorMessage: string | null = null;
  public desactivateTicketOption: 'WARN' | 'WAIT' | 'KO' | 'OK' = null;

  //Transfert
  public productPurposes = Object.entries(TicketPurposeLabel).filter(([index]) => {
    // COMMERCIAL_OFFER is deprecated, but we keep it becasue we need the label for old tickets
    return index !== 'COMMERCIAL_OFFER';
  });
  public generationReasonLabels = TicketPurposeLabel;

  //Ticket Generation
  public ticketGenerationInProcess = false;
  public notificationOptions = {
    timeOut: 3000,
    showProgressBar: true,
    pauseOnHover: false,
    clickToClose: false,
  };

  get startDate(): Date | null {
    const startValidityDate = this.productFormGroup?.value?.startValidityDate;
    const startValidityTime = this.productFormGroup?.value?.startValidityTime ?? '00:00';

    if (startValidityDate) {
      const startStringDate = `${startValidityDate} ${startValidityTime}`;

      if (dateIsMatch(startStringDate, 'yyyy-MM-dd HH:mm')) {
        return new Date(startStringDate);
      }
    }

    return null;
  }

  get endDate(): Date | null {
    const endValidityDate = this.productFormGroup?.value?.endValidityDate;
    const endValidityTime = this.productFormGroup?.value?.endValidityTime ?? '23:59';

    if (endValidityDate) {
      const endStringDate = `${endValidityDate} ${endValidityTime}`;

      if (dateIsMatch(endStringDate, 'yyyy-MM-dd HH:mm')) {
        return new Date(endStringDate);
      }
    }

    return null;
  }

  //Component initialisation
  ngOnInit() {
    this.customerNetworks = this.data.customerNetworks;
    //Step 1 form
    this.productFormGroup = this.fb.group({
      networkId: [null, Validators.required],
      generationReason: [null, Validators.required],
      quantity: [null, Validators.required],
      categoryId: [null, this.isTicketV1 ? Validators.required : null],
      productId: [null, this.isTicketV1 ? Validators.required : null],
      productTypeId: [null, this.isTicketV1 ? Validators.required : null],
      blueprint: [null, this.isTicketV2 ? Validators.required : null],
      startValidityDate: [null, this.data.transfert ? Validators.required : null],
      startValidityTime: [null, this.data.transfert ? Validators.required : null],
      endValidityDate: [null, this.data.transfert ? Validators.required : null],
      endValidityTime: [null, this.data.transfert ? Validators.required : null],
      generationComment: [null],
    });

    //Step 2 form
    this.productInfoFormGroup = this.fb.group({
      dematerialized: [null],
      origin: [null],
      destination: [null],
      trip: [null],
      avatar: [null],
    });

    //Network input changes bind
    this.productFormGroup
      .get('networkId')
      .valueChanges.pipe(debounceTime(200), distinctUntilChanged())
      .subscribe((networkId) => {
        if (this.isTicketV1 && !this.data.transfert) {
          const defaultCategory = this.categories.length === 1 ? this.categories[0].id : null;
          this.productFormGroup.get('categoryId').setValue(defaultCategory);
        }
        if (this.data.transfert) {
          this.currentNetwork = null;
          this.networkService.getNetworksDetails(networkId).subscribe((network) => {
            this.currentNetwork = network;
          });
        }
        if (this.isTicketV2) {
          this.blueprints =
            this.customerNetworks.find(
              (customerNetwork) => customerNetwork.network.id === networkId
            )?.blueprints || [];
        }
      });

    if (this.isTicketV1) {
      //Load Product catalog
      this.productsService
        .getProducts()
        .pipe(map((data: any[]) => data.sort((a, b) => a.name.localeCompare(b.name))))
        .subscribe((data) => {
          this.networksProducts = data;
          this.setDefaultNetwork();
        });

      //Category input changes bind
      this.productFormGroup
        .get('categoryId')
        .valueChanges.pipe(debounceTime(200), distinctUntilChanged())
        .subscribe(() => {
          const defaultProduct = this.products.length === 1 ? this.products[0].id : null;
          this.productFormGroup.get('productId').setValue(defaultProduct);
        });

      //Product input changes bind
      this.productFormGroup
        .get('productId')
        .valueChanges.pipe(debounceTime(200), distinctUntilChanged())
        .subscribe(() => {
          const defaultProductType =
            this.productTypes.length === 1 ? this.productTypes[0].id : null;
          this.productFormGroup.get('productTypeId').setValue(defaultProductType);
          //Step 2 inputs
          this.tripErrorMessage = null;
          this.avatarErrorMessage = null;
          this.isTripFound = false;
          this.productInfoFormGroup.get('dematerialized').removeValidators(Validators.required);
          this.originStopControl.removeValidators(Validators.required);
          this.destinationStopControl.removeValidators(Validators.required);
          this.productInfoFormGroup.get('origin').removeValidators(Validators.required);
          this.productInfoFormGroup.get('destination').removeValidators(Validators.required);
          this.productInfoFormGroup.get('trip').removeValidators(Validators.required);
          this.productInfoFormGroup.get('avatar').removeValidators(Validators.required);
          this.productInfoFormGroup.reset();
          this.originStopControl.reset();
          this.destinationStopControl.reset();

          if (this.product) {
            this.productInfoFormGroup.controls.dematerialized.setValue(
              this.product.supports.includes('PHYSICAL') ? '0' : '1'
            );
          }
        });

      //OriginStop input (step2) changes bind
      this.originStopControl.valueChanges
        .pipe(debounceTime(200), distinctUntilChanged())
        .subscribe((name) => {
          this.productInfoFormGroup.controls.origin.setValue(null);
          this.destinationStopControl.setValue(null);
          this.productInfoFormGroup.controls.destination.setValue(null);

          if (name) {
            this.filteredOriginStops = this.stopsFilter(name);

            const stop = this.stops.find((stop) => stop.stop_name === name);

            if (stop) {
              this.productInfoFormGroup.controls.origin.setValue(stop);
            }
          } else {
            this.filteredOriginStops = this.stops;
          }
        });
      //DestinationStop input (step2) changes bind
      this.destinationStopControl.valueChanges
        .pipe(debounceTime(200), distinctUntilChanged())
        .subscribe((name) => {
          if (name) {
            this.filteredDestinationStops = this.stopsFilter(name);

            const stop = this.stops.find((stop) => stop.stop_name === name);
            if (stop) this.productInfoFormGroup.controls.destination.setValue(stop);
            if (!stop) this.productInfoFormGroup.controls.destination.setValue(null);
          } else {
            this.productInfoFormGroup.controls.destination.setValue(null);
            this.filteredDestinationStops = this.stops;
          }
        });

      if (this.data?.transfert) {
        this.productFormGroup
          .get('networkId')
          .setValue(parseInt(this.data.transfert.ticket.network_id, 10));
        this.productFormGroup.get('categoryId').setValue(this.data.transfert.ticket.category_id);
      }
    }
    if (this.isTicketV2) {
      this.setDefaultNetwork();
    }

    if (this.data.transfert) {
      this.desactivateTicketOption = 'WARN';
    } else {
      //DELETE Device Change for ticket creation
      this.productPurposes = this.productPurposes.filter(
        (productPurpose) => productPurpose[0] !== 'DEVICE_CHANGE'
      );
    }
  }

  //Ticket generation
  public generateTicket() {
    const { productId, productTypeId, generationReason, quantity, blueprint, generationComment } =
      this.productFormGroup.value;

    this.ticketGenerationInProcess = true;
    //Ticket V1 GENERATION
    if (this.isTicketV1) {
      const {
        origin,
        destination,
        trip,
        avatar,
        dematerialized = 1,
      } = this.productInfoFormGroup.value;

      const odData = this.isProductOD
        ? {
            origin,
            destination,
            trip,
          }
        : null;

      const isTicketDematerialized =
        this.productType.dematerialized === null ? dematerialized : this.productType.dematerialized;

      this.ticketService
        .generateTicketsV1(
          this.data.customer.customer_id,
          productId,
          productTypeId,
          quantity,
          avatar,
          // HACK: This is a hack because in some obscure cases, the `isTicketDematerialized` is
          // valued to `null` which doesn't work for the feed.
          // We need to investigate why this variable isn't valued to '1' or '0'...
          isTicketDematerialized === null ? '1' : isTicketDematerialized,
          odData,
          this.startDate ? this.startDate.toISOString() : null,
          this.endDate ? this.endDate.toISOString() : null,
          generationReason,
          generationComment
        )
        .subscribe((response) => {
          this.ticketGenerationInProcess = false;
          if (response.length > 0) {
            this.updateFieldTrigger();
            this.notification.success(this.translate.instant(`otherslabels.notif_create_ok`));
            this.closeModal();
            return;
          }
          //ERROR ON GENERATION
          this.notification.error(this.translate.instant(`otherslabels.notif_create_ko`));
        });
      return;
    }

    //Ticket V2 GENERATION
    let contracts = [];
    for (let i = 0; i < quantity; i++) {
      this.ticketService
        .generateTicketsV2(
          generationReason,
          blueprint,
          this.data.customer.customer_id,
          this.network.id,
          this.startDate ? this.startDate.toISOString() : null,
          this.endDate ? this.endDate.toISOString() : null,
          generationComment
        )
        .subscribe((response) => {
          contracts.push(response);
          if (contracts.length === quantity) {
            this.ticketGenerationInProcess = false;
            this.updateFieldTrigger();
            const nbError = contracts.length - contracts.filter(Boolean).length;
            if (nbError === 0) {
              this.closeModal();
              this.notification.success(this.translate.instant(`otherslabels.notif_create_ok`));
              return;
            }
            //PARTIAL ERROR ON GENERATION
            if (nbError < contracts.length) {
              this.closeModal();
              this.notification.warn(
                this.translate.instant('pages.customer_details.error_add_contracts', {
                  nb: nbError,
                  total: quantity,
                })
              );
              return;
            }
            //FULL ERROR ON GENERATION
            this.notification.error(this.translate.instant(`otherslabels.notif_create_ko`));
          }
        });
    }
  }

  public get isTicketV1() {
    return this.data.version === 'v1';
  }
  public get isTicketV2() {
    return this.data.version === 'v2';
  }

  get network() {
    const networkId = this.productFormGroup.get('networkId').value;
    if (!networkId) return null;
    return (
      this.customerNetworks.find((customerNetwork) => customerNetwork.network.id === networkId)
        ?.network || null
    );
  }

  get categories() {
    if (!this.network) return [];
    return (
      this.networksProducts.find((network) => network.id === String(this.network.id))?.categories ||
      []
    );
  }

  get category() {
    const categoryId = this.productFormGroup.get('categoryId').value;
    if (this.categories.length === 0 || !categoryId) return null;
    return this.categories.find((category) => category.id === categoryId);
  }

  get products() {
    if (!this.category) return [];

    return this.category.items;
  }

  get product() {
    const productId = this.productFormGroup.get('productId').value;
    if (!productId) return null;

    return this.products.find((product) => product.id === productId);
  }

  get blueprint() {
    const blueprintId = this.productFormGroup.get('blueprint').value;
    if (!blueprintId) return null;

    return this.blueprints.find((blueprint) => blueprint.id === blueprintId);
  }

  get productTypes() {
    if (!this.product) return [];

    return this.product.products;
  }

  get productType() {
    const productTypeId = this.productFormGroup.get('productTypeId').value;
    if (!productTypeId) return null;

    return this.productTypes.find((productType) => productType.id === productTypeId);
  }

  get hasSupportChoice() {
    if (!this.product) return false;

    this.productInfoFormGroup.get('dematerialized').setValidators(Validators.required);
    return this.product.supports.length > 1;
  }

  get isProductOD(): boolean {
    if (!this.product) return false;

    return this.product.is_od;
  }

  get isPhotoMandatory(): boolean {
    if (!this.productFormGroup.get('productId').value) return false;

    return this.product?.isPhotoMandatory === true;
  }

  get needMoreInfos() {
    if (this.product) {
      const hasMultiSupport = ['PHYSICAL', 'DEMATERIALIZED'].every((support) => {
        return this.product.supports.includes(support);
      });

      return this.isProductOD || this.isPhotoMandatory || hasMultiSupport;
    }
    return this.isProductOD || this.isPhotoMandatory;
  }

  public getProductOptions(stepper: MatStepper) {
    if (this.startDate !== null && this.endDate !== null && this.startDate > this.endDate) {
      this.notification.error(this.translate.instant('pages.customer_details.start_date_invalid'));
      return;
    }

    if (!this.needMoreInfos) {
      stepper.next();
      return;
    }

    if (this.isProductOD) {
      this.originStopControl.setValidators(Validators.required);
      this.destinationStopControl.setValidators(Validators.required);
      this.productInfoFormGroup.controls.origin.setValidators(Validators.required);
      this.productInfoFormGroup.controls.destination.setValidators(Validators.required);
      this.productInfoFormGroup.controls.trip.setValidators(Validators.required);

      this.loadingStops = true;

      this.ticketService
        .getODStops(this.productFormGroup.value.productTypeId)
        .subscribe((stops) => {
          this.stops = stops;
          this.loadingStops = false;
        });
    }

    if (this.isPhotoMandatory) {
      this.productInfoFormGroup.controls.avatar.setValidators(Validators.required);
      this.productInfoFormGroup.controls.avatar.setValue(this.data.customer.picture);
    }

    stepper.next();
  }

  public onUpdateAvatar(customer) {
    this.data.customer = customer;
    this.productInfoFormGroup.controls.avatar.setValue(customer.picture);
    this.avatarErrorMessage = null;
  }

  public searchTrip() {
    this.isTripFound = false;
    this.tripErrorMessage = null;
    this.avatarErrorMessage = null;

    const { origin, destination } = this.productInfoFormGroup.value;

    if (!origin || !destination) {
      this.tripErrorMessage = this.translate.instant(
        'pages.generate_ticket_modal.select_origin_destination'
      );

      return;
    }

    this.ticketService
      .getODTrips(this.product.id, origin.stop_id, destination.stop_id)
      .subscribe((trips) => {
        if (!trips.length) {
          this.tripErrorMessage = this.translate.instant(
            'pages.generate_ticket_modal.trip_not_found'
          );
          return;
        }

        const trip = trips[0];

        this.productInfoFormGroup.controls.trip.setValue({
          agency: trip.agency,
          fareId: trip.fareId,
          price: trip.price,
        });

        this.isTripFound = true;
      });
  }

  public closeModal(): void {
    this.modalRef.close();
  }

  public goForward(stepper: MatStepper): void {
    this.avatarErrorMessage = null;
    this.tripErrorMessage = null;

    if (this.isProductOD && !this.productInfoFormGroup.value.trip) {
      this.tripErrorMessage = this.translate.instant(
        'pages.generate_ticket_modal.search_trip_message'
      );
      return;
    }

    if (this.isPhotoMandatory && !this.productInfoFormGroup.value.avatar) {
      this.avatarErrorMessage = this.translate.instant(
        'pages.generate_ticket_modal.download_photo_message'
      );
      return;
    }

    stepper.next();
  }

  private stopsFilter(value: string) {
    const filterValue = value.toLowerCase();

    return this.stops.filter((stop) => stop.stop_name.toLowerCase().includes(filterValue));
  }

  public desactivateTicket = () => {
    this.desactivateTicketOption = 'WAIT';
    this.desactivateTicketEvent.emit(this.data.transfert.ticket);
  };

  private setDefaultNetwork() {
    if (this.customerNetworks.length === 1) {
      this.productFormGroup.controls.networkId.setValue(this.customerNetworks[0].network.id);
    }
  }

  private async updateFieldTrigger() {
    const customer = await this.customersService.getCustomerDetails(this.data.customer.customer_id);
    const profiles = await this.customersService.$$getProfiles(this.data.customer.customer_id);
    this.updateField.emit({
      customer,
      profiles,
    });
  }
}
