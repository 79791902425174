<tu-card [withHeader]="true" *ngIf="shouldShowCustomerSearch">
  <div card-header>
    {{ 'pages.customer.filters' | translate }}
  </div>
  <div card-content>
    <tu-filter
      (filtersChanged)="computeFilters(); table.resetPagination()"
      [exportInformation]="exportInformation"
    ></tu-filter>
  </div>
</tu-card>

<tu-card [withHeader]="true" klass="tw-mt-6" *ngIf="shouldShowCustomerSearch">
  <div card-header>
    {{ 'pages.customer.customers_filters' | translate }}
  </div>

  <form
    card-content
    [formGroup]="filtersForm"
    (ngSubmit)="computeFilters()"
    class="tw-flex tw-w-full tw-flex-col"
  >
    <fieldset class="tw-flex tw-flex-col tw-gap-y-4 md:tw-flex-row">
      <legend class="tw-sr-only">{{ 'pages.customer.customers_filters' | translate }}</legend>

      <div class="input-group md:tw-w-1/3 md:tw-px-2">
        <div class="input-group-addon">
          <i class="fa fa-filter fa-lg"></i>
          <label class="sr-only" for="customer-attribute-search-selector">
            {{ 'pages.customer.attribute_name' | translate }}
          </label>
        </div>
        <select
          class="form-control"
          id="customer-attribute-search-selector"
          formControlName="attribute"
        >
          <option class="selector-title" [ngValue]="null">
            {{ 'pages.customer.attribute_name_placeholder' | translate }}
          </option>
          <option *ngFor="let attribute of attributes" [ngValue]="attribute.id">
            {{ attribute.label | translate }}
          </option>
        </select>
      </div>

      <div
        *ngIf="selectedAttribute"
        [ngSwitch]="selectedAttribute?.type"
        class="md:tw-mt-0 md:tw-w-1/3 md:tw-px-2"
      >
        <div class="input-group" *ngSwitchCase="'ENUM'">
          <div class="input-group-addon">
            <i class="fa fa-search"></i>
            <label class="sr-only" for="customer-attribute-search-input">
              {{ 'pages.customer.attribute_search' | translate }} TTC
            </label>
          </div>
          <select
            placeholder="{{ 'pages.customer.attribute_search_placeholder' | translate }}"
            class="form-control"
            id="customer-attribute-search-input"
            formControlName="attribute_search"
          >
            <option value="" disabled>
              {{ 'pages.customer.attribute_search_placeholder' | translate }}
            </option>
            <option *ngFor="let value of selectedAttribute?.values">{{ value }}</option>
          </select>
        </div>

        <div class="input-group" *ngSwitchDefault>
          <div class="input-group-addon">
            <i class="fa fa-search"></i>
            <label class="sr-only" for="customer-attribute-search-input">
              {{ 'pages.customer.attribute_search' | translate }} TTC
            </label>
          </div>
          <input
            placeholder="{{ 'pages.customer.attribute_search_placeholder' | translate }}"
            type="search"
            class="form-control"
            id="customer-attribute-search-input"
            formControlName="attribute_search"
          />
        </div>
      </div>

      <div class="input-group md:tw-mt-0 md:tw-w-1/3 md:tw-px-2">
        <div class="input-group-addon">
          <i class="fa fa-sitemap fa-lg"></i>
          <label class="sr-only" for="virtual_provider">
            {{ 'pages.customer.registration_platform' | translate }}
          </label>
        </div>
        <select class="form-control" id="virtual_provider" formControlName="virtual_provider">
          <option class="selector-title" disabled="disabled" [ngValue]="null">
            {{ 'pages.customer.registration_platform' | translate }}
          </option>
          <option class="selector-title" [ngValue]="null">
            {{ 'pages.order.all' | translate }}
          </option>
          <option class="selector-title" ngValue="tixipass">TixiPass</option>
          <option class="selector-title" ngValue="sncf">SNCF</option>
          <option class="selector-title" ngValue="InstantSystemIrigo">Instant System Irigo</option>
          <option class="selector-title" ngValue="InstantSystemTac">Instant System Tac</option>
          <option class="selector-title" ngValue="InstantSystemTwisto">
            Instant System Twisto
          </option>
        </select>
      </div>
    </fieldset>

    <div
      *ngIf="filtersForm && table"
      class="tw-mt-4 tw-flex tw-flex-col tw-justify-end tw-gap-y-2 md:tw-flex-row md:tw-gap-x-2 md:tw-gap-y-0"
    >
      <button class="btn btn-outline-warning" (click)="filtersForm.reset()">
        {{ 'pages.order.reset_form' | translate }}
      </button>
      <button type="submit" class="btn btn-primary">
        {{ 'pages.order.apply_filters' | translate }}
      </button>
    </div>
  </form>
</tu-card>

<tu-card klass="tw-mt-6">
  <tu-table-server
    #table
    card-content
    idKey="customer_id"
    [hasCheckbox]="true"
    [serverConfig]="serverPaginationService.serverConfig('Customer/list.json', 'customers')"
    [columnsConfig]="columnsConfig"
    [fetchConfig]="serverPaginationService.fetchConfig"
    [filters]="filters"
    [rowLink]="computeLink"
  ></tu-table-server>
</tu-card>
