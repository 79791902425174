<tu-card [withHeader]="true" *ngIf="shouldShowOrderSearch">
  <div card-header>
    {{ 'pages.order.filters' | translate }}
  </div>

  <div card-content>
    <tu-filter
      (filtersChanged)="computeFilters(); table.resetPagination()"
      [exportInformation]="exportInformation"
    ></tu-filter>
  </div>
</tu-card>

<tu-card [withHeader]="true" klass="tw-mt-6" *ngIf="shouldShowOrderSearch">
  <div card-header>
    {{ 'pages.order.sales_filter' | translate }}
  </div>

  <form
    card-content
    [formGroup]="filterOptionsForm"
    (ngSubmit)="computeFilters()"
    class="tw-flex tw-w-full tw-flex-col"
  >
    <fieldset class="tw-flex tw-flex-col tw-gap-y-4 md:tw-flex-row md:tw-gap-x-2 md:tw-gap-y-0">
      <legend class="tw-sr-only">{{ 'pages.order.filters' | translate }}</legend>

      <div class="input-group">
        <div class="input-group-addon">
          <i class="fa fa-ticket fa-lg"></i>
          <label class="sr-only" for="order_status">
            {{ 'pages.order.sale_status' | translate }}
          </label>
        </div>

        <select class="form-control" id="order_status" formControlName="order_status">
          <option class="selector-title" disabled="disabled" [ngValue]="null">
            {{ 'pages.order.sale_status' | translate }}
          </option>
          <option class="selector-title" [ngValue]="null">
            {{ 'pages.order.all' | translate }}
          </option>
          <option class="selector-title" value="COMPLETED">
            {{ 'pages.order.ok' | translate }}
          </option>
          <option class="selector-title" value="PREAUTHORIZED">
            {{ 'pages.order.preauthorized' | translate }}
          </option>
          <option class="selector-title" value="ERROR">
            {{ 'pages.order.refused' | translate }}
          </option>
          <option class="selector-title" value="PENDING">
            {{ 'pages.order.unfinished' | translate }}
          </option>
          <option class="selector-title" value="CANCELED">
            {{ 'pages.order.cancelled' | translate }}
          </option>
          <option class="selector-title" value="REGULARIZATION">
            {{ 'pages.order.regularization' | translate }}
          </option>
          <option class="selector-title" value="TO_REFUND">
            {{ 'pages.order.to_refund' | translate }}
          </option>
          <option class="selector-title" value="REFUNDED">
            {{ 'pages.order.refunded' | translate }}
          </option>
          <option class="selector-title" value="PAYMENT_ISSUE">
            {{ 'pages.order.default_payment' | translate }}
          </option>
        </select>
      </div>

      <div class="input-group">
        <div class="input-group-addon">
          <i class="fa fa-archive fa-lg"></i>
          <label class="sr-only" for="order_process_status">
            {{ 'pages.order.order_process_status' | translate }}
          </label>
        </div>
        <select
          class="form-control"
          id="order_process_status"
          formControlName="order_process_status"
        >
          <option class="selector-title" disabled="disabled" [ngValue]="null">
            {{ 'pages.order.order_process_status' | translate }}
          </option>
          <option class="selector-title" [ngValue]="null">
            {{ 'pages.order.all' | translate }}
          </option>
          <option class="selector-title" value="PROCESSED">
            {{ 'pages.order.processed' | translate }}
          </option>
          <option class="selector-title" value="TO_PROCESS">
            {{ 'pages.order.to_process' | translate }}
          </option>
          <option class="selector-title" value="PROCESSING">
            {{ 'pages.order.processing' | translate }}
          </option>
          <option class="selector-title" value="AUTOPROCESSED">
            {{ 'pages.order.autoprocessed' | translate }}
          </option>
        </select>
      </div>

      <div class="input-group">
        <div class="input-group-addon">
          <i class="fa fa-feed fa-lg"></i>
          <label class="sr-only" for="shipping_mode">
            {{ 'pages.order.delivery' | translate }}
          </label>
        </div>
        <select class="form-control" id="shipping_mode" formControlName="shipping_mode">
          <option class="selector-title" disabled="disabled" [ngValue]="null">
            {{ 'pages.order.delivery' | translate }}
          </option>
          <option class="selector-title" [ngValue]="null">
            {{ 'pages.order.all' | translate }}
          </option>
          <option class="selector-title" value="DEMATERIALIZED">
            {{ 'pages.order.virtual' | translate }}
          </option>
          <option class="selector-title" value="PHYSICAL">
            {{ "Physique (à la charge de l'exploitant)" }}
          </option>
          <option class="selector-title" value="MEDIA">
            {{ 'pages.order.media' | translate }}
          </option>
          <option class="selector-title" value="COLLECT">
            {{ 'pages.order.click_and_collect' | translate }}
          </option>
          <option class="selector-title" value="SHIPPING">
            {{ 'Livraison à domicile' }}
          </option>
          <option class="selector-title" value="PHYSICAL,MEDIA">
            {{ 'pages.order.physical_media' | translate }}
          </option>
          <option class="selector-title" value="DEMATERIALIZED,PHYSICAL,MEDIA">
            {{ 'pages.order.physical_virtual' | translate }}
          </option>
        </select>
      </div>
    </fieldset>

    <fieldset
      class="tw-mt-4 tw-flex tw-flex-col tw-gap-y-4 md:tw-flex-row md:tw-gap-x-2 md:tw-gap-y-0"
    >
      <legend class="tw-sr-only">{{ 'pages.order.filters' | translate }}</legend>

      <div class="input-group">
        <div class="input-group-addon"><i class="fa fa-credit-card fa-lg"></i></div>

        <input
          type="number"
          class="form-control"
          id="order_installment"
          formControlName="order_installment"
          min="1"
          placeholder="{{ 'pages.order.payment_times' | translate }}"
        />
      </div>

      <div class="input-group">
        <div class="input-group-addon">
          <i class="fa fa-eur fa-lg"></i>
          <label class="sr-only" for="order_price">{{ 'pages.order.total' | translate }} TTC</label>
        </div>
        <input
          placeholder="{{ 'pages.order.total' | translate }}"
          type="number"
          class="form-control"
          id="order_price"
          formControlName="order_price"
        />
      </div>

      <div class="input-group">
        <div class="input-group-addon">
          <i class="fa fa-sitemap fa-lg"></i>
          <label class="sr-only" for="virtual_provider">
            {{ 'pages.order.platform' | translate }}
          </label>
        </div>
        <select class="form-control" id="virtual_provider" formControlName="virtual_provider">
          <option class="selector-title" disabled="disabled" [ngValue]="null">
            {{ 'pages.order.platform' | translate }}
          </option>
          <option class="selector-title" [ngValue]="null">
            {{ 'pages.order.all' | translate }}
          </option>
          <option class="selector-title" ngValue="tixipass">TixiPass</option>
          <option class="selector-title" ngValue="sncf">SNCF</option>
          <option class="selector-title" ngValue="modalis">Modalis</option>
          <option class="selector-title" ngValue="smt_nfc">SMT</option>
          <option class="selector-title" ngValue="instantsystem">Instant Sytem</option>
          <option class="selector-title" ngValue="otipass">Otipass</option>
        </select>
      </div>
    </fieldset>

    <fieldset
      class="tw-mt-4 tw-flex tw-flex-col tw-gap-y-4 md:tw-flex-row md:tw-gap-x-2 md:tw-gap-y-0"
    >
      <legend class="tw-sr-only">{{ 'pages.order.filters' | translate }}</legend>

      <div class="input-group md:tw-w-1/3">
        <div class="input-group-addon">
          <i class="fa fa-sitemap fa-lg"></i>
          <label class="sr-only" for="payment_method">
            {{ 'pages.order.payment_method' | translate }}
          </label>
        </div>
        <select class="form-control" id="payment_method" formControlName="payment_method">
          <option class="selector-title" disabled="disabled" [ngValue]="null">
            {{ 'pages.order.payment_method' | translate }}
          </option>
          <option class="selector-title" [ngValue]="null">
            {{ 'pages.order.all' | translate }}
          </option>
          <option class="selector-title" ngValue="CARD">
            {{ 'pages.order.card_long' | translate }}
          </option>
          <option class="selector-title" ngValue="TRANSFER">
            {{ 'pages.order.sepa_long' | translate }}
          </option>
        </select>
      </div>

      <div class="input-group md:tw-w-1/3">
        <div class="input-group-addon">
          <i class="fa fa-sitemap fa-lg"></i>
          <label class="sr-only" for="origin_type">
            {{ 'pages.order.origin' | translate }}
          </label>
        </div>
        <select class="form-control" id="origin_type" formControlName="origin_type">
          <option class="selector-title" disabled="disabled" [ngValue]="null">
            {{ 'pages.order.origin' | translate }}
          </option>
          <option class="selector-title" [ngValue]="null">
            {{ 'pages.order.all' | translate }}
          </option>
          <option class="selector-title" ngValue="ESHOP">
            {{ 'pages.order.origin_type_eshop' | translate }}
          </option>
          <option class="selector-title" ngValue="SCHOOL">
            {{ 'pages.order.origin_type_school' | translate }}
          </option>
          <option class="selector-title" ngValue="APP">
            {{ 'pages.order.origin_type_app' | translate }}
          </option>
          <option class="selector-title" ngValue="RETAIL">
            {{ 'pages.order.origin_type_retail' | translate }}
          </option>
          <option class="selector-title" ngValue="other">
            {{ 'pages.order.other' | translate }}
          </option>
        </select>
      </div>

      <div *ngIf="productItems.length" class="input-group md:tw-w-1/3">
        <div class="input-group-addon">
          <i class="fa fa-sitemap fa-lg"></i>
          <label class="sr-only" for="products">
            {{ 'pages.order.product' | translate }}
          </label>
        </div>

        <div class="pl-0 d-flex input-group tw-w-full">
          <input
            type="search"
            [formControl]="productFilterControl"
            [matAutocomplete]="auto"
            class="form-control"
            placeholder="{{ 'pages.order.product' | translate }}"
            name="products"
          />

          <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
            <mat-option
              *ngFor="let productItem of filteredProductItems"
              [ngClass]="{
                selected:
                  productFilterControl.value !== null &&
                  productFilterControl.value.toLowerCase() === productItem.name.toLowerCase()
              }"
              [value]="productItem.id + ' | ' + productItem.name"
            >
              <span>{{ productItem.id }} | {{ productItem.name }}</span>
            </mat-option>
          </mat-autocomplete>

          <div class="input-group-append">
            <button
              class="btn btn-danger"
              type="button"
              [disabled]="productFilterControl.value === null"
              (click)="productFilterControl.setValue(null)"
            >
              <i class="fa fa-close"></i>
            </button>
          </div>
        </div>
      </div>
    </fieldset>

    <fieldset
      class="tw-mt-4 tw-flex tw-flex-col tw-gap-y-4 md:tw-flex-row md:tw-gap-x-2 md:tw-gap-y-0"
    >
      <legend class="tw-sr-only">{{ 'pages.order.filters' | translate }}</legend>

      <div *ngIf="attributes?.length" class="input-group md:tw-w-1/3">
        <div class="input-group-addon">
          <i class="fa fa-sitemap fa-lg"></i>
          <label class="sr-only" for="customer-attribute-search-selector">
            {{ 'pages.customer.attribute_name' | translate }}
          </label>
        </div>
        <select
          class="form-control"
          id="customer-attribute-search-selector"
          formControlName="attribute"
        >
          <option class="selector-title" [ngValue]="null">
            {{ 'pages.customer.attribute_name_placeholder' | translate }}
          </option>
          <option *ngFor="let attribute of attributes" [ngValue]="attribute.id">
            {{ attribute.label | translate }}
          </option>
        </select>
      </div>

      <div
        *ngIf="selectedAttribute"
        [ngSwitch]="selectedAttribute?.type"
        class="tw-mt-4 md:tw-mt-0 md:tw-w-1/3"
      >
        <div class="input-group" *ngSwitchDefault>
          <div class="input-group-addon">
            <i class="fa fa-search"></i>
            <label class="sr-only" for="customer-attribute-search-input">
              {{ 'pages.customer.attribute_search' | translate }} TTC
            </label>
          </div>
          <input
            placeholder="{{ 'pages.customer.attribute_search_placeholder' | translate }}"
            type="search"
            class="form-control"
            id="customer-attribute-search-input"
            formControlName="attribute_search"
          />
        </div>
      </div>
    </fieldset>

    <div
      class="tw-mt-4 tw-flex tw-flex-col tw-justify-end tw-gap-y-2 md:tw-flex-row md:tw-gap-x-2 md:tw-gap-y-0"
    >
      <button
        class="btn btn-outline-warning"
        (click)="filterOptionsForm.reset(); productFilterControl.reset();"
      >
        {{ 'pages.order.reset_form' | translate }}
      </button>

      <button type="submit" class="btn btn-primary">
        {{ 'pages.order.apply_filters' | translate }}
      </button>
    </div>
  </form>
</tu-card>

<tu-card klass="tw-mt-6">
  <tu-table-server
    #table
    card-content
    [hasCheckbox]="true"
    [serverConfig]="serverPaginationService.serverConfig('Order/getOrder.json', 'orders')"
    [columnsConfig]="columnsConfig"
    [fetchConfig]="serverPaginationService.fetchConfig"
    [filters]="filters"
    [rowLink]="computeLink"
  ></tu-table-server>
</tu-card>
