
<h5 
  mat-dialog-title 
  [ngClass]="{
    '!tw-mb-0': data.showSubscriptionCheckbox
  }"
>{{ 'pages.customer_details.delete_ticket_title' | translate: { name: data.label } }}</h5>

<div *ngIf="data.showSubscriptionCheckbox" mat-dialog-content class="tw-py-6 tw-space-y-2">
  <form [formGroup]="form">
    <div class="tw-flex tw-gap-2">
      <input
        id="delete-linked-subscription"
        type="checkbox"
        name="delete-linked-subscription"
        formControlName="deleteLinkedSubscription"
        class="default-input"
      />
      <label class="!tw-mb-0" for="delete-linked-subscription">{{ 'pages.customer_details.delete_ticket_subscription' | translate }}</label>
    </div>
  </form>
</div>

<div mat-dialog-actions class="tw-flex tw-gap-2 tw-justify-end tw-flex-col lg:tw-flex-row">
  <button type="button" class="btn btn-secondary" (click)="close()">
    {{ 'otherslabels.btn_cancel' | translate }}
  </button>
  <button type="button" class="btn btn-danger" (click)="deleteTicket()">
    {{ 'otherslabels.btn_delete' | translate }}
  </button>
</div>