import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { GenericResponse } from '@app/modules/shared/models/generic-response';
import { AuthService } from '@app/modules/shared/services/auth.service';
import { environment as env } from '@env/environment';
import { firstValueFrom, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { SubscriptionDetails, SubscriptionListItem } from '../models/subscription.interface';

@Injectable()
export class SubscriptionsService {
  constructor(private http: HttpClient, private authService: AuthService, private router: Router) {}

  public list() {
    return this.http
      .get<GenericResponse<SubscriptionListItem[]>>(`${env.config.feedRoot}Subscriptions/list.json`)
      .pipe(
        map(({ response }) => {
          return response.sort((a, b) => b.id - a.id);
        }),
        catchError(() => throwError('Erreur serveur !'))
      );
  }

  public get(id: string, cache: boolean = true) {
    const params: { id: string; time?: number } = { id };

    if (!cache) {
      params.time = Date.now();
    }

    return this.http
      .get<GenericResponse>(`${env.config.feedRoot}Subscriptions/get.json`, {
        params,
      })
      .pipe(
        map(({ response }) => {
          return response as SubscriptionDetails;
        }),
        catchError(() => throwError('Erreur serveur !'))
      );
  }

  public enableSubscription(id: number) {
    return firstValueFrom(
      this.http
        .post<GenericResponse>(`${env.config.feedRoot}Subscriptions/enable.json`, { id })
        .pipe(
          map(({ response }) => {
            return response as any;
          }),
          catchError(() => throwError('Erreur serveur !'))
        )
    );
  }

  public disableSubscription(id: number, handleError: boolean = true) {
    return firstValueFrom(
      this.http
        .post<GenericResponse>(`${env.config.feedRoot}Subscriptions/disable.json`, { id })
        .pipe(
          map(({ response }) => {
            return response;
          }),
          catchError((error) => {
            if (handleError) {
              return throwError('Erreur serveur !');
            }

            return throwError(error.error.code);
          })
        )
    );
  }
}
