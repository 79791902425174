import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface DialogData {
  title: string;
  text: string;
}

/**
 * @title Dialog Overview
 */
@Component({
  templateUrl: 'customer-lost-phone-modal.component.html',
})
export class CustomerLostPhoneModalComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: DialogData) {}
}
