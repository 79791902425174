<h5 mat-dialog-title class="mb-1">
  {{ 'pages.customer_details.update_personal_information' | translate }}
</h5>

<div mat-dialog-content class="tw-relative tw-max-h-screen">
  <form (ngSubmit)="confirmInformationEdit()" class="tw-h-full tw-overflow-auto">
    <label class="tw-text-gray-900" for="lastname">
      {{ 'pages.customer_details.lastname' | translate }}
    </label>
    <input
      type="text"
      id="lastname"
      name="lastname"
      [placeholder]="translateService.instant('pages.customer_details.lastname')"
      class="mb-1 form-control"
      [disabled]="loading"
      [(ngModel)]="lastnameInput"
    />

    <label class="tw-text-gray-900" for="firstname">
      {{ 'pages.customer_details.firstname' | translate }}
    </label>
    <input
      type="text"
      id="firstname"
      name="firstname"
      [placeholder]="translateService.instant('pages.customer_details.firstname')"
      class="mb-1 form-control"
      [disabled]="loading"
      [(ngModel)]="firstnameInput"
    />

    <label class="tw-text-gray-900" for="birthday">
      {{ 'pages.customer_details.birthday' | translate }}
    </label>
    <input
      type="date"
      id="birthday"
      name="birthday"
      [placeholder]="translateService.instant('pages.customer_details.birthday')"
      class="mb-1 form-control"
      [disabled]="loading"
      [(ngModel)]="birthdayInput"
    />
    
    <div *ngIf="data.isCustomerParent">
      <label class="tw-text-gray-900" for="email">
        {{ 'pages.customer_details.contact_email' | translate }}
      </label>
      <input
        type="text"
        id="email"
        name="email"
        [placeholder]="translateService.instant('pages.customer_details.contact_email')"
        class="mb-1 form-control"
        [disabled]="loading"
        [(ngModel)]="emailInput"
      />
    </div>

    <label class="tw-text-gray-900" for="phone">
      {{ 'pages.customer_details.phone_number' | translate }}
    </label>
    <input
      type="text"
      id="phone"
      name="phone"
      [placeholder]="translateService.instant('pages.customer_details.phone_number')"
      class="mb-1 form-control"
      [disabled]="loading"
      [(ngModel)]="phoneInput"
    />

    <label class="tw-text-gray-900" for="streetNumber">
      {{ 'pages.customer_details.street_number' | translate }}
    </label>
    <input
      type="text"
      id="streetNumber"
      name="streetNumber"
      [placeholder]="translateService.instant('pages.customer_details.street_number')"
      class="mb-1 form-control"
      [disabled]="loading"
      [(ngModel)]="streetNumberInput"
    />

    <label class="tw-text-gray-900" for="route">
      {{ 'pages.customer_details.street' | translate }}
    </label>
    <input
      type="text"
      id="route"
      name="route"
      [placeholder]="translateService.instant('pages.customer_details.street')"
      class="mb-1 form-control"
      [disabled]="loading"
      [(ngModel)]="routeInput"
    />

    <label class="tw-text-gray-900" for="zipcode">
      {{ 'pages.network_details.zip_code_placeholder' | translate }}
    </label>
    <input
      type="text"
      id="zipcode"
      name="zipcode"
      [placeholder]="translateService.instant('pages.network_details.zip_code_placeholder')"
      class="mb-1 form-control"
      [disabled]="loading"
      [(ngModel)]="zipCodeInput"
    />

    <label class="tw-text-gray-900" for="city">
      {{ 'pages.network_details.city' | translate }}
    </label>
    <input
      type="text"
      id="city"
      name="city"
      [placeholder]="translateService.instant('pages.network_details.city')"
      class="mb-1 form-control"
      [disabled]="loading"
      [(ngModel)]="cityInput"
    />

    <label class="tw-text-gray-900" for="country">
      {{ 'pages.customer_details.country' | translate }}
    </label>
    <input
      type="text"
      id="country"
      name="country"
      [placeholder]="translateService.instant('pages.customer_details.country')"
      class="mb-1 form-control"
      [disabled]="loading"
      [(ngModel)]="countryInput"
    />

    <div class="row" *ngIf="errorMessage">
      <div class="text-danger tw-mb-1 tw-flex tw-justify-center">
        <span>{{ errorMessage }}</span>
      </div>
    </div>

    <div mat-dialog-actions class="pull-right tw-m-0 tw-mt-2 tw-p-0" style="min-height: auto">
      <button
        type="button"
        (click)="closeModal()"
        [disabled]="loading"
        class="mt-0 mr-1 btn btn-sm btn-secondary"
      >
        {{ 'otherslabels.btn_cancel' | translate }}
      </button>

      <button type="submit" [disabled]="loading" class="mt-0 btn btn-sm btn-primary">
        <i class="fa fa-circle-o-notch fa-spin" [ngClass]="{ 'd-none': !loading }"></i>
        {{
          !loading ? ('otherslabels.btn_save' | translate) : ('otherslabels.loading' | translate)
        }}
      </button>
    </div>
  </form>
</div>

<simple-notifications [options]="notificationOptions"></simple-notifications>
