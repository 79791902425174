import { Pipe, PipeTransform } from '@angular/core';
import { escapeRegExp } from '@app/utils/escape-regexp';

@Pipe({
  name: 'highlight',
})
export class HighlightPipe implements PipeTransform {
  transform(value: string, str: string): any {
    if (value) {
      str = escapeRegExp(str);
      let re = new RegExp(`(${str})`, 'gi');
      return str ? value.toString().replace(re, '<mark>$1</mark>') : value;
    } else {
      return value;
    }
  }
}
