export type TicketStatus = 'EXPIRED' | 'PENDING' | 'CANCELED' | 'REMAINING' | 'ACTIVE';
export type TicketPurpose = 'DEVICE_CHANGE' | 'COMMERCIAL_GESTURE' | 'COMMERCIAL_OFFER' | 'TEST';
export const TicketPurposeLabel = {
  DEVICE_CHANGE: `otherslabels.purpose_device_change`,
  COMMERCIAL_GESTURE: `otherslabels.purpose_commercial_gesture`,
  COMMERCIAL_OFFER: `otherslabels.purpose_commercial_offer`,
  CUSTOMER_SUPPORT: `otherslabels.support`,
  TEST: `otherslabels.purpose_test`,
};

export interface Ticket {
  id: string;
  ticket_id: string;
  order_identifier?: string;
  order_id?: string;
  user_photo?: string;
  customer_id: string;
  date: string;
  expire_at: string;
  user_id: string;
  lastname: string;
  firstname: string;
  email: string;
  label: string;
  start_date: Date;
  end_date: Date;
  status: TicketStatus;
  punch_number?: null | string;
  network_id: string;
  photo_mandatory?: string;
  dematerialized: '0' | '1';
  universal?: boolean;
  ticket_order_issuer: 'airweb' | 'sncf';
  client: {
    id: string;
    name: string;
    slug: string;
  } | null;
  stop: { id: string; code: string; name: string } | null;
  stop_code: string | null;
  line: { id: string; code: string; name: string } | null;
  line_code: string | null;
  generationReason?: TicketPurpose | null;
  product_id?: string | null;
  cms_user_id?: string;
  operator?: {
    id: string;
    login: string;
    firstName: string;
    lastName: string;
  };
}
