import { Uppy } from '@uppy/core';
import { firstValueFrom } from 'rxjs';
import ImageEditor from '@uppy/image-editor';
import French from '@uppy/locales/lib/fr_FR';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SubmissionService } from '@app/modules/submissions/services/submission.service';
import { PaperworkService } from '@app/modules/submissions/services/paperwork.service';

export interface DialogData {
  documentTypeId: string;
  userId: string;
  submissionId?: string;
  ignoreDocumentStatus?: boolean;
  documentId?: number;
  documentStatus?: string;
}

@Component({
  selector: 'tu-update-document-modal',
  templateUrl: './update-document-modal.component.html',
})
export class UpdateDocumentModalComponent implements OnInit {
  public uppy: Uppy;
  public isLoading = false;
  public errorInitializingUppy = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private submissionService: SubmissionService,
    private paperworkService: PaperworkService,
    public modalRef: MatDialogRef<UpdateDocumentModalComponent>
  ) {}

  public ngOnInit() {
    try {
      const uppy = new Uppy({
        debug: true,
        autoProceed: true,
        locale: French,
        allowMultipleUploadBatches: false,
        restrictions: {
          maxNumberOfFiles: 1,
          minNumberOfFiles: 1,
          allowedFileTypes: ['image/*', 'application/pdf'],
        },
      });

      uppy.use(ImageEditor, {
        quality: 0.8,
      });

      this.uppy = uppy;
    } catch (error) {
      console.error(error);
      this.errorInitializingUppy = true;
    }
  }

  public get hasFiles(): boolean {
    return Object.keys(this.uppy.state.files).length > 0;
  }

  public async updateDocument() {
    this.isLoading = true;

    try {
      const [file] = Object.values(this.uppy.state.files);

      // If ignore doc status -> set status as INVALID -> send the document -> reset previous document status
      if (this.data.ignoreDocumentStatus && this.data.documentId && this.data.documentStatus) {
        await this.paperworkService.updateDocument(this.data.documentId, {
          status: 'INVALID',
        });

        await this.sendDocument(file.data as File);

        await this.paperworkService.updateDocument(this.data.documentId, {
          status: this.data.documentStatus,
        });
      } else {
        await this.sendDocument(file.data as File);
      }

      if (this.data.submissionId) {
        await firstValueFrom(
          this.submissionService.updateSubmission(this.data.submissionId, 'PENDING', [])
        );
      }

      this.modalRef.close(true);
    } catch {
      this.uppy.reset();
    } finally {
      this.isLoading = false;
    }
  }

  private async sendDocument(file: File) {
    return this.submissionService.updateDocument({
      ...this.data,
      file: file,
    });
  }
}
