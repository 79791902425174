import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@app/modules/shared/guards/auth.guard';
import { PermissionGuard } from '@app/modules/shared/guards/permission.guard';
import { CustomerDetailComponent } from './components/customer-detail/customer-detail.component';
import { CustomerSearchComponent } from './components/customer-search/customer-search.component';
import { CustomersComponent } from './components/customers.component';
import { ConfigGuard } from '../shared/guards/config.guard';

const routes: Routes = [
  {
    path: '',
    canActivate: [AuthGuard],
    canActivateChild: [PermissionGuard],
    data: {
      title: 'pages.list_menu.clients',
      permissions: {
        All: ['ACCESS_CUSTOMERS'],
        redirectTo: '/403',
      },
    },
    children: [
      {
        path: '',
        canActivate: [AuthGuard],
        component: CustomersComponent,
        data: {
          title: '',
        },
      },
      {
        path: 'search',
        canActivate: [AuthGuard, ConfigGuard],
        component: CustomerSearchComponent,
        data: {
          title: 'otherslabels.bc_search_client',
          features: ['globalSearch'],
        },
      },
      {
        path: ':id',
        canActivate: [AuthGuard],
        component: CustomerDetailComponent,
        data: {
          title: 'otherslabels.bc_client_detail',
        },
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class CustomersRoutingModule {}
