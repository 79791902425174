<h5 mat-dialog-title class="mb-1">{{ 'pages.customer_details.edit_metadata' | translate }}e</h5>

<div mat-dialog-content class="tw-h-36">
  <form class="mb-1" (ngSubmit)="confirmAttributeEdit()">
    <label class="tw-text-gray-900" for="attributeInput">{{ data.label }}</label>
    <input
      type="text"
      id="attributeInput"
      name="attributeInput"
      [placeholder]="data.label"
      class="form-control"
      [disabled]="loading"
      [(ngModel)]="attributeInput"
      *ngIf="!['ENUM', 'BOOLEAN'].includes(data.attributeType)"
    />

    <select
      id="attributeInput"
      class="form-control"
      *ngIf="data.attributeType === 'ENUM'"
      (change)="onSelectValue($event.target.value)"
    >
      <option
        *ngFor="let attributeValue of data.attributeValues"
        [value]="attributeValue"
        [selected]="attributeValue === data.value"
      >
        {{ attributeValue }}
      </option>
    </select>

    <div class="tw-flex tw-space-x-2" *ngIf="data.attributeType === 'BOOLEAN'">
      <label class="tw-flex tw-items-center tw-space-x-2 tw-text-gray-500">
        <input
          type="radio"
          name="attribute"
          [checked]="data.value === true"
          (change)="onSelectValue(true)"
        />
        <span>{{ 'otherslabels.yes' | translate }}</span>
      </label>
      <label class="tw-flex tw-items-center tw-space-x-2 tw-text-gray-500">
        <input
          type="radio"
          name="attribute"
          [checked]="data.value === false"
          (change)="onSelectValue(false)"
        />
        <span>{{ 'otherslabels.no' | translate }}</span>
      </label>
    </div>

    <div mat-dialog-actions class="pull-right">
      <button
        type="button"
        (click)="closeModal()"
        [disabled]="loading"
        class="mt-0 mr-1 btn btn-sm btn-secondary"
      >
        {{ 'otherslabels.btn_cancel' | translate }}
      </button>

      <button type="submit" [disabled]="loading" class="mt-0 btn btn-sm btn-primary">
        <i class="fa fa-circle-o-notch fa-spin" [ngClass]="{ 'd-none': !loading }"></i>
        {{
          !loading ? ('otherslabels.btn_confirm' | translate) : ('otherslabels.loading' | translate)
        }}
      </button>
    </div>
  </form>
</div>

<simple-notifications [options]="notificationOptions"></simple-notifications>
