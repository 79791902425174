import { Component, Input } from '@angular/core';
import { AuthService } from '@app/modules/shared/services/auth.service';
import { heroicons } from '@assets/icons/heroicons';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'tu-customer-comment',
  templateUrl: './customer-comment.component.html',
  styleUrls: ['./customer-comment.component.scss'],
})
export class CustomerCommentComponent {
  public heroicons = heroicons;

  @Input()
  public comment: any;

  @Input()
  public commentToEdit: (id: number, message: string) => void;

  @Input()
  public editedComment: string;

  @Input()
  public commentToDelete: (id: number) => void;

  @Input()
  public markdownToHTML: (markdown: string) => string;

  constructor(public auth: AuthService, public translate: TranslateService) {}
}
