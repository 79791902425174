<form
  [formGroup]="filterForm"
  (ngSubmit)="onClickApplyFilters()"
  class="tw-flex tw-flex-col tw-gap-4"
>
  <fieldset class="tw-flex tw-flex-col tw-gap-y-4 md:tw-flex-row md:tw-gap-x-2 md:tw-gap-y-0">
    <legend class="tw-sr-only">{{ 'pages.order.filters' | translate }}</legend>

    <div *ngIf="config?.query !== false" class="input-group">
      <span class="input-group-addon" id="table-search"><i class="fa fa-search"></i></span>
      <input
        formControlName="query"
        type="search"
        class="form-control avatar-find"
        placeholder="{{ 'pages.order.search' | translate }}"
        aria-describedby="table-search"
      />
    </div>

    <div
      *ngIf="config?.network !== false && authService.networks && authService.networks.length > 1"
      class="input-group tw-flex"
    >
      <span class="input-group-addon" id="table-search"><i class="fa fa-map-signs"></i></span>

      <input
        type="search"
        #networkInput
        [formControl]="networkNameCtrl"
        [matAutocomplete]="auto"
        class="form-control"
        placeholder="{{ 'pages.order.all_networks' | translate }}"
      />

      <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
        <mat-option
          *ngFor="let name of filteredNetworksName | async"
          [ngClass]="{
            selected:
              networkNameCtrl.value !== null &&
              networkNameCtrl.value.toLowerCase() === name.toLowerCase()
          }"
          [value]="name"
        >
          <div class="tw-inline-block">{{ name }}</div>
        </mat-option>
      </mat-autocomplete>

      <div class="input-group-append">
        <button
          class="btn btn-danger"
          type="button"
          [disabled]="networkNameCtrl.value === null"
          (click)="networkNameCtrl.setValue(null)"
        >
          <i class="fa fa-close"></i>
        </button>
      </div>
    </div>
  </fieldset>

  <fieldset
    *ngIf="config?.dates !== false || config?.onlyActive === true || config?.export !== false"
    class="tw-grid tw-w-full tw-gap-4 md:tw-flex md:tw-items-center md:tw-justify-between md:tw-gap-0"
  >
    <legend class="tw-sr-only">{{ 'pages.shared.dates_filter' | translate }}</legend>

    <div
      *ngIf="config?.dates !== false"
      class="tw-flex tw-flex-col tw-items-center tw-gap-y-4 sm:tw-flex-row sm:tw-gap-y-0 sm:tw-gap-x-2"
    >
      <div class="input-group tw-flex" role="group">
        <span class="input-group-addon fa-lg">{{ 'pages.order.from' | translate }}</span>
        <input
          type="date"
          placeholder=""
          class="form-control"
          aria-describedby="date-start"
          data-format="dd/MM/yyyy"
          formControlName="from"
        />
      </div>

      <div class="input-group tw-flex" role="group">
        <span class="input-group-addon fa-lg">{{ 'pages.order.to' | translate }}</span>
        <input
          type="date"
          placeholder=""
          class="form-control"
          aria-describedby="date-end"
          data-format="dd/MM/yyyy"
          formControlName="to"
        />
      </div>
    </div>

    <div *ngIf="config?.onlyActive === true" class="tw-flex tw-items-center tw-gap-x-2">
      <label for="filter_only_active" class="tw-mb-0 tw-cursor-pointer">
        {{ 'pages.shared.hide_unactive' | translate }}
      </label>
      <label class="switch switch-default switch-primary tw-m-0">
        <input
          id="filter_only_active"
          type="checkbox"
          class="switch-input"
          [checked]=""
          formControlName="onlyActive"
        />
        <span class="switch-label"></span>
        <span class="switch-handle"></span>
      </label>
    </div>

    <ng-content></ng-content>

    <div
      *ngIf="config?.export !== false"
      class="tw-ml-auto tw-flex tw-items-center tw-gap-y-2 tw-gap-x-2 md:tw-gap-y-0"
    >
      <span class="tw-hidden sm:tw-inline-block">{{ 'pages.order.export' | translate }}:</span>
      <!-- Export API -->
      <span
        *ngIf="exportInformation"
        ngbTooltip="{{ 'pages.order.export_disable_message' | translate }}"
        [disableTooltip]="isExportEnabled"
        [ngClass]="{ 'tw-cursor-not-allowed': !isExportEnabled }"
      >
        <a
          (mouseenter)="computeLinkHref('excel')"
          [href]="exportLinkHref"
          class="btn btn-outline-primary tw-mr-2"
          [ngClass]="{ disabled: !isExportEnabled }"
        >
          Excel
        </a>

        <a
          (mouseenter)="computeLinkHref('csv')"
          [href]="exportLinkHref"
          class="btn btn-outline-primary"
          [ngClass]="{ disabled: !isExportEnabled }"
        >
          CSV
        </a>
      </span>

      <!-- Old export -->
      <button
        *ngFor="let format of exportFormats ?? ['CSV']"
        type="button"
        class="btn btn-outline-primary"
        (click)="exportFile($event, format.toLowerCase())"
        [ngClass]="{ disabled: !isExportEnabled }"
      >
        {{ format }}
      </button>
    </div>
  </fieldset>

  <div
    class="tw-flex tw-flex-col tw-justify-end tw-gap-y-2 md:tw-flex-row md:tw-gap-x-2 md:tw-gap-y-0"
  >
    <button class="btn btn-outline-warning" type="button" (click)="onClickResetFilters()">
      {{ 'pages.order.reset_form' | translate }}
    </button>

    <button class="btn btn-primary" type="submit">
      {{ 'pages.order.apply_filters' | translate }}
    </button>
  </div>
</form>
