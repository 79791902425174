import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'interval' })
export class IntervalPipe implements PipeTransform {
  static sqlDateRegexp = /\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}/;

  public transform<T>(data: T[], from?: string | Date, to?: string | Date, key?: string): T[] {
    if ([data, from, to].some(this.isFalsy)) return data;

    const fromDate = new Date(from);
    const toDate = new Date(to);

    return data.filter((val) => {
      const value = key ? key.split('.').reduce((v, k) => v[k], val) : key['date'];
      const isSqlDate = IntervalPipe.sqlDateRegexp.test(value);
      const date = isSqlDate ? value.replace(/ /g, 'T') : value;
      const valueDate = new Date(date);

      return valueDate >= fromDate && valueDate <= toDate;
    });
  }

  private isFalsy(value: unknown) {
    return !Boolean(value);
  }
}
