<h2 mat-dialog-title>{{ 'pages.shared.document_modal_title' | translate }}</h2>

<mat-dialog-content>
  <uppy-dashboard
    *ngIf="uppy && !errorInitializingUppy"
    [uppy]="uppy"
    [props]="{ inline: true, plugins: ['ImageEditor'] }"
  ></uppy-dashboard>

  <p *ngIf="errorInitializingUppy" class="tw-text-red-700">
    {{ 'pages.shared.document_modal_error' | translate }}
  </p>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button class="btn" mat-dialog-close cdkFocusInitial>
    {{ 'otherslabels.btn_cancel' | translate }}
  </button>

  <button
    class="btn btn-primary"
    type="button"
    (click)="updateDocument()"
    [disabled]="!hasFiles || isLoading"
    *ngIf="!isLoading"
  >
    {{ 'otherslabels.btn_save' | translate }}
  </button>
</mat-dialog-actions>
