import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Address } from '@app/modules/shared/models/address';
import { TranslateService } from '@ngx-translate/core';
import { NotificationsService } from 'angular2-notifications';
import { DateTime } from 'luxon';
import { CustomersService } from '../../../services/customers.service';

export interface DialogData {
  isCustomerParent: boolean;
  customerId: string;
  firstname: string;
  lastname: string;
  birthday: string;
  email: string;
  phone: string;
  address: Address;
}

@Component({
  selector: 'tu-information-modal',
  templateUrl: './information-modal.component.html',
  providers: [CustomersService],
})
export class InformationModalComponent implements OnInit {
  @Output() updateField = new EventEmitter();

  public firstnameInput: string | null;
  public lastnameInput: string | null;
  public birthdayInput: string | null;
  public emailInput: string | null;
  public phoneInput: string | null;
  public streetNumberInput: string | null;
  public routeInput: string | null;
  public zipCodeInput: string | null;
  public cityInput: string | null;
  public countryInput: string | null;

  public loading: boolean = false;
  public errorMessage: string | null = null;

  public notificationOptions = {
    timeOut: 3000,
    showProgressBar: true,
    pauseOnHover: false,
    clickToClose: false,
  };

  constructor(
    public modalRef: MatDialogRef<InformationModalComponent>,
    public translateService: TranslateService,
    private customersService: CustomersService,
    private notification: NotificationsService,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {}

  ngOnInit() {
    this.firstnameInput = this.data.firstname;
    this.lastnameInput = this.data.lastname;

    if (this.data.birthday) {
      const birthday = DateTime.fromISO(this.data.birthday).setZone('UTC');
      this.birthdayInput = birthday.toFormat('yyyy-MM-dd');
    } else {
      this.birthdayInput = null;
    }

    this.emailInput = this.data.email;
    this.phoneInput = this.data.phone;
    this.streetNumberInput = this.data.address.streetNumber;
    this.routeInput = this.data.address.route;
    this.zipCodeInput = this.data.address.zipCode;
    this.cityInput = this.data.address.city;
    this.countryInput = this.data.address.country;
  }

  public closeModal(): void {
    this.modalRef.close();
  }

  public async confirmInformationEdit() {
    this.errorMessage = null;

    let newBirthday = this.birthdayInput
      ? DateTime.fromISO(this.birthdayInput, { zone: 'UTC' })
      : null;

    if (newBirthday) {
      const isBirthdayValid = newBirthday.isValid;

      if (!isBirthdayValid) {
        this.errorMessage = this.translateService.instant(
          `pages.customer_details.incorrect_birthday_format`
        );
        return;
      }
    }

    const hasSameFirstname = this.data.firstname === this.firstnameInput;
    const hasSameLastname = this.data.lastname === this.lastnameInput;

    // Compare timestamps
    const hasSameBirthday = newBirthday
      ? newBirthday.toMillis() === DateTime.fromISO(this.data.birthday).toMillis()
      : true;

    const hasSameEmail = this.data.email === this.emailInput;
    const hasSamePhone = this.data.phone === this.phoneInput;
    const hasSameAddress =
      this.data.address.streetNumber === this.streetNumberInput &&
      this.data.address.route === this.routeInput &&
      this.data.address.zipCode === this.zipCodeInput &&
      this.data.address.city === this.cityInput &&
      this.data.address.country === this.countryInput;

    const hasSameInfos =
      hasSameFirstname &&
      hasSameLastname &&
      hasSameBirthday &&
      hasSameEmail &&
      hasSamePhone &&
      hasSameAddress;

    if (hasSameInfos) {
      this.errorMessage = this.translateService.instant(
        `pages.customer_details.no_information_changes`
      );
      return;
    }

    try {
      this.loading = true;

      const customerAddress: Address = {
        streetNumber: this.streetNumberInput,
        route: this.routeInput,
        city: this.cityInput,
        zipCode: this.zipCodeInput,
        country: this.countryInput,
      };

      const body: Record<string, any> = {
        firstname: this.firstnameInput,
        lastname: this.lastnameInput,
        email: this.emailInput,
        phone: this.phoneInput,
        address: customerAddress,
      };

      if (newBirthday) {
        body.birthday = newBirthday.toISO();
      }

      const result = await this.customersService.updateInfo(this.data.customerId, body);

      if (result.error) {
        throw new Error();
      } else {
        const customer = await this.customersService.getCustomerDetails(this.data.customerId);

        this.updateField.emit(customer);
        this.notification.success(
          this.translateService.instant(`pages.customer_details.information_changes_success`)
        );
        this.closeModal();
      }
    } catch {
      this.notification.error(
        this.translateService.instant(`pages.customer_details.information_changes_fail`)
      );
    } finally {
      this.loading = false;
    }
  }
}
