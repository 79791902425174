import { Component, Input } from '@angular/core';

type Status = 'default' | 'info' | 'warning' | 'success' | 'error';

@Component({
  selector: 'tu-card',
  styleUrls: ['./card.component.scss'],
  template: `
    <article
      class="tw-divide-y tw-divide-gray-300 tw-border tw-border-gray-300 tw-bg-white"
      [ngClass]="klass"
    >
      <header
        *ngIf="withHeader"
        class="tw-flex tw-items-center tw-space-x-2 tw-px-4 tw-py-3"
        [ngClass]="[status]"
      >
        <i *ngIf="icon" [class]="icon"></i>
        <ng-content select="[card-header]"></ng-content>
      </header>

      <main class="tw-p-4">
        <ng-content select="[card-content]"></ng-content>
      </main>

      <footer *ngIf="withFooter" class="tw-p-4">
        <ng-content select="[card-footer]"></ng-content>
      </footer>
    </article>
  `,
})
export class CardComponent {
  @Input('icon')
  public icon?: string;

  @Input('withHeader')
  public withHeader = false;

  @Input('withFooter')
  public withFooter = false;

  @Input('klass')
  public klass = '';

  @Input('status')
  public status: Status = 'default';
}
