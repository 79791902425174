<div>
  <h5>{{ 'pages.document_upload_modal.title' | translate }}</h5>
  
  <form class="tw-mt-4 tw-space-y-4" [formGroup]="form" (ngSubmit)="submit()">
    <!-- Network -->
    <div class="tw-flex tw-flex-col tw-gap-2">
      <label class="form-control-label tw-mb-0" for="network-input">{{ 'pages.document_upload_modal.network' | translate }}</label>

      <div
        class="pl-0 d-flex input-group tw-w-full"
        aria-describedby="networkHelpBlock"
      >
        <input
          id="network-input"
          type="search"
          [formControl]="networkNameCtrl"
          [matAutocomplete]="auto"
          class="form-control"
          required
        />

        <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
          <mat-option
            *ngFor="let net of this.filteredNetworkList"
            [ngClass]="{
              selected:
                networkNameCtrl.value !== null &&
                networkNameCtrl.value.toLowerCase() === net.name.toLowerCase()
            }"
            [value]="net.name"
          >
            {{ net.name }}
          </mat-option>
        </mat-autocomplete>
      </div>
    </div>
        
    <!-- Type -->
    <div 
      *ngIf="networkHasNoDocumentType"
      class="tw-border tw-border-orange-300 tw-p-2 tw-text-orange-900 tw-bg-orange-200"
    >
      {{ 'pages.document_upload_modal.no_types' | translate }}
    </div>
    
    <div *ngIf="!networkHasNoDocumentType" class="tw-flex tw-flex-col tw-gap-2">
      <label class="form-control-label tw-mb-0 tw-flex tw-gap-2" for="type-input">
        <span>{{ 'pages.document_upload_modal.type' | translate }}</span>
        <tu-loader *ngIf="documentTypeLoader" klass="tw-h-4"></tu-loader>
      </label>

      <div
        class="pl-0 d-flex input-group tw-w-full"
        aria-describedby="typeHelpBlock"
      >
        <input
          id="type-input"
          type="search"
          [formControl]="documentTypeNameCtrl"
          [matAutocomplete]="auto2"
          class="form-control"
          required
        />

        <mat-autocomplete autoActiveFirstOption #auto2="matAutocomplete" [displayWith]="displayDocumentType">
          <mat-option
            *ngFor="let type of filteredDocTypeList"
            [value]="type"
          >
            {{ type.name }}
          </mat-option>
        </mat-autocomplete>
      </div>
      <p *ngIf="documentTypeNameCtrl.disabled" class="tw-mt-1 tw-mb-0 tw-text-red-500">{{ 'pages.document_upload_modal.type_info' | translate }}</p>
    </div>
    
    
    <!-- Document -->
    <div class="tw-flex tw-flex-col tw-gap-2">
      <label class="form-control-label tw-mb-0" for="document-input">{{ 'pages.document_upload_modal.document' | translate }}</label>

      <input
        required
        type="file"
        id="document-input"
        name="document-input"
        (change)="onFileSelected($event)"
      />
    </div>
    
    <div class="tw-flex tw-justify-end tw-gap-4">
      <button
        type="button"
        class="btn btn-xl btn-secondary"
        (click)="closeModal()"
      >
      {{ 'otherslabels.btn_cancel' | translate }}
      </button>
      
      <button
        type="submit"
        class="btn btn-xl btn-primary tw-flex tw-gap-2"
        [disabled]="!form.valid || loader"
      >
        <tu-loader *ngIf="loader" klass="tw-h-4"></tu-loader>
        <span>{{ 'otherslabels.btn_add' | translate }}</span>
      </button>
    </div>
  </form>
</div>

<simple-notifications [options]="notificationOptions"></simple-notifications>