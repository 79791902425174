import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { environment as env } from '@env/environment';
import { formatISO, parse, set } from 'date-fns';
import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, startWith, switchMap, tap } from 'rxjs/operators';
import { IntervalPipe } from '../../pipes/interval.pipe';
import { KeyPipe } from '../../pipes/key.pipe';
import { SearchPipe } from '../../pipes/search.pipe';
import { AuthService } from '../../services/auth.service';
import { FilterService } from '../../services/filter.service';
import { QueryParamsService } from '../../services/queryParams.service';
import { observeProperty } from '../../utils/observeProperty';
import { getDefaultFrom, getDefaultTo } from '../../utils/filter';

@Component({
  selector: 'tu-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss'],
  providers: [SearchPipe, IntervalPipe, DatePipe, KeyPipe],
})
export class FilterComponent implements OnInit, OnDestroy {
  constructor(
    private searchPipe: SearchPipe,
    private intervalPipe: IntervalPipe,
    private datePipe: DatePipe,
    private keyPipe: KeyPipe,
    private fb: FormBuilder,
    public filterService: FilterService,
    private queryParamsService: QueryParamsService,
    public authService: AuthService
  ) {}

  public static sub: Subscription;
  public exportLinkHref = '';

  private _filtered: BehaviorSubject<Object[]> = new BehaviorSubject<Object[]>(null);
  private _content: Object[];
  public loading = false;

  public filterForm: FormGroup = null;
  public networksName: string[] = [];
  public networkNameCtrl = new FormControl();
  public filteredNetworksName: Observable<string[]> = this.networkNameCtrl.valueChanges.pipe(
    startWith(null),
    map((name: string | null) => (name ? this._filter(name) : this.networksName.slice()))
  );

  @Input() defaultFilters: Filters;
  @Input() content: Observable<Object[]> | Object[];
  @Input() config: FiltersConfig;
  @Input() exportInformation: ExportInformation;
  @Input() isLoading = false;
  public isLoading$ = observeProperty(this, 'isLoading');

  @Output() filtered: EventEmitter<BehaviorSubject<Object[]>> = new EventEmitter<
    BehaviorSubject<Object[]>
  >();
  @Output() filter: EventEmitter<Subject<Filters>> = new EventEmitter<Subject<Filters>>();
  @Output() exportDatasEvent: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() filtersChanged = new EventEmitter<Record<string, any>>();
  @Output() filtersReset = new EventEmitter();

  private filtersSub: Subject<Filters> = new Subject();
  private filtersBoSub: Subject<Filters> = new Subject();

  get isExportEnabled() {
    if (!this.exportInformation) return false;

    const hasCheckedRows = this.exportInformation.checkedRowsIds.length > 0;
    const hasAllCheckedRows = this.exportInformation.hasCheckedAll;

    return hasCheckedRows || hasAllCheckedRows;
  }

  get exportFormats() {
    if (!this.exportInformation) return this.config?.export_labels ?? undefined;
    if (!this.config || !this.config?.export_labels) return [];

    // If export information exist, we filter 'CSV' from export formats
    if (typeof this.config.export_labels === 'string') {
      if (this.config.export_labels === 'CSV') {
        return [];
      }
      return this.config.export_labels;
    }

    return this.config.export_labels.filter((label) => label !== 'CSV');
  }

  ngOnDestroy() {
    if (FilterComponent.sub) FilterComponent.sub.unsubscribe();

    this.isLoading$.subscribe((isLoading) => {
      if (isLoading) {
        this.filterForm.disable();
      } else {
        this.filterForm.enable();
      }
    });
  }

  private getNetworkByName(name: string) {
    return this.authService.networks.find((network) => network.name === name);
  }

  async ngOnInit() {
    this.initFilterService();

    const filters = this.filterService.filters;
    this.authService.networks.forEach((n) => this.networksName.push(n.name));

    const network = this.authService.getNetwork(filters.network);

    this.filterForm = this.fb.group({
      query: filters.query,
      from: filters.from ? this.formatDate(new Date(filters.from)) : null,
      to: filters.to ? this.formatDate(new Date(filters.to)) : null,
      network: filters.network,
      onlyActive: filters.onlyActive,
    });

    this.filter.emit(this.filtersSub);
    this.filtersSub.next(this.filterForm.value);

    this.networkNameCtrl.valueChanges
      .pipe(debounceTime(200), distinctUntilChanged())
      .subscribe((name) => {
        if (!name) this.filterForm.controls.network.setValue(null);

        const network = this.getNetworkByName(name);
        if (network) this.filterForm.controls.network.setValue(network.id);

        this.filterService.networkName = name;
      });

    if (network) {
      this.networkNameCtrl.setValue(network.name);
    }

    if (!this.content) return;

    if (this.content instanceof Observable) {
      FilterComponent.sub = this.filtersBoSub
        .pipe(
          startWith(this.filterForm.value),
          tap(() => (this.loading = true)),
          switchMap(() => this.content as Observable<Object[]>)
        )
        .subscribe((c) => {
          this._content = c;
          this._filtered.next(this.filterContent(this._content));
          this.loading = false;
        });
    } else {
      this._content = <Object[]>this.content;
      this._filtered.next(this.filterContent(this._content));
    }

    this.filtered.emit(this._filtered);
  }

  public onClickApplyFilters() {
    //trick to add proper timezone to date
    const to = this.filterForm.value.to?.replaceAll('-', '/') ?? null;
    const from = this.filterForm.value.from?.replaceAll('-', '/') ?? null;
    const onlyActive = this.filterForm.value.onlyActive || null;

    this.filterService.filters = { ...this.filterForm.value, to, from, onlyActive };
    if (this.config && this.config.boFilter !== true)
      this._filtered.next(this.filterContent(this._content));
    else this.filtersBoSub.next(this.filterForm.value);
    this.filtersSub.next(this.filterForm.value);
    this.filtersChanged.emit(this.filterForm.value);
  }

  public onClickResetFilters() {
    //reset from & to date to current day
    const from = getDefaultFrom();
    const to = getDefaultTo();

    this.filterForm.setValue({
      query: null,
      from: this.defaultFilters?.from
        ? this.formatDate(new Date(this.defaultFilters.from))
        : this.formatDate(from),
      to: this.defaultFilters?.to
        ? this.formatDate(new Date(this.defaultFilters.to))
        : this.formatDate(to),
      network: null,
      onlyActive: null,
    });

    this.networkNameCtrl.reset();
    this.filtersReset.emit();
    this.onClickApplyFilters();
  }

  private initFilterService() {
    const filters = this.filterService.filters;

    //Init filters depending on config & previous filter state
    if (this.config?.dates === false) {
      filters.from = null;
      filters.to = null;
    } else {
      //init date if allowed but null
      filters.from = this.defaultFilters?.from || filters.from || new Date().toISOString();
      filters.to = this.defaultFilters?.to || filters.to || new Date().toISOString();
    }

    if (this.config?.network === false) {
      filters.network = null;
      filters.networkName = null;
    }
    if (this.config?.query === false) {
      filters.query = null;
    }
    filters.onlyActive = this.defaultFilters?.onlyActive || null;
    //force filters removed by config

    const isSameObject = (obj1: Object, obj2: Object) => {
      return Object.entries(obj1).every(([key, value]) => obj2[key] === value);
    };

    if (!isSameObject(filters, this.filterService.filters)) {
      this.filterService.filters = filters;
    }
    this.queryParamsService.globalFilterParams = filters;
  }

  private filterContent(content) {
    if (this.config && this.config.boFilter !== true) {
      content = this.keyPipe.transform(content, 'network_id', this.filterService.network); // If single id passed
      content = this.keyPipe.transform(content, 'network_ids', this.filterService.network); // If id array passed
      if (!this.config || this.config.dates !== false) {
        const key = this.config && this.config.date_key ? this.config.date_key : null;
        content = this.intervalPipe.transform(
          content,
          this.filterService.from,
          this.filterService.to,
          key
        );
      }
    }

    return this.searchPipe.transform(content, this.filterForm.controls['query'].value);
  }

  private formatDate(date) {
    return this.datePipe.transform(date, 'yyyy-MM-dd');
  }

  public exportFile(event, type): void {
    this.exportDatasEvent.emit(type);
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.networksName.filter((name) => name.toLowerCase().includes(filterValue));
  }

  public computeLinkHref(format: string): void {
    try {
      // STEP 1: Create export URL base with export dataset ID and export format (for now, CSV)
      const { exportId } = this.exportInformation;

      const exportUrl = new URL(`${env.config.exportRoot}datasets/${exportId}/export`);

      // STEP 2: Create searchParams with selected filters
      const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

      // The '@' character is a convention established in order to identify operator tokens
      const token = `@${this.authService.token}`;

      const filters: Record<string, string> = {
        format: format || 'csv',
        timezone,
        token,
        ...this.exportInformation.filters,
      };

      const { from, to } = this.filterForm.value;

      if (from && to) {
        const from = parse(this.filterForm.value.from, 'yyyy-MM-dd', new Date());
        const to = parse(this.filterForm.value.to, 'yyyy-MM-dd', new Date());

        const start = set(from, { hours: 0 });
        const end = set(to, { hours: 23, minutes: 59, seconds: 59 });

        filters.from = formatISO(start);
        filters.to = formatISO(end);
      }

      const searchParams = new URLSearchParams(filters);

      // STEP 3-a: If all rows have been selected, add general optional filters
      if (this.filterService.network) {
        searchParams.set('networkId', this.filterService.network.toString());
      } else {
        const userNetworkIdList = this.authService.networks.reduce((networksList, { id }) => {
          if (!networksList) return id;

          return networksList + ',' + id;
        }, '');

        searchParams.set('networkId', userNetworkIdList);
      }

      // STEP 3-b: Check if the user has selected some rows, but not all rows, then export data
      if (!this.exportInformation.hasCheckedAll && this.exportInformation.checkedRowsIds.length) {
        const idsString = this.exportInformation.checkedRowsIds.join(',');

        searchParams.set('ids', idsString);
        exportUrl.search = searchParams.toString();

        this.exportLinkHref = exportUrl.toString();
      }

      if (this.filterForm.controls['query'].value) {
        searchParams.set('query', this.filterForm.controls['query'].value);
      }

      // STEP 4-b: Add filters to export URL and export data
      exportUrl.search = searchParams.toString();

      this.exportLinkHref = exportUrl.toString();
    } catch (error) {
      console.log(error);
      this.exportLinkHref = '';
    }
  }
}

export interface Filters {
  query?: string;
  from?: string;
  to?: string;
  network?: number;
  onlyActive?: boolean;
}

export interface FiltersConfig {
  query?: boolean;
  override_style?: boolean;
  dates?: boolean;
  network?: boolean;
  export?: boolean;
  export_labels?: string | string[];
  date_key?: string;
  boFilter?: boolean;
  onlyActive?: boolean;
}

export interface ExportInformation {
  checkedRowsIds: number[];
  hasCheckedAll: boolean;
  exportId: string;
  filters: {};
}
