import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import Cropper from 'cropperjs';

@Component({
  selector: 'tu-image-cropper',
  template: `
    <div class="tw-mx-auto tw-h-80 tw-w-80">
      <img #image [src]="imageUrl" crossOrigin />
    </div>
  `,
})
export class ImageCropperComponent implements AfterViewInit {
  @ViewChild('image', { static: false })
  public imageElement: ElementRef;

  @Input('src')
  public imageUrl: string;

  @Output() newCroppedImageUri = new EventEmitter<string>();

  //cropperjs doc : https://github.com/fengyuanchen/cropperjs/blob/main/README.md
  // angular integration guide : https://www.thepolyglotdeveloper.com/2019/06/image-cropping-zooming-scaling-angular-javascript/
  private cropper: Cropper;

  ngAfterViewInit() {
    this.cropper = new Cropper(this.imageElement.nativeElement, {
      zoomable: false,
      scalable: false,
      checkCrossOrigin: false,
      viewMode: 2,
      aspectRatio: 1,
      minContainerHeight: 0,
      minContainerWidth: 0,
      checkOrientation: false,
      crop: () => {
        const canvas = this.cropper.getCroppedCanvas();
        this.newCroppedImageUri.emit(canvas.toDataURL('image/png'));
      },
    });
  }
}
