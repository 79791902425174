import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { NotificationsService } from 'angular2-notifications';
import { firstValueFrom } from 'rxjs';
import { CustomersService } from '../../../services/customers.service';

export interface DialogData {
  customerId: number;
  customerEmail: string;
  customerAnonymized: string;
}

@Component({
  selector: 'tu-anonymization-modal',
  templateUrl: './anonymization-modal.component.html',
  providers: [CustomersService],
})
export class AnonymizationModalComponent {
  public anonymizeInput: string;
  public loading = false;
  public isForbidden = false;

  public notificationOptions = {
    timeOut: 4000,
    showProgressBar: true,
    pauseOnHover: true,
    clickToClose: false,
  };

  public get isDisabled() {
    if (!this.data.customerEmail) {
      const isId = this.anonymizeInput === String(this.data.customerId);
      return !isId;
    }

    const isEmail = this.anonymizeInput === this.data.customerEmail;
    return !isEmail;
  }
  public get title() {
    return this.translate.instant(
      !this.isForbidden
        ? this.translate.instant('pages.customer_details.anonymization_modal_title')
        : this.translate.instant('pages.customer_details.user_anonymization_failed')
    );
  }

  constructor(
    public modalRef: MatDialogRef<AnonymizationModalComponent>,
    private customersService: CustomersService,
    private readonly translate: TranslateService,
    private notification: NotificationsService,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {}

  public get isCustomerEmailAvailable() {
    return Boolean(this.data.customerEmail);
  }

  public onCancel(): void {
    if (this.loading || this.data.customerAnonymized) {
      return;
    }

    this.modalRef.close();
  }

  public async confirmAnonymisation() {
    if (this.loading || this.data.customerAnonymized) {
      return;
    }

    this.anonymizeInput = '';
    this.loading = true;

    try {
      const { response } = await this.customersService.anonymizeCustomer(
        this.data.customerId.toString()
      );

      //OK
      if (response?.errors.length === 0) {
        const notification = this.notification.success(
          this.translate.instant('pages.customer_details.user_anonymized')
        );
        await firstValueFrom(notification.timeoutEnd);
        location.reload();
        return;
      }
      //KO
      this.notification.error(
        this.translate.instant('pages.customer_details.user_anonymization_failed')
      );
    } catch ({ error }) {
      if (error.code === 'CUSTOMER_ANONYMIZATION_FORBIDDEN') {
        this.isForbidden = true;
        this.notification.error(
          this.translate.instant('pages.customer_details.user_anonymization_forbidden'),
          this.translate.instant('pages.customer_details.user_anonymization_forbidden_message')
        );
        return;
      }
      this.notification.error(
        this.translate.instant('pages.customer_details.user_anonymization_failed')
      );
    } finally {
      this.loading = false;
    }
  }
}
