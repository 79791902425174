<div>
  <details [hasAllPermission]="['ACCESS_COMMENTS']" class="details tw-fixed tw-bottom-0 tw-z-10 tw-bg-white tw-rounded-tl-md tw-rounded-tr-md tw-shadow-lg tw-border tw-border-gray-300">
    <summary class="tw-py-2 tw-px-4 tw-flex tw-items-center tw-justify-between tw-gap-2 tw-cursor-pointer">
      <div class="tw-flex tw-gap-2 tw-items-center">
        <div class="icon tw-transition-transform">
          <tu-hero-icon [path]="heroicons.outline.chevronDown" klass="tw-w-5"></tu-hero-icon>
        </div>
        <span class="tw-font-semibold tw-text-lg">{{ 'pages.shared.comments' | translate }}</span>
        <span *ngIf="comments?.length > 0" class="tw-py-1 tw-px-3 tw-bg-red-500 tw-text-white tw-rounded-full">{{ comments.length }}</span>
      </div>
      
      <button
        type="button"
        (click)="toggleFormat()"
      >
        <tu-hero-icon [path]="heroicons.outline.informationCircle" klass="tw-w-5 tw-text-[#67a5ee]"></tu-hero-icon>
      </button>
    </summary>
    
    <div 
      class="tw-pt-4 tw-border-t tw-border-gray-300 tw-space-y-8 tw-flex tw-flex-col tw-overflow-y-auto"
      [class]="{
        'tw-h-[70vh]': showFormat,
        'tw-h-[50vh]': !showFormat
      }"
    >
      <tu-comments-ng-format *ngIf="showFormat"></tu-comments-ng-format>
      
      <form class="tw-px-4 tw-flex tw-flex-col tw-gap-4" [formGroup]="commentForm" (submit)="submitComment()">
        <select
          class="tw-border tw-p-2"
          *ngIf="requiresNetwork && networks.length"
          formControlName="networkId"
        >
          <option [ngValue]="null">{{ 'pages.shared.network_unspecified' | translate }}</option>
          <option *ngFor="let network of networks" [value]="network.id">
            {{ network.name }}
          </option>
        </select>
        
        <textarea
          formControlName="message"
          class="tw-h-20 tw-resize-none tw-overflow-auto tw-border tw-p-2"
          [disabled]="submitLoading"
        ></textarea>
        
        <div class="tw-flex tw-gap-4 tw-justify-end">
          <button
            type="reset"
            class="btn btn-secondary"
            [disabled]="!hasMessage || submitLoading"
            (click)="cancelEdition()"
          >
            {{ 'otherslabels.btn_cancel' | translate }}
          </button>
          
          <button
            type="submit"
            class="btn btn-primary tw-flex tw-gap-2 tw-items-center"
            [disabled]="!hasMessage || submitLoading"
          >
            <tu-loader *ngIf="submitLoading" klass="tw-w-4"></tu-loader>
            <span>
              {{ (commentForm.controls.id.value ? 'Editer le commentaire' : 'pages.order_details.comment') | translate }}
            </span>
          </button>
        </div>
      </form>
      
      <div *ngIf="isLoading" class="tw-p-4 tw-flex tw-justify-center tw-items-center">
        <tu-loader klass="tw-w-8"></tu-loader>
      </div>
      
      <ul *ngIf="!isLoading && sortedComments.length > 0" class="tw-flex tw-flex-col tw-gap-2 tw-px-4 tw-pb-4 tw-grow tw-overflow-y-auto tw-min-h-28">
        <li *ngFor="let comment of sortedComments">
          <ng-template
            [ngTemplateOutlet]="commentTemplate"
            [ngTemplateOutletContext]="{
              comment: comment,
              markdownToHTML: markdownToHTML,
              editedComment: commentForm.value.id,
              commentToEdit: setEditableComment,
              commentToDelete: deleteCommentModal
            }"
          ></ng-template>
        </li>
      </ul>
      
      <div *ngIf="sortedComments.length === 0" class="tw-text-center">
        <p>{{ 'pages.order_details.no_comment' | translate }}</p>
      </div>
    </div>
  </details>
  
  <div class="backdrop tw-absolute tw-top-0 tw-left-0 tw-w-screen tw-h-screen tw-bg-black/50"></div>
</div>
