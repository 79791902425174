<h5 mat-dialog-title class="mb-1">
  {{ 'pages.customer_details.network_dissociation_modal_title' | translate }}
</h5>

<div mat-dialog-content>
  <p class="tw-mb-0">
    {{ 'pages.customer_details.network_dissociation_modal_description' | translate }}
  </p>

  <form (ngSubmit)="confirmNetworkDissociationDelete()">
    <div mat-dialog-actions class="form-group pull-right tw-min-h-[auto]">
      <button
        type="button"
        (click)="closeModal()"
        [disabled]="loading"
        class="mr-1 btn btn-sm btn-secondary tw-mt-0"
      >
        {{ 'otherslabels.btn_cancel' | translate }}
      </button>

      <button type="submit" [disabled]="loading" class="btn btn-sm btn-danger tw-mt-0">
        <i class="fa fa-circle-o-notch fa-spin" [ngClass]="{ 'tw-hidden': !loading }"></i>
        <span>
          {{
            !loading
              ? ('otherslabels.btn_confirm' | translate)
              : ('otherslabels.loading' | translate)
          }}
        </span>
      </button>
    </div>
  </form>
</div>

<simple-notifications [options]="notificationOptions"></simple-notifications>
