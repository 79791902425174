import { Component, EventEmitter, Inject, Output } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CustomersService } from '@app/modules/customers/services/customers.service';
import { TranslateService } from '@ngx-translate/core';
import { NotificationsService } from 'angular2-notifications';

export interface DialogData {
  networkId: number;
  customerId: number;
}

@Component({
  selector: 'tu-network-dissociation-modal',
  templateUrl: './network-dissociation-modal.component.html',
  providers: [CustomersService],
})
export class NetworkDissociationModalComponent {
  @Output() updateField = new EventEmitter();

  public loading = false;

  public notificationOptions = {
    timeOut: 5000,
    showProgressBar: true,
    pauseOnHover: false,
    clickToClose: false,
  };

  constructor(
    public modalRef: MatDialogRef<NetworkDissociationModalComponent>,
    private customersService: CustomersService,
    private notification: NotificationsService,
    private translate: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {}

  public closeModal(): void {
    this.modalRef.close();
  }

  public async confirmNetworkDissociationDelete() {
    this.loading = true;

    try {
      const { networkIds } = await this.customersService.unlinkNetwork(
        this.data.customerId,
        this.data.networkId
      );

      this.updateField.emit(networkIds);

      this.notification.success(
        this.translate.instant('pages.customer_details.network_dissociation_success_notification')
      );

      this.closeModal();
    } catch ({ error }) {
      switch (error.code) {
        case 'NETWORK_FORBIDDEN':
          this.notification.error(
            this.translate.instant('pages.customer_details.network_forbidden')
          );
          break;
        case 'CUSTOMER_NOT_FOUND':
          this.notification.error(
            this.translate.instant('pages.customer_details.customer_not_found')
          );
          break;
        case 'CUSTOMER_NETWORK_ASSOCIATION_NOT_FOUND':
          this.notification.error(
            this.translate.instant('pages.customer_details.network_association_not_found')
          );
          break;
        default:
          this.notification.error(this.translate.instant('pages.customer_details.generic_error'));
      }
    } finally {
      this.loading = false;
    }
  }
}
