<div class="card">
  <div class="card-header">
    <i class="fa fa-align-justify"></i>
    {{ 'pages.order.filters' | translate }}
  </div>
  <div class="card-block">
    <tu-filter
      [defaultFilters]="{ query: term }"
      [config]="{ query: true, network: true, dates: false, export: false, boFilter: true }"
      [content]="null"
      (filter)="filter = $event"
    ></tu-filter>
  </div>
</div>

<div class="card">
  <div class="card-header">
    <i class="fa fa-align-justify"></i>
    {{ 'pages.customers_search.search_results' | translate }}
    <b>{{ term }}</b>
  </div>
  <div class="card-block table-responsive">
    <div class="row">
      <div class="col-lg-12">
        <tu-table
          [isClicked]="true"
          [config]="tableConfig"
          [content]="feedContent"
          [filterObs]="filter"
          [checkboxes]="false"
          (rowClicked)="navigateToCustomer($event)"
        ></tu-table>
      </div>
    </div>
  </div>
</div>
