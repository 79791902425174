const COLORS = {
  red: 'tw-text-red-500',
  rouge: 'tw-text-red-500',
  blue: 'tw-text-blue-500',
  bleu: 'tw-text-blue-500',
  green: 'tw-text-green-500',
  vert: 'tw-text-green-500',
  orange: 'tw-text-orange-500',
  yellow: 'tw-text-yellow-500',
  jaune: 'tw-text-yellow-500',
  violet: 'tw-text-violet-500',
  pink: 'tw-text-pink-500',
  rose: 'tw-text-rose-500',
};

// https://marked.js.org/using_pro#extensions
export const fontColor = {
  name: 'fontColor',
  level: 'inline', // Is this a block-level or inline-level tokenizer?
  start(src) {
    return src.match(/\[([a-z]+)\]/)?.index;
  }, // Hint to Marked.js to stop and check for a match
  tokenizer(src) {
    const rule = /^\[([a-z]+)\]((?:.|\n)+)\[\/\1\]/; // Regex for the complete token

    const match = rule.exec(src);

    if (!match) return;
    const color = match[1].trim();

    if (!Object.keys(COLORS).includes(color)) return;

    const token = {
      // Token to generate
      type: 'fontColor', // Should match "name" above
      raw: match[0], // Text to consume from the source
      text: this.lexer.inlineTokens(match[2].replace(/^ +| +$/gm, '')), // Removes the leading and trailing white spaces
      color,
    };

    return token;
  },

  renderer(token) {
    return `<span class="${COLORS[token.color]} tw-whitespace-pre">${this.parser.parseInline(
      token.text
    )}</span>`;
  },
};
