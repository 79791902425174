<div>
  <p class="tw-text-lg">{{ 'pages.shared.confirm_comment_delete' | translate }}</p>
  <div class="tw-flex tw-justify-center tw-gap-4">
    <button
      type="button"
      class="btn btn-secondary"
      (click)="closeModal(false)"
    >
      {{ 'otherslabels.btn_cancel' | translate }}
    </button>
    
    <button
      type="button"
      class="btn btn-danger"
      (click)="closeModal(true)"
    >
      {{ 'otherslabels.btn_delete' | translate }}
    </button>
  </div>
</div>
