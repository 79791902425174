<h5 mat-dialog-title class="mb-1">{{ 'pages.customer_details.fusion_modal_title' | translate }}</h5>
<div mat-dialog-content>
  <div class="ctn">
    <div id="modal-context" class="tw-m-2">
      <mat-stepper #stepper>
        <mat-step>
          <ng-template matStepLabel>
            {{ 'pages.customer_details.fusion_modal_search' | translate }}
          </ng-template>
          <p>
            {{ 'pages.customer_details.fusion_modal_base_user' | translate }}
            <strong>
              {{ customer.customer_id }} -- {{ customer.firstname }} {{ customer.lastname }}
            </strong>
          </p>
          <div class="tw-grid tw-grid-cols-[20rem,auto]">
            <label for="customerSearch">
              {{ 'pages.customer_details.fusion_modal_search_input' | translate }}
            </label>
            <div class="tw-mb-2">
              <input
                id="customerSearch"
                type="text"
                [placeholder]="
                  'pages.customer_details.fusion_modal_search_input_placeholder' | translate
                "
                [attr.aria-label]="'pages.customer_details.fusion_modal_search_input' | translate"
                class="form-control"
                [formControl]="search"
                [matAutocomplete]="auto"
              />
              <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selectedCustomer($event)">
                <mat-option *ngFor="let customer of filteredCustomers" [value]="customer">
                  {{ customer.id }} -- {{ customer.firstName }} {{ customer.lastName }}
                </mat-option>
              </mat-autocomplete>
            </div>
          </div>
          <mat-chip-list #chipList aria-label="Customers">
            <mat-chip
              *ngFor="let customer of selectedCustomers"
              removable="true"
              (removed)="removeCustomer(customer)"
            >
              {{ customer.id }} -- {{ customer.firstName }} {{ customer.lastName }}
              <button matChipRemove>x</button>
            </mat-chip>
          </mat-chip-list>

          <div mat-dialog-actions class="pull-right tw-m-0 tw-mt-2 tw-p-0" style="min-height: auto">
            <button
              type="button"
              mat-flat-button
              (click)="closeModal()"
              [disabled]="loading"
              class="mt-0 mr-1 btn btn-sm btn-secondary"
            >
              {{ 'otherslabels.btn_cancel' | translate }}
            </button>
            <button
              type="button"
              mat-button
              matStepperNext
              (click)="generateFusionLists()"
              [disabled]="!canGoNextStep"
              class="btn btn-primary"
            >
              {{ 'pages.customer_details.fusion_modal_next_step' | translate }}
            </button>
          </div>
        </mat-step>
        <mat-step>
          <ng-template matStepLabel>
            {{ 'pages.customer_details.fusion_modal_data_choice' | translate }}
          </ng-template>
          <p>
            {{ recapLabel }}
            <br />
            <b class="text-danger">
              {{
                'pages.customer_details.fusion_modal_users_delete' | translate : { customersLabel }
              }}
            </b>
            <br />
            <strong>{{ 'pages.customer_details.fusion_modal_confirm_fusion' | translate }}</strong>
          </p>
          <p>{{ 'pages.customer_details.fusion_modal_choices' | translate }}</p>
          <div class="tw-grid tw-grid-cols-[20rem,auto] tw-gap-x-2 tw-gap-y-2">
            <!-- Email -->
            <label>{{ 'pages.customer_details.fusion_modal_email' | translate }}</label>
            <select class="form-control" [formControl]="fusionEmail">
              <option *ngFor="let email of customerEmails" [value]="email.value">
                {{ email.label }}
              </option>
            </select>

            <!-- Address -->
            <label>{{ 'pages.customer_details.fusion_modal_address' | translate }}</label>
            <select class="form-control" [formControl]="fusionAddress">
              <option *ngFor="let address of customerAddresses" [value]="address.value">
                {{ address.label }}
              </option>
            </select>

            <!-- Phones -->
            <label>{{ 'pages.customer_details.fusion_modal_phone' | translate }}</label>
            <select class="form-control" [formControl]="fusionPhone">
              <option *ngFor="let phone of customerPhones" [value]="phone.value">
                {{ phone.label }}
              </option>
            </select>

            <!-- Birthdate -->
            <label>{{ 'pages.customer_details.fusion_modal_birthday' | translate }}</label>
            <select class="form-control" [formControl]="fusionBirthdate">
              <option *ngFor="let birthdate of customerBirthdates" [value]="birthdate.value">
                {{ birthdate.label }}
              </option>
            </select>

            <!-- Additionnal Data -->
            <ng-container *ngFor="let attribute of customerAttributes">
              <label>{{ attribute.label | translate }}</label>
              <select class="form-control" [formControl]="fusionAttributes[attribute.id]">
                <option
                  *ngFor="let attributeValue of attribute.values"
                  [value]="attributeValue.value"
                >
                  {{ attributeValue.label }}
                </option>
              </select>
            </ng-container>
          </div>

          <div mat-dialog-actions class="pull-right tw-m-0 tw-mt-2 tw-p-0" style="min-height: auto">
            <button mat-button matStepperPrevious [disabled]="loading">
              {{ 'pages.customer_details.fusion_modal_back' | translate }}
            </button>
            <button
              [disabled]="loading"
              mat-flat-button
              class="mt-0 btn btn-sm btn-primary"
              (click)="fusionCustomers()"
            >
              <i class="fa fa-circle-o-notch fa-spin" [ngClass]="{ 'd-none': !loading }"></i>
              <span>
                {{
                  !loading
                    ? ('otherslabels.btn_save' | translate)
                    : ('otherslabels.loading' | translate)
                }}
              </span>
            </button>
          </div>
        </mat-step>
      </mat-stepper>
    </div>
  </div>
  <simple-notifications [options]="notificationOptions"></simple-notifications>
</div>
