import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Filters } from '@app/modules/shared/components/filter/filter.component';
import { TableElementType } from '@app/modules/shared/components/table/table.component';
import { AuthService } from '@app/modules/shared/services/auth.service';
import { Observable } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  startWith,
  switchMap,
  tap,
} from 'rxjs/operators';
import { Customer } from '../../models/customer';
import { CustomersService } from '../../services/customers.service';

@Component({
  selector: 'tu-customer-search',
  templateUrl: './customer-search.component.html',
  providers: [CustomersService],
})
export class CustomerSearchComponent implements OnInit {
  public feedContent: Observable<Customer[]>;
  public customers: Customer[];
  public filtered: any;

  private _filter: Observable<Filters>;
  set filter(filters: Observable<Filters>) {
    this._filter = filters;

    if (!filters) return;
    this.feedContent = filters.pipe(
      startWith({ query: this.term, network: null }),
      tap(({ query }) => (this.term = query)),
      distinctUntilChanged((a, b) => a.query === b.query && a.network === b.network),
      map(({ query }) => query ?? ''),
      filter((query) => query.length >= 3),
      debounceTime(200),
      switchMap((query) => this.customersService.getSearchedCustomers(query)),
      map((r) => {
        r.forEach((el, i) => {
          if (
            el.fullname === this.term ||
            el.firstname === this.term ||
            el.lastname === this.term ||
            el.email === this.term ||
            el.phone === this.term
          ) {
            let tmp = r[i];
            r.splice(i, 1) && r.unshift(tmp);
          }
        });
        return r;
      })
    );
  }
  get filter(): Observable<Filters> {
    return this._filter.pipe(startWith({ query: this.term, network: null }));
  }

  public term: string;

  private styles = {
    canceled: {
      text: 'color:gray !important; text-decoration:line-through; background-color:rgba(0, 0, 0,.1);',
      icon: 'color:transparent; pointer-events: none; background-color:rgba(0, 0, 0,.1);',
      tag: 'background-color: rgba(0, 0, 0,.1);',
      avatar:
        'background: linear-gradient(to right, transparent, rgba(0, 0, 0,.1)); color:transparent; pointer-events: none;',
      origin:
        'background: linear-gradient(to left, transparent, rgba(0, 0, 0,.1)); color:transparent; pointer-events: none;',
    },
  };

  tableConfig = [
    {
      key: 'picture',
      label: '',
      type: TableElementType.Avatar,
      imgDefault: '/assets/img/user.svg',
      style: this.canceledStyle.bind(this, this.styles.canceled.avatar),
    },
    {
      key: 'fullname',
      label: 'otherslabels.col_fullname',
      type: TableElementType.Text,
      isSortable: true,
      style: this.canceledStyle.bind(this, this.styles.canceled.text),
    },
    {
      key: 'email',
      label: 'otherslabels.col_mail',
      type: TableElementType.Email,
      isSortable: true,
      style: this.canceledStyle.bind(this, this.styles.canceled.text),
    },
    {
      key: 'date',
      label: 'pages.customer.signin_date',
      type: TableElementType.Date,
      format: 'mediumDate',
      isSortable: true,
      style: this.canceledStyle.bind(this, this.styles.canceled.text),
    },
    {
      key: 'phone',
      label: 'otherslabels.col_phone',
      type: TableElementType.Text,
      modifier: (phone) => (phone ? phone : 'otherslabels.unspecified'),
      isSortable: true,
      style: (customer) =>
        customer.phone
          ? this.canceledStyle(this.styles.canceled.text, customer)
          : 'font-style: italic; color: darkgray;',
    },
    {
      key: 'network_ids',
      label: 'otherslabels.col_networks',
      type: TableElementType.Text,
      modifier: (ids) => {
        let out = '';
        ids.forEach((id) => {
          out += this.authService.networks.find((n) => +n.id === +id).name + '<br />';
        });
        return out;
      },
      isSortable: true,
      displayed: this.authService.networks.length > 1,
      style: (customer) =>
        this.canceledStyle(this.styles.canceled.tag, customer) + 'font-size: .8em;',
    },
    {
      key: 'provider',
      label: 'otherslabels.col_origin',
      type: TableElementType.Image,
      modifier: (provider) => {
        if (provider?.toLowerCase().includes('instantsystem')) {
          return '/assets/img/providers/instant_system.png';
        }

        switch (provider) {
          case 'tixipass':
            return '/assets/img/logo_tixipass.png';
          case 'sncf':
            return '/assets/img/providers/sncf.png';
          default:
            return '';
        }
      },
      config: {
        width: '45px',
      },
      style: (customer) =>
        this.canceledStyle(this.styles.canceled.origin, customer) + 'text-align: center',
      isSortable: true,
    },
  ];

  constructor(
    private customersService: CustomersService,
    private authService: AuthService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit() {
    this.term = this.route.snapshot.paramMap.get('term');
  }

  private canceledStyle(style, { active }: Customer) {
    return active ? '' : style;
  }
  public navigateToCustomer(c: Customer): void {
    this.router.navigate(['/customers', c.customer_id]);
  }
}
