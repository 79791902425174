<ul class="nav nav-tabs">
  <ng-container *ngFor="let tab of tabs">
    <li *ngIf="!tab.disabled" class="nav-tab" (click)="selectTab(tab)" [class.active]="tab.active">
      <a
        href="javascript:;"
        [attr.data-title]="tab.title"
        class="tw-flex tw-items-center tw-justify-center tw-py-0 tw-px-4"
      >
        {{ tab.title }}
      </a>
    </li>
  </ng-container>
</ul>
<ng-content></ng-content>
