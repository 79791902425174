import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GenericResponse } from '@app/modules/shared/models/generic-response';
import { environment as env } from '@env/environment';
import { firstValueFrom, map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PaperworkService {
  constructor(private http: HttpClient) {}

  public updateDocument(
    documentId: number,
    content: {
      status?: string;
      startingAt?: string;
      expiresAt?: string;
      comment?: string;
      examined?: string;
    }
  ) {
    return firstValueFrom(
      this.http
        .patch<GenericResponse>(`${env.config.feedRoot}Paperwork/updateDocument`, {
          documentId,
          ...content,
        })
        .pipe(
          map(({ response }) => {
            return response;
          })
        )
    );
  }
}
