<ng-container *ngIf="!displayableData || displayableData.length || isLoading; else noData">
  <div class="tw-w-full tw-overflow-auto">
    <table class="tw-min-w-full">
      <caption class="tw-sr-only">Tableau générique d'affichage de données</caption>

      <thead>
        <tr>
          <th scope="col" *ngIf="hasCheckbox" class="tw-whitespace-nowrap tw-px-4 tw-py-3">
            <label *ngIf="hasCheckboxForAll" class="switch switch-default switch-primary-outline-alt tw-m-0">
              <input
                class="switch-input"
                type="checkbox"
                [checked]="allSelected"
                (change)="toggleAllSelected($event.target.checked)"
              />
              <span class="switch-label"></span>
              <span class="switch-handle"></span>
            </label>
          </th>
          <th
            scope="col"
            *ngFor="let column of displayableColumns"
            class="tw-whitespace-nowrap tw-px-4 tw-py-3"
          >
            <button
              *ngIf="
                pagination.sortableKeys.includes(column.key) && column.isSortable !== false;
                else onlyLabel
              "
              type="button"
              (mousedown)="sort(column.key)"
              (keydown)="sort(column.key)"
            >
              {{ column.label }}

              <tu-hero-icon
                *ngIf="pagination.sortBy === column.key && pagination.sortIn === 'ASC'"
                [path]="heroicons.outline.arrowSmUp"
                klass="tw-h-4 tw-inline-block tw-mx-auto tw-text-gray-700"
              ></tu-hero-icon>

              <tu-hero-icon
                *ngIf="pagination.sortBy === column.key && pagination.sortIn === 'DESC'"
                [path]="heroicons.outline.arrowSmDown"
                klass="tw-h-4 tw-inline-block tw-mx-auto tw-text-gray-700"
              ></tu-hero-icon>

              <tu-hero-icon
                *ngIf="pagination.sortBy !== column.key || !pagination.sortIn"
                [path]="heroicons.outline.switchVertical"
                klass="tw-h-4 tw-inline-block tw-mx-auto tw-text-gray-700"
              ></tu-hero-icon>
            </button>

            <ng-template #onlyLabel>
              {{ column.label }}
            </ng-template>
          </th>
        </tr>
      </thead>

      <tbody
        *ngIf="displayableData"
        [ngClass]="{ 'tw-opacity-50 tw-contrast-200 tw-grayscale tw-filter': isLoading }"
      >
        <ng-container *ngFor="let row of displayableData; let k = index">
          <tr
            [ngClass]="{
              'hover:tw-bg-gray-50': !!rowLink,
              'tw-bg-gray-50': activeDetails === k
            }"
          >
            <td *ngIf="hasCheckbox" class="tw-px-4 tw-py-3">
              <label class="switch switch-default switch-pill switch-primary-outline-alt tw-m-0">
                <input
                  class="switch-input"
                  type="checkbox"
                  [checked]="allSelected || isRowSelected(row.row) >= 0"
                  (change)="toggleSelectedRow(row.row)"
                />
                <span class="switch-label"></span>
                <span class="switch-handle"></span>
              </label>
            </td>
            <td
              *ngFor="let cell of row.cells; let i = index"
              [class]="
                'tw-max-w-xs ' + (displayableColumns[i]?.class ? displayableColumns[i].class : '')
              "
            >
              <ng-container *ngIf="displayableColumns[i].type === 'details'">
                <div class="tw-flex tw-items-center tw-justify-center">
                  <button
                    type="button"
                    class="tw-flex tw-items-center tw-justify-center tw-rounded-full tw-p-2 tw-outline-none"
                    (click)="toggleDetails(k)"
                    [ngClass]="{
                      'tw-bg-slate-100': activeDetails === k
                    }"
                  >
                    <tu-hero-icon
                      [path]="heroicons.outline.chevronRight"
                      [klass]="
                        (activeDetails === k ? 'tw-rotate-90' : '') +
                        ' tw-h-6 tw-w-6 tw-inline-block tw-mx-auto tw-text-gray-700 tw-transition-transform'
                      "
                    ></tu-hero-icon>
                  </button>
                </div>
              </ng-container>

              <a
                *ngIf="displayableColumns[i].type !== 'details'"
                [ngSwitch]="displayableColumns[i].type"
                [routerLink]="
                  rowLink && displayableColumns[i].type !== 'actions' ? rowLink(row.row) : null
                "
                [ngClass]="[!!rowLink ? 'tw-cursor-pointer' : 'tw-cursor-default']"
                class="tw-inline-flex tw-w-full tw-items-center tw-px-4 tw-py-3 tw-text-current hover:tw-no-underline"
              >
                <ng-container *ngSwitchCase="'link'">
                  <a
                    *ngIf="isInternalLink(cell.config.href(value, row.row))"
                    (click)="stopPropagation($event)"
                    [routerLink]="[cell.config.href(value, row.row)]"
                  >
                    {{ cell.value }}
                  </a>
                  <a
                    *ngIf="!isInternalLink(cell.config.href(value, row.row))"
                    [attr.href]="cell.config.href(value, row.row)"
                  >
                    {{ cell.value }}
                  </a>
                </ng-container>

                <ng-container *ngSwitchCase="'htmlLink'">
                  <a
                    *ngIf="isInternalLink(cell.config.href(value, row.row))"
                    (click)="stopPropagation($event)"
                    [routerLink]="[cell.config.href(value, row.row)]"
                    [innerHTML]="cell.value | safeHtml"
                  >
                  </a>
                  <a
                    *ngIf="!isInternalLink(cell.config.href(value, row.row))"
                    [attr.href]="cell.config.href(value, row.row)"
                    [innerHTML]="cell.value | safeHtml"
                  >
                  </a>
                </ng-container>

                <ng-container *ngSwitchCase="'badge'">
                  <span
                    class="tw-whitespace-nowrap tw-rounded-lg tw-py-1 tw-px-2 tw-text-xs"
                    [ngClass]="{
                      'tw-bg-green-200 tw-text-green-700':
                        cell.config[cell.value]?.type === 'success',
                      'tw-bg-blue-200 tw-text-blue-700':
                        cell.config[cell.value]?.type === 'information',
                      'tw-bg-yellow-200 tw-text-yellow-700':
                        cell.config[cell.value]?.type === 'warning',
                      'tw-bg-red-200 tw-text-red-700': cell.config[cell.value]?.type === 'danger',
                      'tw-bg-gray-200 tw-text-gray-700':
                        cell.config[cell.value]?.type === 'default',
                      'tw-hidden': cell.config[cell.value]?.type === 'hidden',
                      'tw-hidden': !cell.config[cell.value]?.type
                    }"
                  >
                    {{
                      isFunction(cell.config[cell.value]?.label)
                        ? cell.config[cell.value]?.label(cell.row)
                        : cell.config[cell.value]?.label
                    }}
                  </span>
                </ng-container>

                <ng-container *ngSwitchCase="'icon'">
                  <tu-hero-icon
                    *ngFor="let icon of cell.config.svgPaths(cell.value)"
                    [path]="icon"
                    [ngbTooltip]="cell.config.label(cell.value)"
                    klass="tw-h-6 tw-inline-block tw-mx-auto tw-text-gray-700"
                  ></tu-hero-icon>
                </ng-container>

                <ng-container *ngSwitchCase="'image'">
                  <div
                    class="tw-flex tw-h-8 tw-items-center tw-justify-center tw-object-contain tw-object-center"
                  >
                    <img
                      *ngIf="cell.config.src(cell.value)"
                      [src]="cell.config.src(cell.value)"
                      [alt]="cell.config.alt(cell, row.row)"
                      class="tw-block tw-h-full tw-w-auto tw-rounded-md"
                    />
                  </div>
                </ng-container>

                <ng-container *ngSwitchCase="'actions'">
                  <button title="actions" [matMenuTriggerFor]="menu">
                    <tu-hero-icon
                      [path]="heroicons.outline.dotsVertical"
                      klass="tw-h-6 tw-inline-block tw-mx-auto tw-text-gray-700"
                    ></tu-hero-icon>
                  </button>

                  <mat-menu #menu="matMenu" class="tw-min-w-0">
                    <ng-container *ngFor="let action of cell.config.actions">
                      <button
                        *ngIf="action.isDisplayable === undefined || action.isDisplayable(row.row)"
                        mat-menu-item
                        (click)="action.onClick(cell.value, row.row)"
                        [title]="action.label"
                      >
                        <span [ngClass]="{ 'tw-sr-only': !!action.icon && !action.labelAndIcon }">
                          {{ action.label }}
                        </span>

                        <tu-hero-icon
                          *ngIf="action.icon"
                          [path]="action.icon"
                          klass="tw-h-6 tw-inline-block tw-mx-auto tw-text-gray-700"
                        ></tu-hero-icon>
                      </button>
                    </ng-container>
                  </mat-menu>
                </ng-container>

                <ng-container *ngSwitchCase="'text'">
                  <span class="tw-truncate" [title]="cell.value">{{ cell.value }}</span>

                  <tu-tooltip
                    *ngIf="cell.config?.tooltip && cell.config.tooltip(cell, row.row)"
                    [message]="cell.config.tooltip(cell, row.row)"
                    [config]="{ position: k === 0 ? 'bottom center' : 'top center' }"
                  ></tu-tooltip>
                </ng-container>
                
                <ng-container *ngSwitchCase="'html'">
                  <div [innerHTML]="cell.value | safeHtml"></div>
                </ng-container>

                <ng-container *ngSwitchDefault>
                  <span class="tw-truncate" [title]="cell.value">{{ cell.value }}</span>
                </ng-container>
              </a>
            </td>
          </tr>

          <!-- Add another tr when we have details column -->
          <tr
            *ngIf="detailsColumnsConfig"
            [ngClass]="{
              'tw-hidden': activeDetails !== k
            }"
          >
            <td [attr.colspan]="row.cells.length + 1" class="tw-bg-gray-50 tw-p-4">
              <table class="tw-min-w-[80vw]">
                <caption class="tw-sr-only">Tableau "placeholder" pour les loaders</caption>
                <thead>
                  <tr>
                    <th
                      scope="col"
                      *ngFor="let subColumn of detailsColumnsConfig.config.table"
                      class="tw-px-4 tw-py-3"
                    >
                      {{ subColumn.label }}
                    </th>
                  </tr>
                </thead>

                <tbody>
                  <tr *ngFor="let subRow of row.row[detailsColumnsConfig.key]">
                    <td
                      *ngFor="let itemTable of detailsColumnsConfig.config.table"
                      [ngSwitch]="itemTable.type"
                      class="tw-px-4 tw-py-3"
                    >
                      <ng-container *ngSwitchCase="'link'">
                        <a
                          *ngIf="
                            isInternalLink(itemTable.config.href(subRow[itemTable.key], subRow))
                          "
                          (click)="stopPropagation($event)"
                          [routerLink]="[itemTable.config.href(subRow[itemTable.key], subRow)]"
                        >
                          {{ subRow[itemTable.key] }}
                        </a>
                        <a
                          *ngIf="
                            !isInternalLink(itemTable.config.href(subRow[itemTable.key], subRow))
                          "
                          [attr.href]="itemTable.config.href(subRow[itemTable.key], subRow)"
                        >
                          {{ subRow[itemTable.key] }}
                        </a>
                      </ng-container>

                      <ng-container *ngSwitchCase="'price'">
                        <span class="tw-truncate">
                          {{
                            subRow[itemTable.key]
                              | currency2
                                : itemTable?.config?.locale()
                                : itemTable?.config?.currency(row.row)
                          }}
                        </span>
                      </ng-container>

                      <ng-container *ngSwitchCase="'sumQuantityPrice'">
                        <span class="tw-truncate">
                          {{
                            subRow[itemTable.config.quantityKey] * subRow[itemTable.config.priceKey]
                              | currency2
                                : itemTable?.config?.locale()
                                : itemTable?.config?.currency(row.row)
                          }}
                        </span>
                      </ng-container>

                      <ng-container *ngSwitchDefault>
                        <span class="tw-truncate">{{ subRow[itemTable.key] }}</span>
                      </ng-container>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
        </ng-container>
      </tbody>

      <tbody *ngIf="!displayableData">
        <tr *ngFor="let fakeRow of fakeRows">
          <td [attr.colspan]="displayableColumns.length" class="tw-px-4 tw-py-2">
            <div class="w-full rounded tw-flex tw-h-6 tw-animate-pulse tw-bg-gray-300"></div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <div class="tw-mt-4 tw-flex tw-flex-col tw-items-center tw-justify-end md:tw-flex-row">
    <div class="tw-flex tw-items-center tw-space-x-4">
      <label class="tw-m-0 tw-hidden tw-text-sm md:tw-inline" for="limit-selector">
        {{ 'otherslabels.items_per_page' | translate }} :
      </label>

      <select
        id="limit-selector"
        [value]="pagination.limit"
        (change)="onChangePaginationLimit($event.target.value)"
      >
        <option value="5">5</option>
        <option value="10">10</option>
        <option value="15">15</option>
        <option value="20">20</option>
        <option value="50">50</option>
      </select>
      <span *ngIf="!!displayableData" class="tw-text-sm">
        {{ pagination.offset + 1 }} -
        {{
          pagination.offset + pagination.limit < pagination.count
            ? pagination.offset + pagination.limit
            : pagination.count
        }}
        sur
        {{ pagination.count }}
      </span>
    </div>

    <ul class="tw-m-0 tw-mt-4 tw-flex tw-items-center tw-space-x-4 md:tw-mt-0 md:tw-ml-4">
      <li>
        <button
          [disabled]="pagination.currentPage <= 1"
          type="button"
          (click)="goto(1)"
          class="tw-text-gray-700 disabled:tw-cursor-not-allowed disabled:tw-text-gray-400"
        >
          <tu-hero-icon
            [path]="heroicons.outline.chevronDoubleLeft"
            title="Première Page"
            klass="tw-h-6 tw-inline-block tw-mx-auto tw-text-current"
          ></tu-hero-icon>
        </button>
      </li>
      <li>
        <button
          [disabled]="pagination.currentPage <= 1"
          type="button"
          (click)="goto(pagination.currentPage - 1)"
          class="tw-text-gray-700 disabled:tw-cursor-not-allowed disabled:tw-text-gray-400"
        >
          <tu-hero-icon
            [path]="heroicons.outline.chevronLeft"
            title="Page Précédante"
            klass="tw-h-6 tw-inline-block tw-mx-auto tw-text-current"
          ></tu-hero-icon>
        </button>
      </li>
      <li>
        <button
          [disabled]="pagination.currentPage >= pagination.pages"
          type="button"
          (click)="goto(pagination.currentPage + 1)"
          class="tw-text-gray-700 disabled:tw-cursor-not-allowed disabled:tw-text-gray-400"
        >
          <tu-hero-icon
            [path]="heroicons.outline.chevronRight"
            title="Page suivante"
            klass="tw-h-6 tw-inline-block tw-mx-auto tw-text-current"
          ></tu-hero-icon>
        </button>
      </li>
      <li>
        <button
          [disabled]="pagination.currentPage >= pagination.pages"
          type="button"
          (click)="goto(pagination.pages)"
          class="tw-text-gray-700 disabled:tw-cursor-not-allowed disabled:tw-text-gray-400"
        >
          <tu-hero-icon
            [path]="heroicons.outline.chevronDoubleRight"
            title="Dernière Page"
            klass="tw-h-6 tw-inline-block tw-mx-auto tw-text-current"
          ></tu-hero-icon>
        </button>
      </li>
    </ul>
  </div>
</ng-container>

<ng-template #noData>
  <div *ngIf="displayableData && !displayableData.length">
    <h6 class="px-2 py-1 table-msg tw-m-0">{{ 'otherslabels.no_data' | translate }}</h6>
  </div>
</ng-template>
