import { Component, EventEmitter, Inject, Output } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CustomersService } from '@app/modules/customers/services/customers.service';
import { SubmissionService } from '@app/modules/submissions/services/submission.service';
import { TranslateService } from '@ngx-translate/core';
import { NotificationsService } from 'angular2-notifications';

export interface DialogData {
  documentId: string;
  documentTypeName: string;
  submissionId: number;
  customerId: string;
}

@Component({
  selector: 'tu-delete-document-modal',
  templateUrl: './delete-document-modal.component.html',
  providers: [SubmissionService],
})
export class DeleteDocumentModalComponent {
  @Output() updateField = new EventEmitter();

  public loading = false;

  public notificationOptions = {
    timeOut: 5000,
    showProgressBar: true,
    pauseOnHover: false,
    clickToClose: false,
  };

  constructor(
    public modalRef: MatDialogRef<DeleteDocumentModalComponent>,
    private submissionService: SubmissionService,
    private customersService: CustomersService,
    private notification: NotificationsService,
    private translate: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {}

  public closeModal(): void {
    this.modalRef.close();
  }

  public async confirmDocumentDelete() {
    this.loading = true;

    try {
      await this.submissionService.deleteDocumentFile(this.data.documentId);

      if (this.data.submissionId) {
        this.submissionService
          .getSubmissionDetail(this.data.submissionId)
          .subscribe((submission) => this.updateField.emit(submission));
      } else {
        const documents = await this.customersService.$$getDocuments(this.data.customerId);
        this.updateField.emit(documents);
      }

      this.notification.success(this.translate.instant(`otherslabels.notif_delete_ok`));
      this.closeModal();
    } catch (deleteDocumentResponse) {
      this.notification.error(this.translate.instant(`otherslabels.notif_delete_ko`));
      return this.closeModal();
    } finally {
      this.loading = false;
    }
  }
}
